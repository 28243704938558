.banner-image-wrapper {
  .banner-img {
    width: 100%;
    height: 100%;
  }
  .content {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .logo-img{
      max-width: 6rem;
    }
    h1 {
      font-size: 3rem;
      line-height: 3.25rem;
      text-transform: uppercase;
      white-space: pre-wrap;
    }
    .curate-gradient-button {
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      &::after {
        border-radius: 0.25rem;
      }
    }
  }
}
