.modern-gallery-preview {
    top: 0;
    left: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: var(--cu-black-200);
    z-index: 13; 
}

.modern-gallery-preview-container {
    height: 100%;
    width: 100%;  
    &.outview { 
        transform: scale(1.5);                
    }
    &.midview {  
        transform: scale(1.2);              
    }
    &.fullview {
        transition: transform 0s;    

        .nftframe {
            width:100% !important;
            height: 100% !important;

            div {
                left:0 !important;
                top:0 !important;
                width:100% !important;
                height:100% !important;
            }

            .nftimage {
                display: none;
            }

            .nft-img-sec {
                width: 100%;
                object-fit: cover;
                height: 100%;
            }

            .iframe-container {
                width: max(var(--gallery-width), var(--gallery-height));
                height: max(var(--gallery-width), var(--gallery-height));                    
                position: absolute;
            }                

            video {
                width: max(var(--gallery-width), var(--gallery-height));
                height: max(var(--gallery-width), var(--gallery-height));                    
                max-width: none;
                max-height: none;
                object-fit:cover;
            }
        }             
    }
    &:not(.multi-view) {

        &.inview,
        &.outview {
            .slick-slider {
                height: 100%;
                display: grid;
                align-items: center;
            }
        }
    }
    .slider-container {
        height: 100%;
        width: 100%;

        .nftframe {
            max-height: var(--gallery-height);
            max-width: var(--gallery-width);
        }           

        .nftframe.portrait {
            height: 90vh var(--gallery-height);               
        }

        .nftframe.square {
            width: 90vh var(--gallery-height);            
            height: 90vh var(--gallery-height);                
        }
        
        &.grotate-90 {
            .slider-section {
                transform: rotate(90deg);
            }
        }

        &.grotate-180 {
            .slider-section {
                transform: rotate(180deg);
            }
        }

        &.grotate-270 {
            .slider-section {
                transform: rotate(270deg);
            }
        }
    }
    
    @media screen and (max-width: 768px) {
        gap: 5rem;
    }

    @media screen and (max-width: 550px) {
        gap: 2rem;
    }

    &.fram-bg {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &:hover {
            .gallery-control-panel {
                display: flex;
            }
        }
    }

    .slick-track {
        display: flex;
        align-items: center;

        .slick-active {
            .rotate-90 {
                transform: rotate(90deg);
                &.caption-container {
                    top: 60%;
                }
            }

            .rotate-180 {
                transform: rotate(180deg);
            }

            .rotate-270 {
                transform: rotate(270deg);
                &.caption-container {
                    top: 60%;
                }
            }
        }
    }

    &.outview {
        .caption-container {
            top: 55%;
        }
    }

    .caption-container {
        bottom: 10%;
        background: rgba(13, 15, 17, 0.7);
        padding: 2rem;
        border-radius: 0.71429rem;
        align-items: flex-start;
        min-width: 15rem;
        align-items: center;
        width: auto;
        // border: 1px solid var(--cu-black-70);
        text-align: left;
        transition: opacity 2s ease-in-out;
        span {
            font-size: 0.86rem;
            color: var(--cu-white-900);
            font-weight: 600;

            &.nft-name {
                font-weight: 400;
                padding-left: 0.71429rem;
                border-width: 0;
                border-left: 2px solid var(--cu-white-900);
                text-align: left;
                font-size: 0.79rem !important;
            }
            &.community-name {
                font-size: 0.86rem !important;
            }
            &.created-name {
                font-style: italic;
                font-weight: 400;
                background: var(--cu-white-900);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.show {
            transition-delay: 0s;
            z-index: 1;
        }
    }

    .multi-view .frame-main-image {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;

        img {
            min-height: 60vh;
            max-height: 80vh;
            width: auto;
            height: 100%;

            @media screen and (max-width: 550px) {
                min-height: unset;
            }
        }
    }
    &.mono-view {
        &.midview {  
            transform: scale(1.1);              
        }
        .slick-slider{
            height: 100%;
            display: grid;
            align-items: center;
        }
        .slick-list, .slick-track, .slick-slide div:first-child {
            height: 100%;
        }
    }
    &.multi-view {
        transform-origin: 50% 25%;    
        // .caption-container {
        //     top: 70%;
        // }
        .nft-frame-background {
            height: auto;
        }
        .gallery-nft-frame-viewer .frame, .gallery-images img {
            max-height: 100vh !important;
        }
        .nft-img-sec {
            height: 100%;
        }
        .preview-gallery-new {
            height: 100vh;
            .frame-image {
                display: flex;
                .multi-view {
                    flex: 1 1;
                    justify-content: center;
                }
            }
        }
        .card-layout-container {
            display: block;
            .gallery-images-position, .nft-frame-background {
                height: 100%;
            }
        }
        .gallerylayout  {
            .skelton-loading {
                height: auto;
            }
        }
    }
    .gallery-image-frame-sction {
        .gallery-frame-image {
            object-fit: cover;
        }
    }

    &.fadeOut {
        .slick-slide {
            transition-timing-function: cubic-bezier(0.2, 0, 0.2, 0) !important;
        }
    }

    &.slide {
        .slick-track { 
            transition: transform 5s ease 0s !important;        
        }
    }
}
.gallery-control-container {
    opacity: 0;
    transition: opacity .5s ease;
    display: flex;
    justify-content: center;

    &:hover,
    &.active-control {
        opacity: 1 !important;
    }

    .gallery-control-panel {
        position: absolute;
        display: flex;
        align-items: center;
        padding: 0.625rem 0.75rem;
        gap: 3.75rem;
        width: max-content;
        background: var(--dark-mode-900);
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 19px 1px rgb(0 0 0);
        -moz-box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 1);
        box-shadow: 0px 0px 19px 1px rgb(0 0 0);
        bottom: 1rem;
        z-index: 11;

        img {
            width: 1.143rem;
            height: 100%;
        }

        // @media screen and (max-width: 1023px) {
        //     width: 90%;
        //     height: 40px;
        // }

        @media screen and (max-width: 550px) {
            width: 100%;
            height: auto;
            flex-wrap: wrap;
            gap: 1rem;
        }

        .gallery-control {
            display: flex;
            align-items: center;

            @media screen and (max-width: 550px) {
                gap: 0.5rem;
            }

            .caption-icon {
                &.disabled {
                    cursor: no-drop;
                }
            }
        }
    }

    .gallery-control-time-selection {
        .time-selector-value {
            min-height: 1rem;
            min-width: 7rem;

            @media screen and (max-width: 768px) {
                min-height: 2.3rem;
            }
        }

        .time-selector-dropdown {
            top: -7rem;
            bottom: 0;

            .time-selector-menu ul {
                max-height: 7rem;
            }
        }
    }

    .gallery-bg-selection {
        .select-box-new {
            font-size: 0.625rem;
        }

        .select-box--container {
            min-height: 1.563rem;
            min-width: 10rem;

            @media screen and (max-width: 768px) {
                min-height: 2.3rem;
            }
        }

        .select-box-items {
            bottom: 100%;
            top: unset;
            overflow-y: scroll;
        }

        .select-box-open .select-box-items {
            overflow-y: scroll;
            max-height: calc(100vh - 10rem) !important;
        }
    }

    .frame-selection-container,
    .animation-selection-container,
    .caption-selection-container,
    .padding-selection-container,
    .shape-selection-container,
    .background-selection-container,
    .time-selection-container {
        position: absolute;
        background: var(--dark-mode-900);
        border-radius: 0.625rem;
        padding: 1rem;
        bottom: 3.5rem;

        &.hide-bg {
            background-color: unset;
            left: -4.8rem;
        }
    }

    .time-selection-container {
        .time-head {
            span {
                font-size: 1rem;
                color: var(--cu-white-900);
            }
        }

        .time-selector-dropdown {
            top: -10.5rem;
            background-color: var(--dark-mode-900);
            border: unset;
            padding: 1rem;
            padding-top: 0;

            canvas {
                background-color: var(--cu-black-90);
            }
        }
    }

    .padding-selection-container {
        border-radius: 0.71429rem;
        padding: 0.85714rem 1.14286rem;
        right: 2.5rem;

        .padding-list {
            gap: 1.42857rem;
        }
    }

    .frame-selection-container {
        display: flex;
        padding: 0.85714rem 0rem;
        flex-direction: column;
        align-items: flex-start;
        right: 13rem;
        bottom: 3.92857rem;
        border-radius: 0.85714rem;

        span.active {
            background: var(--cu-white-900);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .frame-head {
            span {
                font-size: 1rem;
                color: var(--cu-white-900);
            }
        }

        .frame-list {
            padding: 0.28571rem 1.42857rem;
            font-weight: 400;
            gap: 0.71429rem;
            display: flex;

            span {
                font-size: 0.71429rem;
                color: var(--cu-white-900);
                font-weight: 400;
            }

            img {
                width: 0.71429rem;
                height: 0.71429rem;
            }
        }
    }

    .animation-selection-container {
        display: flex;
        padding: 0.85714rem 0rem;
        flex-direction: column;
        align-items: flex-start;
        right: -3rem;
        bottom: 3.2rem;
        border-radius: 0.85714rem;

        span.active {
            background: var(--cu-white-900);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .animation-head {
            span {
                font-size: 1rem;
                color: var(--cu-white-900);
            }
        }

        .animation-list {
            padding: 0.28571rem 1.42857rem;
            font-weight: 400;
            gap: 0.71429rem;
            display: flex;
            align-items: center;

            span {
                font-size: 0.71429rem;
                color: var(--cu-black-10);
                font-weight: 400;
            }
        }
    }

    .caption-selection-container {
        display: flex;
        padding: 0.85714rem 0rem;
        flex-direction: column;
        align-items: flex-start;
        right: -4.7rem;
        bottom: 3.3rem;
        border-radius: 0.85714rem;
        background: var(--dark-mode-900);
        z-index: 3;
        span.active {
            background: var(--cu-white-900);            
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .caption-time-head {
            span {
                font-size: 1rem;
                color: var(--cu-white-900);
            }
        }

        .caption-time {
            padding: 0.28571rem 1.42857rem;
            font-weight: 400;
            gap: 0.71429rem;
            display: flex;
            align-items: center;

            span {
                font-size: 0.71429rem;
                color:  var(--cu-black-10);
                font-weight: 400;

                img {
                    width: 1.14286rem;
                    height: 1.14286rem;
                }
            }
        }
    }

    .shape-selection-container {
        display: flex;
        padding: 0.85714rem 0rem;
        flex-direction: column;
        align-items: flex-start;
        right: -3.7rem;
       bottom: 3.3rem;
        border-radius: 0.85714rem;
        background: var(--dark-mode-900);

        .shape-head {
            span {
                font-size: 1rem;
                color: var(--cu-white-900);
            }
        }

        .shape-details {
            padding: 0.28571rem 1.42857rem;
            font-weight: 400;
            gap: 0.71429rem;
            display: flex;
            width: 9rem;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.5rem;
            
            span {
                font-size: 0.71429rem;
                color:  var(--cu-black-10);
                font-weight: 400;

                img {
                    width: 1.14286rem;
                    height: 1.14286rem;
                }
            }

            .square {
                margin-top:-0.45rem;
            }

            .portrait {
                margin-left: -0.45rem;
            }

            .inactive {
                filter: brightness(0.3);
            }
        }
    }

    .background-selection-container {
        bottom: 5rem;
        max-height: 15.5rem;
        max-width: 50rem;
        .style-box {
            font-size: 0.625rem;
            color: var(--cu-white-100);
            padding: 0rem 0.375rem;
            border-right: 1px solid var(--cu-black-100);
            cursor: pointer;
            &.active {
                color: white;
            }
        }
        .no-data{
            min-height: auto;
        }
        .background-text {
            text-align: center;
            width: 100%;
            &.text-left{
                text-align: left;
            }
        }

        .color-wrapper {
            font-size: 0.625rem;
            display: flex;
            gap: 0.625rem;
            align-items: center;
            padding: 0.25rem 0.375rem;
            border-radius: 0.25rem;
            border: 1px solid var(--cu-black-30);
            cursor: pointer;
            .color-show {
                width: 0.9375rem;
                height: 0.9375rem;
                border-radius: 0.125rem;
            }
            .color-list {
                position: absolute;
                right: 0rem;
                z-index: 99;
                top: 24px; 
                padding: 1rem 1.25rem;
                background: var(--cu-black-500);
                border-radius: 0.75rem;
                width: 10.125rem;
                border: 1px solid var(--cu-black-100);
                .color-list-wrapper{
                  display: flex;
                  gap: 0.625rem;
                  flex-wrap: wrap;
                }
                .color-circle {
                  width: 1rem;
                  height: 1rem;
                  border-radius: 0.125rem;
                  cursor: pointer;
                  border: 1px solid var(--cu-black-500);
                  outline: 1px solid transparent;
        
                  &.multi {
                    background: linear-gradient(red, yellow, blue, orange);
                  }
        
                  &.selected {
                    outline-color: var(--cu-white);
                  }
                }
              
            }
        }
        @media screen and (max-width: 1023px) {
            width: 100%;
            bottom: 7rem;
        }

        @media screen and (max-width: 550px) {
            width: 100%;
            bottom: 4rem;
        }

        .background-selection-list {
            display:flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 0.5rem;
            align-items: center;
            justify-items: center;
            row-gap: 1rem;
            max-height: 12rem;
            min-height: 4rem;
            overflow-y: scroll;
            padding-right: 0.5rem;
            padding-bottom: 0.5rem;
            &::-webkit-scrollbar {
                width: 0.1rem;
            }

            .background-item {
                border-radius: 0.25rem;
                border: 1px solid var(--cu-black-100);
                margin-top: 0.0625rem;

                &.active {
                    border: 0.0625rem solid white;
                }
            }

            img {
                border-radius: 0.25rem;
                height: 3rem;
                width: 5rem;
            }
        }
    }

    &.zoom-in {

        .padding-selection-container,
        .caption-selection-container {
            right: 0;
        }

        .animation-selection-container {
            right: 3rem;
        }

        .frame-selection-container {
            right: 11rem;
        }

        .background-selection-container {
            right: 36%;
        }
    }

    .background-selection-container.ismeta {
        height: auto;
        width: auto;
        padding-left: 1.5rem;
    }
}

.gallery-preview-popup {
    height: 38vh;
    border-radius: 0.25rem;
    background: var(--cu-black-700);
    padding: 0.6rem;
}
#mainContent .hide-music + .floating-section-wrapper {
    opacity: 0;
}
.floating-section-wrapper{
    &:hover{  
        .toggle-music-player{
    button{
        background: var(--cu-black-50);
    }
        }
    }
}