.overview-content {
    .home-grid{
        margin-top: 0rem;
    }
    .no-data{
        min-height: 14rem;
        @media screen and (max-width: 550px){ 
            min-height: 10rem;
        }   
    }
    .overview-gallery-container {
        @media screen and (max-width:550px){
            flex-direction: column;
        }
        .info-cat-wrp{
            @media screen and (max-width:550px){
                height: auto;
                width: 100%;
            }
            
        }
    }
}
.overview-content-event {
  
    @media screen and (min-width: 550px){ 
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    }
}