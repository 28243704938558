.frame-shop-box{
    .infinite-scroll-component {
        .skeltonMainClass {
            height: auto;
        }
        .frame-loader-container-lg {
            grid-template-columns: repeat(auto-fill, minmax(5, 1fr)) !important;
            padding: 0;
        } 
        .frame-loader-container-sm {
            grid-template-columns: repeat(8, 1fr) !important;
            padding: 0;
        } 
    }
    .frame-shop {
        .port-frame {
            .portrait {
                width: auto !important;
            }
        }
    }
}
