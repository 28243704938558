.community-list{
    border-radius: 0.625rem;
    cursor: pointer;
    &:is(&.active,:hover){
        background: var(--cu-black-trans-hover);
    }
    .img-round-box{
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 3.125rem;
        min-width: 2.5rem;
        overflow: hidden;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    h5{
        font-size: 0.875rem;
        color: var(-cu-white-200);
        font-weight: 500;
        -webkit-line-clamp: 1;
        font-weight: 500;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
    }
}