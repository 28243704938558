.hover-community {
    display: flex;
    position: absolute;
    inset: 0;
    opacity: 0;
    //height: 0rem;
    flex-direction: column;
    // gap: 0.5rem;
    border-radius: 0.42857rem;
    padding: 1rem;
    border: 1px solid var(--cu-black-400);
    background: var(--cu-black-410);
    transition: all 0.5s ease-in-out;
    .collection-box {
      display: grid;
      gap: 0.5rem;
      grid-template-columns: repeat(6, 1fr);
      .collection-img {
        // height: var(--collection-sm-card-height);
        aspect-ratio: 1;
        border-radius: 0.35rem;
        overflow: hidden;
        transition: 0.4s 0.2s ease;
        scale: 0.7;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &.remaining-count-box{
          position: relative;
          .count-text{
            position: absolute;
            inset: 0;
            background: var(--cu-grey-dim-200);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
          }
        }
      }
    }
    p {
      text-align: left;
      font-size: 0.875rem;
      color: var(--cu-white-200);
      transition: 0.4s 0.2s ease-in-out;
      scale: 0.7;
      &.description {
        min-height: 3.938rem;
        -webkit-line-clamp: 3;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }
    .details-section {
      gap: 0.5rem;
      .details-item {
        font-size: 0.875rem;
        color: var(--cu-black-5);
        transition: 0.4s 0.2s ease-in-out;
        scale: 0.7;
        .item-count {
          font-size: 0.875rem;
          color: var(--cu-white-200);
          transition: 0.4s 0.2s ease-in-out;
          scale: 0.7;
        }
        padding: 0rem;
      }
    }
  }