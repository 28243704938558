.tileview {    
    svg {
        &.hasdetailtext {
            .tile {
                image {
                    animation: unhighlighttile 0.5s;
                    animation-fill-mode: forwards;
                }
    
                .titletext, .shadowtext {
                    opacity: 1;
                    transition: opacity 0.5s ease-in-out;
                }
    
                .detailtext {
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out;
                }
            }
    
            .tile.hasdetailtext:hover {
    
                image {
                    animation: highlighttile 0.5s;
                    animation-fill-mode: forwards;
                }
                
                .titletext, .shadowtext {
                    opacity: 0;
                }
    
                .detailtext {
                    opacity: 1;
                }
            }
    
            @keyframes highlighttile {
                100% {              
                  filter: saturate(20%) blur(5px) brightness(0.7);
                }
            }        
    
            @keyframes unhighlighttile {
                0% {                
                    filter: saturate(20%) blur(5px) brightness(0.7);
                  }
                100% {              
                  filter: saturate(100%);
                }
            }        
        }        

        width:100%;
        height: 100%;

        .text-tile, .rect-text-tile {
            pointer-events: none;
        }
     }
     .shadowtext.text-title {
        tspan {
            fill: var(--cu-white-100);
            &:last-child {
                fill: white;
            }
        }
    }
}