.display-gallery-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .5rem;
    padding-right: .5rem;
s    >div {
        flex:1 0 100%;
        padding: 0.2rem;
    }

    .gallery-selected {
        border: 1px solid var(--cu-white-100);        
        border-radius: 0.5rem;
    }

    .info-cat-wrp .info .image-container {
        width: 100%;
    }

    .info-cat-wrp .info .info-title {
        font-size:0.75rem;
    }
}
