.plus-button-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.8;
  background-image: url("/Assets/Icons/add-icon-hover.svg");
  &:hover {
    opacity: 1;
  }
  :is(&.warning-btn,&.warning-btn:hover){
   opacity: 0.4;
  }
}
