.signin {
    display: grid;

    form {
        max-width: 15.75rem;
        width: 100%;
        padding: 1.875rem 0rem;
    }
    .auth-round {
        input {
            border-radius: 0.9rem;
        }
    }
    .forgot-pass {
        font-family: 'Poppins';
        font-style: normal;
        font-size: 0.625rem;
        line-height: 0.75rem;
        font-weight: 600;
        /* identical to box height, or 120% */

        letter-spacing: 0.02em;

        /* Shades/White */

        color: var(--cu-white-900);
    }
    .register-button {
        width: 100%;
    }

    .login-button{
        border-radius: 14px;
        border: 1px solid var(--Neutral-400, #A0ABBB);
        background: var(--neutral-900);
        padding: 10px 132px;
        color: var(--cu-white-200);
        font-family: ZenKaku;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        height: 2rem;
    }

    .register {
        color: blue;
    }
}
.validator-positon {
    position: absolute;
    right: -5.4rem;
    top: .8rem;
}   