.display-item-overlay {
    position: fixed;
    top:0rem;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px) brightness(30%); /* Apply the blur effect */
    z-index: 1000; /* Set a high z-index to ensure it appears on top of other elements */

    .display-item-content {
        display: flex;
        border-radius: 0.25rem;
        border: 1px solid var(--dark-mode-300);
        overflow: hidden;
        padding: 2rem;
        position: fixed;
        top: 2rem;
        left: 2rem;
        right: 2rem;
        bottom: 2rem;
        border-radius: 0.25rem;
        background: var(--Neutral-900, #0D0F11);
        z-index: 10;        
        gap: 2rem;

        .tab-container-details {
            overflow-y: scroll;
        }
        .tab-content {
            font-size: 0.875rem;
            line-height: 1.21429rem;
            padding-bottom: 0.625rem;
            box-sizing: border-box;            

            .rentals-content {
                margin-top:3rem;
            }

            .display-info-title {
                text-align: left;
                margin-top:1.5rem;
                margin-bottom:1.5rem;                
            }

            .display-info {                
                display: flex;
                flex-direction: row;                
                gap: 1rem;
                align-items: center;
                min-height: 3rem;

                .display-key {
                    color: var(--cu-white-100);
                    text-align: right;
                    flex:1;
                    margin-bottom:0.5rem;
                }

                .display-value {
                    text-align: left;
                    flex:1;
                    margin-bottom:0.5rem;
                }
            }            
        }

        .display-gallery-edit {
            width:calc(70% - 2rem);     
            
            .display-gallery-container {
                border: 0.3rem solid grey;
                border-radius: 0.5rem;
            }
            
            .display-gallery-header {
                display: flex;             
                justify-content: space-between;   
                padding-bottom:1rem;
                align-items: center;

                .display-header-info {
                    margin-left: 1rem;
                }

                .actions {                                
                    display: flex;
                    justify-content: right;
                    gap: 1rem;
    
                    button {
                        background-color: var(--neutral-200);
                        border: 1px solid var(--dark-mode-300);
                        padding: 0.35rem 1.4rem;
                        border-radius: 0.25rem;
                        font-size: 0.875rem;
                        &:hover {
                            background: var(--Dark-Mode-300, #30333A) !important;
                        }
                    }            
                }
            }

            

            .display-gallery-none {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            .diplay-module-gallery-preview{
                position: relative; 
                // height: 61vh;  
                //height: calc(100% - 13rem);      
                border-radius: .5rem;
                overflow: hidden;
            }     

            .diplay-module-gallery-preview .modern-gallery-preview-container.multi-view{
                :is(.slick-slider,.slick-list,.slick-track,.preview-wrap,.slick-slide>div){
                   height:100%;
                }
                .progress-loader{
                    &.show{
                        width: 100%;
                        position: absolute;
                    }
                }
                .preview-gallery-new {
                height: 100%;
            }
        }

            .gallery-type {
                display: flex;
                width:100%;
                justify-content: center;
                align-items: center;   
                gap:1rem;
                margin-top:2rem;                         
                
                .image-container {
                    width: 10rem;                   
                    border: 1px solid #444;
                    padding: 0.2rem;
                    border-radius: 0.5rem;        
                    height: 6rem;
                    display: flex;
                    justify-content: center;
                    cursor: pointer;                   
                }
                .mono-image {    
                    padding: 0.5rem;

                    img {
                        width:5rem;                        
                    }
                }

                .multi-image {                    
                    padding: 0.5rem;

                    img {
                        width:12rem;
                    }
                }

                .meta-image {
                    img {
                        border-radius: 0.3rem;        
                    }
                }

                .type-selected {
                    border: 1px solid var(--cu-white-100);
                }
            }
        }
        .display-gallery-lists {
            width:30%;
            display: flex;
            flex-direction: column;                                                
        }
    }
  }
  .display-info-overlay {
    position: absolute;
    display: flex;
    border-radius: 0.25rem;
    border: 1px solid var(--dark-mode-300);
    overflow: hidden;
    padding: 2rem;
    position: fixed;
    top: 7rem;
    left: 8rem;
    width: calc(70% - 12.15rem);
    height: 20rem;
    border-radius: 20px;
    background: var(--Neutral-900, #0D0F11);
    z-index: 100;        
    gap: 2rem;

    .details-container-display {
        display: flex;
      }

      .data-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 0.5px solid #30333a;
        border-top: none;
        border-left: none;
        border-bottom: none;
        flex: 1 1 5rem;
        margin-right: 0.5px;
        gap: 0.28571rem;
        &:last-child {
          border: none;
        }
        .data-title {
          text-align: left;
          font-weight: 400;
          font-size: 0.85714rem;
          color: var(--cu-white-100);
          line-height: 1.21429rem;
          @media screen and (max-width: 550px) {
            font-size: 0.75rem;
          }
        }

        .data-value {
          flex: 1;
          word-break: break-word;
          text-align: left;
          font-weight: 400;
          color: var(--cu-white-200);
          font-size: 0.85714rem;
          line-height: 1.21429rem;
          display: flex;
          align-items: center;
          @media screen and (max-width: 550px) {
            font-size: 0.75rem;
          }
        }
      }
  }
//   .tab-container {
//     padding: 1rem 1rem 0rem 1rem;
//     background: var(--neutral-900);
//     .tab-data {
//         cursor: pointer;
//         color: var(--cu-black-10);
//         font-size: .875rem;
//         line-height: 1.21429rem;
//         padding-bottom: 0.5rem;
//         display: flex;
//         gap: 0.25rem;
//         &.active {            
//             color: var(--cu-white-200);
//             border-color: var(--cu-white-200);
//             border-bottom-width: 2px;
//             @media screen and (max-width: 550px) {
//                 border-bottom-width: 1.6px;
//                 border-color: var(--cu-white-550);
//             }
//         }
//     }
// }
.tab-datas {
  border-bottom: solid 1px var(--cu-black-5);;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--cu-black-5);
  font-size: .875rem;
  line-height: 1.21429rem;

  .tabs {
    font-size: .875rem;
  line-height: 1.21429rem;
  padding-bottom:  .625rem;
  box-sizing: border-box;
  position: relative;
  &.not-active{
    &::after{
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 1px;
      left: 50%;
      position: absolute;
      background: var(--cu-white-200);
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    &:hover{
      color: var(--cu-white-200);
      &::after { 
      width: 100%; 
      left: 0; 
    }}
  }
  &.active {
    color: var(--cu-white-200);
    border-bottom: solid 2px var(--cu-white-200);
    
  }
  }
  
}
.display-tag{
    display: flex;
padding: 0.125rem 0.375rem;
align-items: center;
gap: 0.625rem;
border-radius: 0.25rem;
background: var(--Neutral-800, #191D23);
margin-left: .5rem;
}
.display-content {
    .display-info_input {
        color: var(--cu-white-100);
        padding: 0.425rem;
        gap: 0.625rem;
        /* Neutral / 900 */

        background: var(--cu-black-700);
        /* Dark Mode / 300 */

        border: 0.0625rem solid var(--cu-black-100);
        border-radius: 0.25rem;

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        font-size: .625rem;
    }
}

.light-txt {
    input {                
        width: 100%;
    }

    input,
    textarea {
        padding: 0.625rem;
        color: var(--cu-white-200);
        font-size: 0.75rem;
    }
    textarea {
        resize: none;
    }
}

.label-color {
    color: var(--cu-white-100);                    
}