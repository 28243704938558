.create-gallery {
    height: calc(100vh - 13rem);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    &-value {
        font-size: 1rem;
        color: var(--cu-white-900);
    }
    .banner-img-main{
        &.gallery-images{
        height: 100%;
            img{
                height: 100%;
                width: 100%;
            }
        }
    }
    &.create-gallery-continer-sm {
        @media screen and (max-width: 550px) {
            height: calc(100vh - 8rem);
            background: var(--dark-mode-900);
            border: 0px;
            border-radius: 0.125rem;
            margin: 0;
            padding: 1.625rem 1rem;
            overscroll-behavior: contain;
            margin-top: 1rem;
            &.pt-0{
                padding-top: 0rem;
            }
            &.mt-0{
                margin-top: 0rem;
            }
            .cur8-border {
                padding-bottom: 0.25rem;
            }

            .preview-slider {
                width: 100%;
            }
        }

        @media screen and (max-width: 375px) {
            margin: 0;
            height: calc(100vh - 8rem);
        }
    }
}

.create-frame-gallery {
    padding: 1rem 1.1875rem 1.1875rem !important;
}

.gallery-settings {
    height: 100%;
    padding-bottom: 2rem;
    .info-icon{
        &.common-tooltip-with-arrow-tip{
            &::before{
                top: -0.5rem;
                right: 0.1875rem;
            }
            &::after{
                top: 1.5rem;
                right: -5.4rem;
                min-width: 12.5rem;
                text-align: left;
            }
        }
    }
    @media screen and (max-width: 550px) {
        margin-bottom: 0px;
        padding-bottom: 1rem;
    }

    span {
        font-size: 0.71rem;
        color: var(--cu-white-100);
    }

    label {
        font-size: 0.875rem;
        color: var(--cu-white-100);

    }
    .checkbox-wrapper{
       :is(&.discoverable,.set-primary){
        label{
            font-size: 0.875rem;
        }
       } 
       &.discoverable{
        label{
            // margin-top: 1px;
        }
       }
    }

    &-form {
        // min-height: 6.57rem;
        background: var(--cu-black-700);
        resize: none;
        width: 100%;
        border: 1px solid var(--cu-black-100);
        border-radius: 20px;
        font-size: 0.875rem;
        padding: 0.625rem 1.25rem;
        overflow-y: auto;
        overflow-x: hidden;
        &.bg-transparent{
            background:transparent
        }
        input {
            width: 100%;
            background: none;
            border: none;
            outline: none;
            color: var(--cu-white);
            height: 100%;
            word-break: break-all;
            font-size: 0.875rem;
        }

        &::placeholder {
            color: var(--cu-black-5);
            font-size: 0.875rem;
        }

    }

    &-desc {

        &::-webkit-scrollbar{
            width: 0.1875rem;
    }

    }

    &-expand {
        background: var(--cu-black-80);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .error-validation {
        display: flex;
        flex-direction: column;
        font-size: 0.75rem;
    }
}

.gallery-preview {
    span {
        font-size: 0.87rem;
        color: var(--cu-white-100);
        &.background-text{
            font-size: 1rem;
            color: var(--cu-white-900);
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.625rem;
    }
    .filter-field{
        border-bottom: 1px solid var(--cu-black-100);
    }

    &-section {
        border-top: 1px solid var(--cu-black-100);
        border-bottom: 1px solid var(--cu-black-100);
        .react-loading-skeleton {
            height: 15.5rem !important;
        }

    }

    &-image {
        width: 100%;
        height: 100%;


        img {
            height: 100%;
            width: 100%;
            max-height: 5rem;
            object-fit: contain;
            //padding: 0rem 0.6rem;
        }
        &.gallery-frames {
            img {
                border-radius: 0.25rem;
            }
        }
    }

    &-wall {
        width: 100%;
        height: 100%;
    }

    .gallery-images-position {
        height: 100%;
    }

    &-font {
        font-size: 1rem;
    }

    &-label {
        width: 8.62rem;
        font-size: 0.75rem;
        align-items: center;
        display: flex;
        height: 100%;
        color: var(--cu-white-100);
    }
    
}

.select-box-new-gallery {
    .select-box--container {
        min-height: 1.375rem;
        padding-right: 0px;
    }

    .select-box-items {
        margin-top: 0.2rem;
        font-size: 0.875rem;
        line-height: 1.0625rem;
        width: 100%;

        li {
            padding: 0.6rem;
        }
    }
}

.select-box-new-gallery-date {

    .select-box-open .select-box-items,
    .select-box-items {
        border: 0px transparent !important;
    }
}

.select-box-new {
    padding: 0.3rem;

}

.desc {
    resize: none;
    padding: 0.625rem 1.25rem;
}

.nftdrop-options {
    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
        gap: 0.5rem;
        row-gap: 1rem;
        @media screen and (max-width: 550px) {
        grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
        }
    }
}

.grid-3D {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(6.18rem, 1fr));
    gap: 0.625rem;
    max-width: 100%;

    .gallery-images-position {
        .close {
            img {
                margin-right: 0.2rem;
                margin-top: 0.2rem;
            }

        }
    }
}

.gallery-3D {
    .close {
        opacity: 1;
    }
}


.gallery-popup {
    border-radius: 0.5rem;

    .popup-box {
        height: calc(100vh - 33rem);
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 0.31rem;
        max-height: 24rem;

    }
}

.gallery-popup {
    .popup-template-body {
        padding: 1rem;

        .gallery-images-position {
            max-height: calc(100vh - 17rem);
            width: 100%;
        }

        .gallery-3D {
            width: 100%;
        }
    }

    .popup-template {
        @apply inset-0
    }
}

.close-icon-gallery {
    cursor: pointer;
    background: var(--cu-grey-100);
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 0.0625rem solid var(--cu-grey-500);
    font-size: 1.25rem;

}

.table-gallery {
    flex-basis: calc(100% - 29rem);
    margin-right: 0.2rem !important;

}

.no-frame {
    background: var(--cu-black-700);
    border: 0.0625rem solid var(--cu-black-100);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
    min-height: 6rem;

    .no {
        font-size: 2rem;
        background: -webkit-linear-gradient(180deg, #00C5FF 0%, #4745ED 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .frame {
        margin-bottom: 0.3125rem;
        margin-top: auto;
        font-size: 0.7rem;
        line-height: 0.75rem;
    }
}

.preview-slider {
    width: 27rem;
    max-height: 15.5rem;
    .gallery-slider-1 .slick-list{
        max-height: 15.5rem;
    }
}

.view-more {
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: 0.75rem;
    border: none;

    &-disable {
        color: var(--cu-grey-500)
    }

    &-active {
        color: var(--cu-white-900)
    }
}

.gallery-create {
    .tab-page ul li {
        margin-top: 0px;
        border-radius: 0px 0px 9px 9px;
    }
}

@media screen and (max-width: 766px) {

    .gallery-preview-card {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 550px) {

    .gallery-preview-card {
        justify-content: center;
    }
}

.new-release-img {
    img {
        width: 1.5rem;
        height: 1.5rem;
    }

}

.gallery-nft-drop {
    &-form {
        background: var(--cu-black-700);
        border: 1px solid var(--cu-black-100);
        border-radius: 0.28rem;

        input {
            padding: 0.375rem 0.5rem;
            width: 100%;
            background: none;
            border: none;
            outline: none;
            color: var(--cu-white-100);
            height: 100%;
            word-break: break-all;
            font-size: 0.82rem;
            letter-spacing: 0.02em;
        }


    }
}

.curate-form-sm {
    @media screen and (max-width: 550px) {
        margin-top: 0;
    }
}

.wall-border {
    border-radius: 0.3rem;
    border: 1px solid transparent;
}
// .wall-image-gallery {
//     height: 4rem;
// }
.create-gallery-btn {
    @media screen and (max-width: 550px) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 1rem;
        background: var(--cu-black-500);
        z-index: 9;
    }
}

.wizard {
    @media screen and (max-width: 550px) {
        background: var(--cu-black-500);
        
        span {
            font-size: 0.625rem;
        }

    }
    border-bottom: 1px solid var(--dark-mode-300);
    ul {
        display: flex;
        width: 100%;
        color: var(--cu-black-10);
        li {
            @media screen and (max-width: 550px) {
                .gallery-create {
                    background: var(--cu-black-500) !important;
                }

                &:first-child,
                &:nth-child(2) {
                    .gallery-create {
                        width: 94% !important;
                    }
                }
            }

            position: relative;
            height: 1.875rem;

            .gallery-create {
                position: absolute;
                top: 0px;
                left: 1px;
                right: 1px;
                bottom: 1px;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }
            }

            // &:first-child {
            //     background: var(--dark-mode-300);
            //     z-index: 3;
            //     clip-path: polygon(0% 0%, 93% 0%, 100% 50%, 93% 100%, 0% 100%);
            //     border-top-left-radius: .35rem;
            //     .gallery-create {
            //         width: 95%;
            //         clip-path: polygon(0% 0%, 94% 0%, 100% 50%, 94% 100%, 0% 100%);
            //         border-top-left-radius: .35rem;
            //     }
            // }

            // &:nth-child(2) {
            //     background: var(--dark-mode-300);
            //     z-index: 4;
            //     margin-left: -1rem;
            //     clip-path: polygon(93% 0%, 100% 50%, 93% 100%, 0% 100%, 6% 50%, 0% 0%);

            //     .gallery-create {
            //         width: 95%;
            //         clip-path: polygon(94% 0%, 100% 50%, 94% 100%, 1% 100%, 6% 50%, 1% 0%);
            //     }
            // }

            // &:nth-child(3) {
            //     background: var(--dark-mode-300);
            //     margin-left: -1rem;
            //     z-index: 4;
            //     clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 0% 100%, 6% 50%, 0% 0%);

            //     .gallery-create {
            //         width: 99%;
            //         clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 1% 100%, 6% 50%, 1% 0%);

            //     }
            // }


            &.active-wizard {
                // background: var(--dark-mode-300) !important;
                .gallery-create {
                    left: 0px;
                    right: 0px;
                    bottom: 1px;
                    top: -0px;
                    // background: var(--dark-mode-300) !important;
                }
            }

            display: flex;
            justify-content: center;
            transition: width .1s ease-in;
            flex: 1;
            padding: 0.39rem 0px 0.39rem 0px;
            
            &.active-wizard {
                // background: var(--cu-black-50);
                color: var(--cu-white) !important;
                border-bottom: 1px solid var(--cu-white-900);
            }

        }
    }
}
.tab-top-container {
    height: 15.5rem;
    max-height: 15.5rem;
    background: var(--cu-black-700);

    @keyframes popIntoFrameEffectKF {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
    .popIntoFrameEffect {
        animation: popIntoFrameEffectKF 0.4s linear;
    }
}
.personal-info {
    .desc {
        height: 28px;
    }
}

.nft-drop-options {
    .form {
        height: 28px;

        input {
            color: var(--cu-white-200);
        }


    }

    .select-box-new {
        color: var(--cu-white-200);
    }

    .gallery-time {
        color: var(--cu-white-900);
    }
}

.add-to-gallery {
    display: none;

    @media screen and (max-width: 550px) {
        display: unset;
    }
}

.settings-gal {
    background: var(--cu-black-500);
    padding-bottom: 1rem;

    @media screen and (max-width: 425px) {
        padding-bottom: 3rem;
    }
}

.preview-skelton {
    .skeltonMainClass {
        top: 4rem;
        width: 100%;
    }
}

.glry-arrang {
    @media screen and (max-width: 550px) {
        max-width: calc(100vw - 2rem);
    }
}

.wall-style {
    @media screen and (max-width: 550px) {
        padding-bottom: 1.5rem;
    }
}

.step-component {
    .steps-container {
        padding: 0 1rem 0.625rem 1rem;
        &.bottom-line {
            border-bottom: 1px solid var(--neutral-100);
            padding-bottom: 0.5rem;
        }
    }
    .tabs {
        display: flex;
        gap: 1rem;
        &.bottom-line {
            border-bottom: 1px solid var(--dark-mode-300);
            padding-bottom: 0.5rem;
        }
        .tab-item {
            cursor: pointer;
            color: var(--cu-black-5);
            &:is(&.active,:hover) {
                color: var(--cu-white-200);
            }
        }
    }
    .item-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border-bottom: 1px solid var(--dark-mode-300);
        padding-bottom: 0.5rem;
        &.btm-brd-none {
            border-bottom: none;
        }
        .items-data {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            gap: 1rem;
            span {
                color: var(--cu-black-5);
                font-size: 0.85rem;
            }
        }
    }
    .frame-toggle {
        font-size: 0.85rem;
        color: var(--cu-black-5);
        &:is(&.text-active,:hover) {
            color: var(--cu-white-200);
        }
    }
    .frame-listing {
        .skeltonMainClass{
            aspect-ratio: 1;
        }
    }
}