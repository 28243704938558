.no-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    &.custom-no-data-banner{
        .custom-image-container{
            img{
                max-height: 20rem;
            }
        }
    }
.custom-image-container{
    min-height: 8.3rem;
    display: flex;
    justify-content: center;

}
.reusable_dropdown-container{
    width: 100%;
    max-width: 21rem;
    .plus_button-style{
        width: 100%;
        opacity: 1;
    }
    .reusable_dropdown-list-box{
        width: 100%;
        position: relative;
    }
}
    h1 {
        color: var(--cu-white-200);
        font-size: 2rem;
        font-weight: 500;

    }

    p {
        color: var(--cu-white-200);
        font-size: 1rem;
    }

    .btn-dark {
        color: var(--cu-white-200);
        font-size: 0.875rem;
        padding: 0.875rem;
        border-radius: 1.25rem;
        max-width: 21rem;
    }
    &.no-results{
        h1{
            font-size: 1rem;  
            font-weight: 400;
        }

    }
}