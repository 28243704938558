@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'theme/default';
@import 'theme/color';
@import 'theme/dynamicVariable';
@import 'font/font';
@import 'forms/forms';
@import'components/spinner';
@import'components/text';
@import'components/popup';
@import'components/accordion';
@import'components/table';
@import'components/slider';
@import'components/buttons';