.event-container {
    .event-card {
        padding: 0.55rem 0;
        border-radius: 0.38571rem;
        // border: 1px solid var(--cu-black-100);
        box-shadow: 0 0 0 0.5px var(--cu-black-100);
        width: 100%;
        height: 25rem;
        overflow: hidden;
        transition: all 0.4s;
            .icons-overlay-edit-delete{
              visibility: hidden;
              opacity: 0;
          }
          .event_desc_box {
            padding: 0 0.55rem;
            text-align: left;
            font-size: 80%;
            transition: all 0.4s;
            .event_details_box {
                transition: all 0.4s;
                height: 0%;
                opacity: 0;
                overflow-y: scroll;
            }
        }
        &:hover {
            cursor: pointer;
            background: #191D23;
            .icons-overlay-edit-delete{
                visibility: visible;
                opacity: 1;
             }  
            .event_details_box {
                opacity: 1;
                height: 100%;
                width: 98%;
                background-color: #191D23;
            }
        }
        @media screen and (max-width: 550px){ 
            padding: 0.4rem;
            font-size: 0.625rem;
            min-height: 15rem;
        }          
        .head {
            .time {
                color: var(--cu-black-5);
                font-size: 0.75rem;
                @media screen and (max-width: 550px){ 
                    font-size: 0.625rem;
                }
            }
            .title {
                color: var(--cu-white-200);
                @media screen and (max-width: 550px){ 
                    font-size: 0.625rem;
                }
            }
            @media screen and (max-width: 550px){ 
                flex-direction: column;
                align-items: start;
            }
        }
        .body {
            position: relative;
            min-height: 11rem;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            .event-body-following {
                position: absolute;
                right: 2%;
                top: 4%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding:0 5px 3px 5px;
                border-radius: 2px;
                background: var(--cu-black-800);
                color: var(--cu-white-100);
                font-size: 0.8rem;
                opacity: 0.8;
                z-index: 3;
            }
            @media screen and (max-width: 550px){ 
                height: 5.375rem;
                .event-body-following {
                    font-size: 0.6rem;
                }
            }  
        }
        .foot {
            color: var(--cu-white-200);
             white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            @media screen and (max-width: 550px){ 
                font-size: 0.75rem;
               
            }
        }
    }
}