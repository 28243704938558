.popup {
    &-overlay {
        position: fixed;
        @apply inset-0;
        background: var(--cu-black-trans-600);
        z-index: 10;
        opacity: 0;
        visibility: hidden;
    }
    .main-only{
        position: absolute;
    }

    &-overlay,
    &-wrapper {
        transition: all .5s ease;
    }

    &-wrapper {
        max-height: max-content;
        margin: auto;
        display: flex;
        flex-direction: column;
        padding: 3.42rem;
        position: fixed;
        @apply inset-0;
        border-radius: 2.14rem;
        z-index: 11;
        opacity: 0;
        visibility: hidden;

    }

    &-template {
        max-height: max-content;
        margin: auto;
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 1rem;
        border-radius: 2.14rem;
        z-index: 11;
        opacity: 0;
        visibility: hidden;

        &-body {
            display: flex;
            padding: 32px 64px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 42px;
            border-radius: 0.25rem;
            border: 1px solid var(--dark-mode-300, #30333A);
            background: #14171F;
        }

    }

    &.popupActive {

        .popup-overlay,
        .popup-wrapper,
        .popup-template {
            opacity: 1;
            visibility: visible;
        }
    }

    &-title {
        font-size: 1.42rem;
        font-weight: 600;
        color: var(--cu-white);
    }

    &-body {
        padding-top: 3.57rem;
        max-height: calc(100vh - 10rem);
        padding-bottom: 0.5rem;
    }

}