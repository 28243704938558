.dropdown-select {
    position: relative;
    height: 100%;
    // width: 2rem;
    border-radius: 0.375rem;
    display: grid;
    align-items: center;
    cursor: pointer;
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 0.25rem;
        padding: 1px;
        // border: 1px solid #30333A;    
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude; 
        z-index: 9;
      }


    &.loading {
        padding: 0.5rem;
    }


    .wallet-select-available{
        top: 3.4rem;
    }
    &.close-popup .wallet-select-available {
        visibility: hidden;
        opacity: 0;

    }

    &.open-popup .wallet-select-available {
        visibility: visible;
        opacity: 1;
    }
    

    &-available {
        position: absolute;
        right: 0;
        top: 3.4rem;
        width: 100%;
        z-index: 15;
        background: var(--dark-mode-900);
        // border: 1px solid var(--dark-mode-300);
        border-radius: 0.3125rem;
        transition: .5s ease;
        min-width: 8rem;
        ul {
            padding: .375rem 0;
            color: var(--cu-white-200);
            font-size: .75rem;
            padding-bottom: .5rem;
            text-align: left;
            li {
                grid-gap: 0.5rem;
                align-items: center;
                color: var(--cu-black-5);
                cursor: pointer;
                display: grid;
                font-size: 0.75rem;
                gap: 0.5rem;
                grid-template-columns: 2rem calc(100% - 5rem) 2rem;
                justify-content: start;
                text-align: left;
                padding: 0.5rem;
                text-transform: capitalize;
            
            }
        }
    }
    
}

.drop-down {
    position: absolute;
    top: 100%;
    background: var(--dark-mode-900);
    border-radius: 0.25rem;
    right: 0rem;
    font-size: .75rem;
    z-index: 101;
    color: var(--cu-white-200);
    text-align: left;
    overflow: hidden;

    input {
        width: 0;
        height: 0;
        position: absolute;
    }

    li {
        cursor: pointer;
        white-space: nowrap;
        line-height: 1.5rem;
    }

    .res-elements {
        display: none;
    }

    &-closed {
        .drop-down-items {
            border: 0;
            animation: fadeOut .1s;
        }
    }
    
        .drop-down-items {
        
            margin-top: 0 ;
            li {
                
              
                font-size: 0.8125rem;
                color: var(--cu-black-5);
                .hover-icon {
                    display: none;
                }
                .non-hover-icon {
                    display: block;
                }
                .drop-icon {
                    width: 1rem;
                }
                &:hover, 
                &.selected {
                    color: var(--cu-white-200);
                    .hover-icon {
                        display: block;
                    }
                    .non-hover-icon {
                        display: none;
                    }
                }
            }
        
    }

    ul {
        z-index: 100;
    }

    @media screen and (max-width: 768px) {

        .res-elements {
            display: block;
        }

        li {
            font-size: .625rem;
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: transparent !important;
}
.filter-box{
    position: relative;
    display: flex;
    align-items: center;
    width: 1.5rem;
}
.user-avatar-box {
    position: relative;
    display: flex;
    align-items: center;



    .profile-box {
        border-radius: 50%;
        border: 1px solid #30333A;    
        padding: .0625rem;




        &.no-profile-box {
            border-radius: 50%;
            border: .0625rem var(--cu-black-30) solid;
            background: transparent;
        }

    }

    .user-avatar,
    .profile-pic {
        border: 0;
        border-radius: 50%;
        padding: 0.4rem;
        width: 2.6rem;
        height: 2.6rem;
    }

    .user-avatar {
        height: 2rem;
        width: 2rem;
    }

    .profile-pic {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &.no-pro-pic{
                background-size: contain;
        }
    }


    .down-arrow {
        margin-left: .5rem;
        right: 0;
        top: 0.75rem;
        width: .6rem;
        height: .6rem;

    }
    .head-dropdown {
        width: max-content;
    }
}