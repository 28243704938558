.preview-user {
    padding: 0px !important;
    background: var(--cu-black-500) !important;

    .custom-round-btn {
        width: auto;
        height: auto;
    }
}

.user-details-name {
    font-weight: 600;
    font-size: 1.125rem;
    color: var(--cu-white-200);
}

.user-details-value {
    word-break: break-all;
}

.asset-count {
    color: var(--cu-black-5) !important;
}

.user-details-desc {
    font-weight: 400;
    font-size: 1rem !important;
    color: var(--cu-white-200) !important;

}

@media screen and (max-width: 550px) {
    .user-container {
        display: none;
    }

    .user-details-view {
        padding-top: 0px;
    }
}