.access-plans {
    @media screen and (max-width: 550px) {
        padding-top: 0.5rem;
        padding-bottom: 3rem;

    }
   
    .card {
        width: 100%;
        background: transparent !important;
        padding: 0px;
        padding-right: 1rem;

        img {
            border-radius: 0.6rem;
            height: 10rem;
            object-fit: cover;

        }
    }
        .heading{
            color:var(--cu-white-200);  
            @media screen and (max-width: 550px) {
                color:var(--cu-white-200);
            }
        }
    .right-card {
        width: 100%;
        background: transparent !important;
        padding: 0px;
        padding-left: 1rem;

        img {
            border-radius: 0.6rem;
            height: 10rem;
            object-fit: cover;
        }
    }

    .stake-value {
        color: var(--neutral-100, #E7EAEE);
        text-align: center;
    }
    .boxwrapper{
        background: var(--cu-black-500);
        @media screen and (max-width: 550px) {
            background: transparent;
            border: 0.0625rem solid var( --cu-grey-860) !important;
            padding: 1.25rem !important;
        }
    }
    .pln {
        border: 0.0625rem solid var(--cu-black-100);
        border-radius: 0.625rem;
        height: fit-content;

        @media screen and (max-width: 550px) {
            border: 0px;
            padding: 0px;
            gap: 0.125rem;

        }

        @media screen and (max-width: 425px) and (min-width: 400px) {
            border: 0px;
            padding: 0px;
            gap: 1.4rem;
        }

        .access-learn {
            color: var(--cu-black-5);
            text-decoration: underline;
            font-size: 0.75rem;
            @media screen and (max-width: 550px) {
                text-align: left;
            }
        }

        .tier {
            font-size: 0.75rem;
            text-align: initial;
            color: var(--cu-black-5);

            @media screen and (max-width: 550px) {
                padding-left: 0.625rem;
            }

            span {
                font-size: 0.75rem;
                color: var(--cu-white-200);
            }
        }


    }

    .min-stake {
        color: var(--neutral-100, #E7EAEE);
        font-size: 0.75rem;
        line-height: 0.4375rem;

    }

    .access-box-container {
        opacity: 40%;

        &.plan-selected {
            opacity: 100%;
        }
    }

}

progress[value]::-webkit-progress-value {
    background: var(--cu-blue-70);
    border-radius: 0.25rem;
    height: 0.4375rem;
}

progress[value]::-webkit-progress-bar {
    background-color: var(--cu-white-900);
    border-radius: 0.25rem;
    height: 0.4375rem;
}

@media screen and (max-width: 768px) {
    .wrap {
        flex-wrap: wrap;
        display: contents !important;
    }

    .cardmob {
        width: 100% !important;
    }


    .card,
    .right-card {
        width: 100% !important;
        align-items: center;
        padding: 0px !important;

        img {
            display: initial;
        }
    }
}

.cardmobs {
    width: 34%;
}

.no-access {
    border: 1px solid var(--cu-black-100);
    border-radius: 1rem;
    border-style: dashed;
    height: 10rem;
    display: flex;
    align-items: center;
}


.pln-mob-head {
    background-color: transparent;
    top: 6.125rem;
    position: fixed;
    height: 2rem;
    background: var(--cu-black-700);
}
.plan-wrapper{
    @media screen and (max-width: 550px) {
    max-width: 15.625rem;
    margin: 0 auto;
    }
}