.title-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.625rem;
    background: var(--cu-black-500);
    width: 100%;
    min-height: 4.75rem;
    box-shadow: inset 0px -1px 3px var(--cu-black-900);
    &.nav-bar{
        display: block;
        @media screen and (min-width: 550px) { 
            height: 100%;
        }
    }
    .nav-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.625rem; 
        width: 100%;
        margin-left: 1.5rem;
        @media screen and (max-width: 550px) { 
            margin-left: 0rem;
        }
    }
    .web-filter{
        display: inline-block;
        @media screen and (max-width: 550px) {
      display: none;
        }

    }

    .mob-filter{
        display: none;
        @media screen and (max-width: 550px) {
            display: block;
            width: 100%;
            position: fixed;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 16;
            // margin: 0.6rem auto 0.25rem auto;
        }

    }
    .home-auth-pop {
        position: fixed;
        top: 38%;
        left: 38%;
    }
    .home-wallet-pop {
        position: fixed;
        top: 27%;
        left: 42%;
    }
    @media screen and (max-width: 550px) {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 5;
    }

    .register-btn {
        font-size: 0.875rem !important;
        // width:5rem  !important;
        padding: 1rem 3rem;
        @media screen and (max-width: 1030px) {
            font-size: .625rem !important;
        }


    }
    &.collpased {
        gap: 0;
        .logo-box {
            flex: 0;
            min-width: 2.8rem;
        }
    }
    .logo-box {
        height: 100%;
        // flex: 0 0 12.8rem;
        min-width: 6.625rem;

        img {
            height: 3.651rem;
        }
        .head-logo {
            display: flex;
            // align-items: end;
            .beta{
                font-weight: 500;
                font-size: 1rem;
                line-height: 21px;
                color: var(--cu-white-100);
            }
            @media screen and (max-width: 550px) {
                height: 2rem;
                img{
                height: 100%;
                }
            }
            img {
                height: 0;
                visibility: hidden;
                opacity: 0;
                transition: all 0.5s;
                transition-delay: 1s;
            }
        }
        img.collpased-logo {
            height: auto;
            visibility: visible;
            opacity: 1;
            @media screen and (max-width: 550px) {
                height: 100%;
            }
            cursor: pointer;
        }
        img.expanded-logo {
            height: auto;
            width: 9rem;
            margin-left: .8rem;
            visibility: visible;
            opacity: 1;
            @media screen and (max-width: 550px) {
                height: 100%;
                margin-left: 0rem;
            }
            cursor: pointer;
        }

        .alpha-text {        
            font-size: 1rem;
            background: -webkit-linear-gradient(#00C5FF, #4745ED);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 500;
            margin-left: 0.5em;
        }
    }

    @media screen and (max-width: 1030px) {
        .logo-box {
            flex: 0 0 9.3rem;
        }
    }

    @media screen and (max-width: 550px) {
        .logo-box {
            display: flex;
            align-items: center;
        }
    }

    .signIn-button,
    .trial-button {
        // width: 4.5625rem;
        // height: 2.3125rem;
        color: var(--Neutral-50, var(--cu-white-200)) !important;
        
    }
    .trial-button{
        width: 2.5625rem;
    }
    .trial-button, .signIn-button {
        button {
            background: unset;
        }
    }
    .trial-button button {
        border-color: var(--cu-black-30);
        color: var(--cu-black-5);
    }
    .register-button {
        width: 4.6875rem;
    }

    .icons {
        width: 0.938rem;
        height: 0.938rem;
    }

    .search-bar-sm {
        display: none;
    }

    .search-bar-lg {
        // height: 2.75rem;
        // flex: 1 1 55rem;

        .search-input {
            height: 2.75rem;
            border-radius: 0 .6875rem .6875rem 0;
            padding-left: 1rem;
            padding-right: 2.5rem;
            font-size: .875rem;
            color: var(--cu-white-900);
            background-color: var(--cu-black-700);

            &:focus-visible {
                outline: 0;
                outline-color: transparent;
                outline-style: none;
                outline-width: 0;
            }
        }

        .select-arrow {
            position: relative;
            position: absolute;
            right: 0.75rem;
        }

        .search-select {
            height: 2.75rem;
            width: 4.375rem;
            background-color: var(--cu-black-500);
            border-radius: .6875rem 0 0 .6875rem;
            color: var(--cu-black-5);
            padding: 0 1.5rem 0 0.75rem;
            font-size: .75rem;
            -moz-appearance: none;
            /* Firefox */
            -webkit-appearance: none;
            /* Safari and Chrome */
            appearance: none;
        }

        .search-icon {
            position: absolute;
            right: 1rem;
        }
    }

    .settings {
        width: 6rem;
        padding: 0.907rem 0;
    }

    .wallet-short {
        background-image: linear-gradient(180deg, #00C5FF 0%, #4745ED 100%);
        border-radius: .375rem;
        padding: .0625rem;
        display: flex;
        height: 2.5rem;
        margin-left: .625rem;
        width: 9.1875rem;

        .input {
            width: 0 !important;
            height: 0 !important;
            padding: 0 !important;
            position: absolute;
            border: 0 !important;
        }

        .wallet-drop-down {
            background-color: var(--cu-white-900);
            border-radius: .3125rem 0px 0px .3125rem;
            padding: .5439rem .5rem .5rem .3575rem;
            display: flex;
            position: relative;

            .wallet-icon {
                width: 1.25rem;
                height: 1.375rem;
            }

            .wallet-arrow {
                width: .5624rem;
                margin-left: .376rem;
                margin-right: .5rem;
            }

            .select-box--selected-item {
                display: inline-block;
                height: 100%;
                width: 100%;
                vertical-align: middle;
            }

            .select-box--items {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                background: var(--cu-white-900);
                border: 0.0625rem solid var(--cu-black-100);
                border-radius: .25rem;
                text-align: left;
                z-index: 10;
                cursor: pointer;

                div {
                    padding: .625rem;

                    &:hover,
                    &.selected {
                        background-color: var(--cu-black-50);
                    }
                }
            }
        }

        .wallet-balance {
            background-color: var(--cu-black-700);
            border-radius: 0 .375rem .375rem 0;

            .wallet-balance-frame {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                display: grid;
                place-items: center;
                padding: .625rem;
                margin: 0 .75rem 0 .375rem;
                white-space: nowrap;
                width: 4.8125rem;
            }
        }
    }

    .title-btns {
        // flex: 0;

        .link-wallet-message {
            display: flex;
            gap: 1rem;
            align-items: center;
            bottom:1em;
            color: var(--cu-black-5);
            background: var(--cu-black-500);
            padding: 0.5rem 0.75rem;
            border-radius: 0.5em;
            left:0;
            right:0;
            margin:auto;
            width: fit-content;
            height: 2.75rem;
            display: flex;
            border: 1px solid var(--dark-mode-300);
            cursor: pointer;
            transition: flex-grow 0.5s ease;
            &.no-link-wallet-section{
                &:hover{
                    border-color: var(--cu-black-30);
                }
            }
            &.link-wallet-section{
                padding: 0.5625rem 0.75rem;
            }
            .info-icon {
                width: 1.5rem;
                height: 1.5rem;
                margin: auto 1.4em auto 0;
                path {
                    stroke: #333333 !important;
                }   
            }
            .collection-loading {
                animation: rotation 2s infinite linear;
                @keyframes rotation {
                    from {
                      transform: rotate(0deg);
                    }
                    to {
                      transform: rotate(359deg);
                    }
                }
            }
            .wallet-icons {
                &.hide-icon {
                    display: none;
                    flex-grow: 0;
                }
            }
            
            .link-message {
                // width:8rem;
                color:var(--cu-white-200);
                text-align: left;
                transition: all 0.3s;
                // overflow: hidden;
                // text-wrap: nowrap;
                // &::after{
                //     pointer-events: none;
                //     content: attr(data-text);
                //     min-width: 9rem;
                //     white-space: pre-wrap;
                //     height: auto;
                //     background: var(--cu-black-700);
                //     position: absolute;
                //     right: 50%;
                //     transform: translate(50%, 10%);
                //     font-size: 0.75rem;
                //     padding: 0.625rem;
                //     opacity: 0;
                //     border-radius: 0.25rem;
                //     transition: opacity 0.3s ease;
                // }
                // &::before{
                //     content: "";
                //     position: absolute;
                //     pointer-events: none;
                //     right: 50%;
                //     transform: translate(50%, -10%);
                //     opacity: 0;
                //     transition: opacity 0.3s ease;
                //     border-style: solid;
                //     border-width: 27px 6px 6px;
                //     border-color: transparent transparent var(--cu-black-700) transparent;
                // }
                // &:hover{
                //     &::after,&::before{
                //         opacity: 1;
                //         z-index: 20;
                //     }  
                // }
                .link-btn{
                    padding:0.3125rem 1.25rem;
                    border-radius: 0.75rem;
                }
            }            
    
            .close-icon {
                // height: fit-content;
                // margin: 0.2em 0 auto 1em;
            }
        }        

        .link-wallet-message-contract {
            // .link-message {
            //     width:0px;
            // }

            // .close-icon { 
            //     display: none;
            // }
        }
    }

    .user-avatar-box {
        position: relative;
        display: flex;
        align-items: center;



        .profile-box {
            border-radius: 50%;
            border: 1px solid #30333A;    
            padding: .0625rem;




            &.no-profile-box {
                border-radius: 50%;
                border: .0625rem var(--cu-black-30) solid;
                background: transparent;
            }

        }

        .user-avatar,
        .profile-pic {
            border: 0;
            border-radius: 50%;
            padding: 0.4rem;
            width: 2.6rem;
            height: 2.6rem;
        }

        .user-avatar {
            height: 2rem;
            width: 2rem;
        }

        .profile-pic {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }


        .down-arrow {
            margin-left: .5rem;
            right: 0;
            top: 0.75rem;
            width: .6rem;
            height: .6rem;

        }
        .head-dropdown {
            width: max-content;
            
        }
    }

    .search-bar-container {
        border: 1px solid var(--cu-black-30);
        border-radius: 0.7rem;
    }

    @media screen and (max-width: 550px) {
        padding: .5rem 1rem;
        min-height: 3.125rem;
        gap: 0;
        // box-shadow: inset 0 0 10px black;

        .logo-box {
            flex: 1;
            margin: 0;

            img {
                height: 1.95125rem;
            }
        }

        .search-bar-sm {
            display: flex;
            margin-right: 0.5rem;

            .search-icon {
                height: 1.875rem;
            }


            .wallet-select-label-connector {
                padding: 0.5rem !important;
            }


            .header-wallet {
                height: 1.875rem;



            }
        }

        .search-bar-lg {
            display: none;
        }

        .title-btns {
            margin: 0;

            .signIn-button,
            .trial-button {
                width: 4rem;
                height: 1.5rem;

                button {
                    border-radius: .4rem;
                    font-size: .625rem;
                }
            }

        }

        .user-avatar-box {

            .user-avatar,
            .profile-pic {
                width: 1.875rem;
                height: 1.875rem;


            }

            .down-arrow {
                width: .5rem;



            }
        }
    }

}

@media screen and (max-width: 550px) {
    .mob-head {
        display: flex;
        gap: 3rem;
        align-items: center;
        margin-right: 0rem;
    }
    .scroll-menu-header{
        padding: 0rem 0rem;
    }
    .scroll-menu-div{
        padding: .375rem 1rem;
    }
}

.header-wallet {
    .wallet-select-label-connector {
        padding: 0.5rem !important;
    }
}
.register-pop div:nth-child(1) {
    z-index: 11;
}
.register-pop div:nth-child(2) {
    max-width: 30rem;
    left: 0;
    right: 0;
    top: 0;
}
.shadow-div{
    @media screen and (max-width: 550px) {
        display: block;
        height: 6px;
        width: 100%;
        box-shadow: 2px 2px 4px 0px #000 inset;
        position: fixed;
        left: 0;
        right: 0;
        top: 50px;
        z-index: 5;
    }  
}
.full-page-loader {
    position: fixed !important;
}