.date-picker {
    background-color: var(--cu-black-800);
    border-radius: 0.57rem;
    width: fit-content;
}
.rmdp-analog-clock{
    display: none;
}
.rmdp-wrapper {
    background-color: var(--cu-black-800) !important;
    border-radius: 0.35rem !important;
}

.rmdp-day.rmdp-today span {
    background-color: transparent !important;
    color: var(--cu-gray-960) !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: var(--cu-blue) !important;
    color: var(--cu-white) !important;
}

.rmdp-week-day {
    color: var(--cu-gray-960) !important;
}

.rmdp-day span {
    color: var(--cu-gray-960) !important;
}

.rmdp-month-picker,
.rmdp-year-picker {
    background-color: var(--cu-black-800) !important;
}

.rmdp-border-bottom {
    border-bottom: 0px !important;
}

.rmdp-header-values {
    margin: auto !important;
    color: var(--cu-white) !important;
}

.rmdp-shadow {
    box-shadow: 0 0 0px !important;
}

.rmdp-arrow-container:hover {
    background-color: transparent !important;
    box-shadow: 0 0 0px !important;
}

.rmdp-arrow-container {
    height: 2rem !important;
    width: 2rem !important;
    border: 0.05rem solid var(--cu-black-trans-250) !important;
    border-radius: 0.57rem !important;
}

.rmdp-arrow {
    border: solid var(--cu-blue-100) !important;
    border-width: 0 0.14rem 0.14rem 0 !important;
    margin-top: 0.71rem !important;
}

.rmdp-calendar {
    height: max-content !important;
    padding: 0.64rem !important;
}

.rmdp-range.start {
    background-color: var(--cu-blue) !important;

    .sd {
        color: var(--cu-white-900) !important;
    }
}
.rmdp-range.end {
    background-color: var(--cu-blue) !important;
    .sd {
        color: var(--cu-white-900) !important;
    }
}
.rmdp-range {
    .sd {
        color: var(--cu-white-900) !important;
    }
}

.rmdp-day-picker {
    padding: 0.64rem !important;
    gap: 2.07rem !important;
}

.date-desk .popup-template-body {
    width: fit-content;
    padding: 0px !important;
    gap:0rem;
    background:var(--cu-black-800);
}
.rmdp-day.rmdp-selected span:not(.highlight) {
box-shadow: unset;
}
.active{
.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: var(--cu-blue) !important;
    color: var(--cu-white-100) !important;

}
}
