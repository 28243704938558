.collectors_community_list_container {
  padding: 0rem 1rem 2rem 1rem;
  // overflow-y: scroll;
  // height: calc(100vh - 26.5rem);
}

.collector_communities_category-listing {
  padding: 0rem 1rem 1rem 1rem;
  // height: calc(100vh - 26.5rem);

  // .infinite-scroll-component {
  //     padding-right: 1rem;
  //     padding-bottom: 3rem;
  // }
  .skeltonMainClass {
    .details-skeleton {
      display: flex !important;
    }
  }
}