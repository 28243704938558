.select-box--container {
    .has-value{
        color:var(--cu-white-200) !important;
    }
    background: var(--cu-black-700);
    border: 0.0625rem solid var(--cu-black-100);
    border-radius: .25rem;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 2rem;
    &.disabled{
        cursor: no-drop;
    }
}

* {
    box-sizing: border-box;
}



.select-box--selected-item {
    display: inline-block;
    height: 100%;
    width: 100%;
    vertical-align: middle;


}


.select-box--arrow {
    min-width: 1.5rem;
    padding-right: .625rem;
    margin-left: .125rem;
}

.select-box {
    position: relative;
    &.disabled {
        cursor: not-allowed;
    }
    &-items {
        position: absolute;
        top: 2.4rem ;
        left: 0;
        right: 0;
        z-index: 9;
        overflow: hidden;
        max-height: 0;
        background: var(--dark-mode-900);
        border-radius: .25rem;
        border: 1px solid var(--cu-black-100);
        text-align: left;
        z-index: 100;
        color: var(--cu-white-200);
        cursor: pointer;
        white-space: nowrap;
        min-width: -moz-fit-content;
        min-width: -webkit-fill-available;
        min-width: fit-content;
        width: 10rem;
        @media screen and (max-width: 550px) {
            width: 100%;
        }
        li {
            padding: .5rem 1rem;
            line-height: 1rem;
            color: var(--cu-black-5);
            font-size: .8125rem !important;

            &:hover,
            &.selected {
                color: var(--cu-white-200);
            }

            &.selected {
                color: var(--cu-white-200);
            }
        }
        .li_for_heading {
            color: var(--cu-white-100);
            font-size: .875rem !important;
            margin: 0 0 -0.2rem 0;
            cursor: text;
            &:hover {
                color: var(--cu-white-100);
            }
        }
    }

    &-selected-item {
        display: inline-block;
        height: 100%;
        width: 100%;
        vertical-align: middle;


    }

    &-closed {


        .select-box-items {
            border: 0;
            animation: fadeOut .1s;
        }

        .selectbox-label {
            &::after {
                transform: rotateZ(0deg);
            }
        }
    }

    &-open {
        .select-box-items {
            animation: fadeIn .1s;
        }

        .nation-selection {
            filter: blur(0px);
            min-width: 100% !important;


            ul {
                max-height: 20rem !important;
                overflow: auto;
-webkit-overflow-scrolling: touch;

                li {
                    position: relative;
                }
            }
        }

        .frame-qty-select-item {
            max-height: 6rem !important;
            overflow: auto;
-webkit-overflow-scrolling: touch;
            filter: unset;
        }

        .top {
            top: unset;
            bottom: 100%;
        }
        .topleft {

            left: -1rem !important;
            top: unset;
            bottom: 100%;

        }

        .selectbox-label {
            &::after {
                transform: rotateZ(-180deg);
            }
        }
    }

}

@media screen and (max-width: 550px) {
    .select-box-sm {
        width: 100%;
        font-size: 0.875rem;
        line-height: 1rem;

        .rot {
            transform: rotate(270deg);
        }

        .select-box-items {
            li {
                display: flex;
                align-items: center;
                padding: 0.5rem;
                margin: 0.4rem 0rem;
                padding-right: 1rem;
                //min-width: 12.5rem;
                font-size: 0.7rem;
            }


        }

        .select-box-items li.selected::after {
            position: unset !important;
        }

    }

    .search-sm-gallery {
        flex-direction: column;
        width: 100%;
        padding: 0rem 0.8rem 0.4rem 0.8rem;

        .sort-txt {
            display: none !important;
        }

        .search {
            width: 100%;
            padding-top: 0.6rem;

            input {
                padding: 0.2rem 0.6rem;
            }

            .search-icon {
                top: 16px;
            }
        }

        .sort-txt-sm {
            display: flex;
        }
    }

}

.sort-txt-sm {
    display: none;
}

.sort-txt {
    display: none !important;
}

.select-boxs {
    width: 100%;
    position: relative;
    cursor: pointer;

    &-items {
        li {
            align-items: center;
            display: flex;
            padding: 0.4rem 0.8rem 0.4rem 0.8rem;
            margin: 0.2rem 0;

            &:hover,
            &.selected {
                background-color: var(--cu-black-500);
            }

            &.selected {


                &::after {
                    background-image: url(~/src/Assets/Icons/tick-icon.png);
                    background-size: contain;
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    background-repeat: no-repeat;
                    content: "";
                    position: absolute;
                    right: 0.5rem;
                }
            }
        }
    }

    &-selected-item {
        display: inline-block;
        height: 100%;
        width: 100%;
        vertical-align: middle;


    }

    &-closed {


        .select-box-items {
            border: 0;
            animation: fadeOut .1s;
        }

        .selectbox-label {
            &::after {
                transform: rotateZ(0deg);
            }
        }
    }

    &-open {
        .select-box-items {
            animation: fadeIn .1s;
        }

        .nation-selection {
            max-height: 20rem !important;
            overflow-y: scroll;
        }

        .selectbox-label {
            &::after {
                transform: rotateZ(-180deg);
            }
        }
    }

}

@media screen and (max-width: 550px) {
    .col-select {
        width: 100%;
    }
}


.col-gal-select {
    .select-box-items {
        margin-top: 0.5rem;
    }
}

.gallery-page,
.my-colection {
    .select-box-items {
        @media screen and (max-width: 550px) {
            left: unset;

            li {
                min-width: max-content;
            }
        }
    }
}