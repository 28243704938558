.customizedTable {
    width: 100% !important;
    overflow: scroll;
    th {
        position: sticky;
        top: 0px;
        padding: 1.25rem 0.938rem;
        text-align: center;
        z-index: 3;
        
        &:first-child {
            position: sticky;
            left: 0rem;
            z-index: 6 !important;
            background: var(--neutral-900);
            border-bottom: 0.5px solid var(--cu-black-trans-250);
        }
        &:last-child {
            width: 100%;
        }
        img {
            min-width: fit-content;
        }
    }

    td {
        padding: 0.5rem 0.938rem;
        text-align: left;
        text-wrap: nowrap;
        // vertical-align: middle;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
        z-index: 1 !important;
        &:first-child {
            position: sticky;
            left: 0rem;
            z-index: 2;
            background: var(--neutral-900);
            border-bottom: 0.5px solid var(--cu-black-trans-250);
        }
        &:last-child {
            width: 100%;
        }
    }

    // .tbl-content {
    //     // height:calc(100vh - 15rem);
    //     margin-top: 0px;
    // }

    // .tbl-header {
    //     border-bottom: 0.5px solid var(--cu-black-trans-250);
    //     position: sticky;
    //     top: 0rem;
    //     background: var(--neutral-900);
    //     z-index: 2;
    //     min-height: 50px;
    // }

    // .tbl-header-asset {
    //     border-bottom: 0.5px solid var(--cu-black-trans-250);
    //     position: sticky;
    //     top: 0rem;
    //     background: var(--neutral-900);
    //     z-index: 2;
    //     min-height: 50px;
    // }

    .flip-icon {
        rotate: (180deg);
    }
}