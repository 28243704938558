.share-wrapper {
  :is(.copy-box,.react-share__ShareButton) {
    height: 2.5rem !important;
    width: 2.5rem !important;
    display: flex !important;
    align-items: center !important;
    border: 1px solid var(--cu-black-100) !important;
    border-radius: 50% !important;
  }
  .twitter-box{
    flex-direction: column;
  }
  p {
    color: var(--cu-white-200);
    font-size: 0.625rem;
    margin-top: 0.25rem;
  }
  .react-share__ShareButton {
    flex-direction: column;
    justify-content: center;
  }
}
