.infinite-scroll-component {
// &::-webkit-scrollbar {
//     width: 0 !important
//   }
overflow: auto;
  .skeltonMainClass{
    position: static;
    .body{
      grid-template-columns: repeat(var(--skelton-count), 1fr) !important;
      grid-template-rows: max-content;
      .react-loading-skeleton{
        aspect-ratio: 1;
      }
    }
  }
}