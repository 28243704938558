.backdrop {
    top: 0;
    left: 0;
    width: 100%;
    flex: 1;
    z-index: 10;
    background: url("~/src/Assets/Images/background_cur8.png");
    background-size: cover;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    height: 100%;
    .backTrans {
      background-color: rgb(14 14 14 / 50%);
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 2.5rem;
    }
    .pageLogo {
      width: 100%;
      object-fit: cover;
    }
    .ipad-baner {
      display: none;
      height: 100px;
      width: 100%;
      position: absolute;
      top: 0;
    }
  
    @media screen and (max-width: 1024px) {
      .ipad-baner {
        display: block;
      }
    }
  
    @media screen and (max-width: 550px) {
      background: var(--cu-black-700);
      min-height: calc(100vh - 5rem);
  
      .ipad-baner {
        display: none;
      }
    }
  }