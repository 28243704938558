.image-tab-item {
    iframe {
        pointer-events: none;
    }
    &.file-type-box{
        width: 100%;
        aspect-ratio: 1;
        position: relative;
        
            .data-type{
                opacity: 1;
                position: relative;
                // &[tooltip]:hover::after {
                //     content: attr(tooltip);
                //     position: fixed;
                //     padding: 0.3125rem 0.75rem;
                //     transform: translate(0%, -194%);
                //     font-size: 0.75rem;
                //     border-radius: 0.75rem;
                //     border:1px solid var(--dark-mode-300); ;
                //     background: var(--Neutral-900, #0D0F11);
                //     width: max-content;
                //     margin-bottom: 5px;
                //     display: inline-block;
                //   }
                .media-file-type{
                    scale: 1;
                    position: absolute;
                }
            }
            .tooltip-box {
                opacity: 0;
            }
            &:hover {
                .tooltip-box {
                    opacity: 1;
                    position: fixed;
                    padding: 0.3125rem 0.75rem;
                    transform: translate(-34%, -194%);
                    font-size: 0.75rem;
                    border-radius: 0.75rem;
                    border: 1px solid var(--dark-mode-300); ;
                    background: var(--Neutral-900, #0D0F11);
                    width: max-content;
                    margin-bottom: 5px;
                    display: inline-block;
                }
            }
        
    }
}
.pop-up-filetype-wrapper{
    max-width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
}
.data-type {
    opacity: 0;
    color: var(--cu-white-700);
    font-size: 0.875rem;
    position: absolute;
    transition: all 0.5s ease;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cu-black-410);
    z-index: 2;
    .media-file-type{
      scale: 0.7;
      transition: all 0.5s ease;
    }
  }

.images-tab-container {
    display: grid;
    grid-gap: 0.5rem;
    padding: 0.8rem 0;
    grid-template-columns: repeat(auto-fill,minmax(5rem, 5rem));
    grid-auto-flow: column;
    grid-auto-columns: minmax(5rem, 5rem);
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 4px;
    }
}