.cur8-page-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 101;
    iframe {
        aspect-ratio: 1/1;
        width: 35%;
        height: auto;
    }
    .curating-text {
        width: fit-content;
        font-weight: bold;
        clip-path: inset(0 10ch 0 0);
        animation: l4 1s steps(30) infinite;
    }
}