.wallet-select-pop {
    cursor: auto;
    &.wallet-loading {
        .popup-template { 
            left: 31%;
        }
    }
    .popup-template {
        top: 0;
        bottom: 0;
        left: 35%;     
        min-width: 45rem;   
        @media screen and (max-width: 550px) { 
            left: 1rem;
            min-width: unset;
            right: 1rem;
        }
        .popup-template-body {
            padding: 2.5rem;
            @media screen and (max-width: 550px) {  
                max-height: 100vh;
            }
            .head, .foot  {
                @media screen and (max-width: 550px) {  
                    font-size: 0.75rem;
                }
            }
            .body {
                @media screen and (max-width: 550px) {  
                    flex-wrap: wrap;
                    justify-content: center;
                }
            } 
        }
    }
    .popup-overlay {
        z-index: 11;
    }
    &.close-popup {
        display: none;
    }
    .error-msg {
        padding: 1rem;
        animation: fade 1s linear;
        max-width: 25rem;
        background: var(--cu-red-400);
        border: 1px solid var(--cu-red-550);
        border-radius: 0.625rem;
    }
    @keyframes fade {
        0% { opacity: 0 }
        100% { opacity: 1 }
      }
    .wallet-pop-container {
        max-width: 460px;
        width: 100%;
        .wallet_box {
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            align-items: center !important;
            gap: 0.5rem !important;
            padding: 10px 0;
            .wallet-icon {
                img {
                    margin: auto;
                    width: 62px;
                    height: 3.6rem;
                    // object-fit: contain;
                    @media screen and (max-width: 550px) {  
                        min-height: 25px;
                        width: 25px;
                    }
                }
            }
            .wallet-label {
                font-size: 0.75rem;
                font-weight: 600;
                @media screen and (max-width: 550px) {  
                    font-size: 0.625rem;
                }
            }
            .wallet-button {
                display: flex;
                height: 27px;
                justify-content: center;
                border-radius: 10px;
                border: 0.5px solid var(--dark-mode-700, #24272F);
                background: var(--neutral-900, #0D0F11);
                font-weight: 400;
                font-size: 0.75rem;
                padding: 0.2rem 0.625rem;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                cursor: pointer;
                @media screen and (max-width: 550px) {  
                    font-size: 0.625rem;
                    margin-top: 0rem;
                }
                transition: all 0.3s;
                color: var(--cu-white-900);
                border: none;
                box-shadow: 0 0 0 1px var(--cu-black-100);
                &:hover {
                  background: var(--dark-mode-300);
                }
            }
        }
        .slick-arrow {
            width: 23px;
            height: 23px;
            cursor: pointer;
            &.slick-next,
            &.slick-prev {
                background-size: contain;
                border: 1px solid var(--cu-black-10);
                border-radius: 50%;
                opacity: 0.8;
                z-index: 3;
                &::before {
                    content: '';
                }
            }
            &.slick-disabled {
                cursor: not-allowed;
            }
            &.slick-next {
                background-image: url('/Assets/Icons/arrow-gray.svg');
                transform: rotate(-90deg);
            }

            &.slick-prev {
                background-image: url('/Assets/Icons/arrow-grey.svg');
                transform: rotate(90deg);
            }
        }
    }
}
