.grid-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(18.8rem, 18.8rem));
  gap: 4rem;
  justify-content: center;
  height: 100%;
  align-content: center;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 0 .75rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0 .75rem;
  }
}

.partner-card {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--dark-mode-300);

  .partner-name {
    color: var(--cu-white-200);
    font-family: ZenKaku;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1rem;
  }

  .image-container {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
  }

  .description-container {
    display: flex;
    text-align: left;
  }
}

.parent-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;

  @media screen and (max-width: 550px) {
    min-height: calc(100vh - 5rem);
  }
}