.overflow-auto {
    overflow: auto;
-webkit-overflow-scrolling: touch;
}

.info-cat-wrp {
    border-radius: 0.4rem;
    // background-color: var(--cu-black-500);
    // border: 0.0625rem solid var(--cu-black-100);

    .info {

        .image-container:before {
            -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
            background: transparent;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            z-index: 2;
        }

        .image-container {
            overflow: hidden;

            .image-cls {
                // height: 11.5rem !important;
                background-size: cover;
                width: 100%;
                aspect-ratio: 1.5;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 0.25rem;
            }
        }

        .info-title {
            font-size: 0.875rem;
            line-height: 0.75rem;
        }
    }

    .card-text {
        padding: 0.625rem;
    }
}