/* Popup style */
.popup-box {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: grid;
    z-index: 12;

    .overlay {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 12;
        position: fixed;
        background: rgb(0 0 0 / 56%);
        backdrop-filter: blur(1px);
    }

    .box {
        position: relative;
        width: auto;
        margin: auto;
        height: auto;
        background: var(--cu-black-500);
        max-height: 90vh;
        display: flex;
        flex-direction: column;
        z-index: 12;
        border-radius: 0.25rem;
        border: 1px solid var(--Dark-Mode-300, #30333A);
        .pop-content {
            padding: 3rem;
            overflow-y: auto;
            scroll-behavior: smooth;

            &.zero-padding {
                padding: 0;
            }
            &.warning-padding{
                padding: 1rem;
                img{
                    height: 1.25rem;
                    width: 1.25rem;
                }
            }
        }

        .close-icon {
            cursor: pointer;
            background: var(--cu-grey-100);
            ;
            width: 1.2rem;
            height: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 0.0625rem solid var(--cu-grey-500);
            font-size: 1.25rem;
            margin: 1rem;
        }
    }

    @media screen and (max-width: 550px) {
        .box {
            width: 95vw;

            .pop-content {
                padding: 1rem 0;
            }
        }
    }

}