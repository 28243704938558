:root {
    --cu-black-900: #000000;
    --cu-black-800: #1A1D27;
    --cu-black-700-90: hsla(210, 13%, 6%, 0.900);
    --cu-black-700-80: hsla(210, 13%, 6%, 0.800);
    --cu-black-700: #0D0F11;
    --cu-black-650: #24272F;
    --cu-black-500: #14171F;
    --cu-black-550:#0F121A;
    --cu-black-300: #0B0D0E;
    --cu-black-400: #191D23;
    --cu-black-200: #202328;
    --cu-black-100: #30333A;
    --cu-black-90: #313339;
    --cu-black-70: #20232A;
    --cu-black-80: #2e343e;
    --cu-black-50: #323A46;
    --cu-black-40: #30343a;
    --cu-black-30: #4B5768;
    --cu-black-20: #41444B;
    --cu-black-10: #64748B;
    --cu-black-5: #A0ABBB;
    --cu-black-120: #292D3A;
    --cu-black-110: #41444B;
    --cu-black-410:#0d0f11f2;
    --cu-black-hover:#0d0f11b8;
    --cu-black-trans-700: rgba(0, 0, 0, .9);
    --cu-black-trans-650: rgba(0, 0, 0, 0.5);
    --cu-black-trans-600: rgba(0, 0, 0, .7);
    --cu-black-trans-500: rgba(0, 0, 0, .5);
    --cu-black-trans-250: rgba(131, 133, 140, 0.4);
    --cu-black-trans-200: rgb(0 0 0 / 40%);
    --cu-black-trans-hover: rgba(255, 255, 255, 0.05);

    --cu-gray-960: #8B8B8B;
    --cu-gray-930: #2d2f38;
    --cu-grey-750: #373A41;
    --cu-grey-700: #3A3C43;
    --cu-grey-650: #64748B;
    --cu-grey-600: #828282;
    --cu-grey-500: #999;
    --cu-grey-700: #3A3C43;
    --cu-grey-600: #373A41;
    --cu-grey-100: #ededed;
    --cu-grey-800: #223345;
    --cu-grey-860: #35373E;
    --cu-grey-shade: #384867;
    --cu-grey-200: #282D3A;
    --cu-grey-950: #202431;
    --cu-grey-300: #30333a30;
    --cu-grey-350: #606573;
    --cu-grey-dim:#000000ba;
    --cu-grey-dim-200:#000000d6;
    --cu-grey-light:#b9b9b9;
    --cu-grey-280:#272A31;
    --cu-grey-disabled:#75757585;
    
    --cu-red-800: #991B1B;
    --cu-red-550: #EB5757;
    --cu-red-400: #B75050;
    --cu-red-550: #EF4444;
    --cu-red-500: #eb5a5a;
    --cu-red-600: #DE3D3C;

    --cu-blue: #4C66F0;
    --cu-blue-lite: #99B7FF;
    --cu-blue-150: #185BC3;
    --cu-blue-700: #4745ED;
    --cu-blue-400: #2525e1;
    --cu-blue-300: #00C5FF;
    --cu-blue-100: #4155bf;
    --cu-blue-70: #14A0FA;
    --cu-blue-60: #326BF2;
    --cu-blue-link:#0000EE;
    --cu-green-800: #047857;
    --cu-green-700: #059669;
    --cu-green-500: #27AE60;

    --cu-white-100: #B8C0CC;
    --cu-white-200: #F7F8F9;
    --cu-white-700: #D0D5DD;
    --cu-white-900: #FFFFFF;
    --cu-white-500: #CCDBFF;
    --cu-white-550: #E7EAEE;
    --cu-white-450: #64748B;
    --cu-white: #fff;


    --neutral-100: #E7EAEE;
    --neutral-500: #64748B;
    --neutral-900: #0D0F11;

    --dark-mode-300: #30333A;
    --dark-mode-900: #14171F;

    --cu-white-trans-600: rgba(255, 255, 255, .6);
    --cu-white-trans-800: rgba(255, 255, 255, .8);
}