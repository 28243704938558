.transparent-popup {
  position: absolute;
  inset: 0;
  display: flex;
  z-index: 5;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(0.6px);
  -webkit-backdrop-filter: blur(0.6px);
  .warning-wrap {
    padding-right: 2.75rem;
  }
}
