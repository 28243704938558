.logOut-warn {
    padding: 3.8125rem 2.5625rem;

    .logOut-txt {
        font-size: 1rem;
        margin-bottom: 1.25rem;
        padding: 1.125rem;
    }

    .button-box {
        display: flex;
        justify-content: center;
        gap: .625rem;

        button {
            width: 8.375rem;
            padding: 6px;
            background: var(--cu-black-400);

            border-radius: 10px;

            /* Success/Green 700 */
            &.success {
                border: 0.0625rem solid var(--dark-mode-300);       
                &:hover {
                    background: var(--dark-mode-300);
                    border: 1px solid var(--dark-mode-300);
                }
            }

            &.error {
                border: 1px solid var(--Dark-Mode-300, #30333A);
                background: var(--cu-black-700);
                &:hover {
                    background: var(--dark-mode-300);
                    border: 1px solid var(--dark-mode-300);
                }
            }

        }
    }

    @media screen and (max-width: 550px) {
        padding: 2.40625rem .625rem;
    }
}