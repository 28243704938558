.global_search-container {
    position: relative;
    transition: all 0.3s;

    // width: 32.85rem;
    .global_search-box-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid var(--cu-black-40);
        border-radius: 8px;
        transition: all 0.3s;
        height: 100%;

        &:hover {
            // border-color: var(--cu-black-30);
            .global-search-image{
                svg {
                    path {
                      stroke: var(--cu-white-900);
                    }
                  }
            }
        }

        // &.active {
        //     border-color: var(--cu-black-30);
        // }

        .global_search-icon-button {
            width: 2.65rem;
            height: 2.65rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .global_search-icon {
                width: 1.5rem;
                height: 1.5rem;
                margin-left: 0.25rem;
            }
        }

        .global_search-input-field {
            height: 2.5rem;
            background-color: unset;
            padding: 0 0.6rem;
            font-size: 0.875rem;

            &::placeholder {
                font-size: 0.875rem;
            }
        }
    }

    .global_search-result-wrapper {
        position: absolute;
        width: 100%;
        top: 100%;
        right: 71%;
        z-index: 999;

        .global_search-results {
            width: 171%;
            height: 100%;
            margin: 0.4rem 0 0 0;
            border: 1px solid var(--cu-black-40);
            border-radius: 8px;
            background-color: var(--cu-black-500);
            min-height: 8rem;

            .tab-container-details {
                padding: 1rem;

                .tab-data {
                    display: flex;
                    gap: 2rem;
                    border-bottom: solid 1px var(--cu-black-5);
                    ;
                    box-sizing: border-box;
                    cursor: pointer;
                    color: var(--cu-black-10);
                    font-size: .875rem;
                    line-height: 1.21429rem;

                    .tab {
                        font-size: .875rem;
                        line-height: 1.21429rem;
                        padding-bottom: .625rem;
                        box-sizing: border-box;
                        position: relative;

                        &.not-active {
                            &::after {
                                background: none repeat scroll 0 0 transparent;
                                bottom: 0;
                                content: "";
                                display: block;
                                height: 1px;
                                left: 50%;
                                position: absolute;
                                background: var(--cu-white-200);
                                transition: width 0.3s ease 0s, left 0.3s ease 0s;
                                width: 0;
                            }

                            &:hover {
                                color: var(--cu-white-200);

                                &::after {
                                    width: 100%;
                                    left: 0;
                                }
                            }
                        }

                        &.active {
                            color: var(--cu-white-200);
                            border-bottom: solid 2px var(--cu-white-200);

                        }
                    }

                }
            }
        }
    }
}