.reusable_dropdown-container {
    position: relative;
    display: inline-block;
    .reusable_dropdown-button {
        padding: 0.5rem 1rem;
    }
    .hide_dropdown {
        display: none;
    }
    .reusable_dropdown-list-box {
        position: absolute;
        display: inline-block;
        // top: 2.5rem;
        // left: 0;
        right: 0;
        z-index: 9;
        background: var(--dark-mode-900);
        border-radius: .25rem;
        border: 1px solid var(--cu-black-100);
        text-align: left;
        z-index: 100;
        color: var(--cu-white-200);
        white-space: nowrap;
        min-width: -moz-fit-content;
        min-width: -webkit-fill-available;
        min-width: fit-content;
        width: 10rem;
        overflow-y: scroll;
        height: auto;
        max-height: 30rem;
        &::-webkit-scrollbar {
            display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
          }
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        .reusable_dropdown-ul-box {
            li {
                padding: 0.6rem 1rem;
                line-height: 1rem;
                color: var(--cu-white-100);
                font-size: 0.85rem;
                cursor: pointer;
                transition: all 0.3s;
                &:hover,
                &.selected {
                    color: var(--cu-white-400);
                }
    
                &.selected {
                    color: var(--cu-white-200);
                }
            }
        }
    }
    .plus_button-style {
        transition: all 0.3s;
        opacity: 0.8;
        &:hover {
            opacity: 1;
        }
    }
}
