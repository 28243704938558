.icon-wrapper {
  &.toggle{
    circle{
      &:hover{
        fill:var(--cu-black-10);
      }
    }
  }
  &.fill-color {
    &.activeIcon {
      svg {
        path {
          fill: var(--cu-white-200);
        }
      }
    }
    &:hover{
        svg {
            path {
              fill: var(--cu-white-200);
            }
          }  
    }
  }
  &.stroke-color {
    &.activeIcon {
      svg {
        path {
          stroke: var(--cu-white-200);
        }
      }
    }
    &:hover{
        svg {
            path {
                stroke: var(--cu-white-200);
            }
          }  
    }
  }
  &.rect-stroke-color {
    &.activeIcon {
      svg {
        rect {
          stroke: var(--cu-white-200);
        }
      }
    }
    &:hover{
        svg {
            rect {
              stroke: var(--cu-white-200);
            }
          }  
    }
  }
  &.display-lists{
    &:hover{
      svg {
          rect {
            fill: var(--cu-black-5);
          }
        }  
  }
    &.active{
      svg {
        rect {
          fill:var(--cu-black-5);
          stroke: var(--cu-white-200);
        }
      }  
    }
  }
  &.disabled{
    cursor: no-drop;
  }
}
