.accord-card {
    padding: 0.28571rem 0.57143rem;
    border-radius: 0.1875rem;
    border: 1px solid var(--cu-black-400);
    @media screen and (max-width: 550px) {
        background-color: transparent;
      }
    &.sm-accord-card, &.lg-accord-card  {        
        .accord-top {
            color: var(--cu-black-5);
            font-size: 0.625rem;
            line-height: 1.5rem;
            letter-spacing: 0.0125rem;
        }
        .accord-caption {
            font-size: 1rem;
            line-height: 1.71429rem;
            letter-spacing: 0.02rem;
            color: var(--cu-whit);
            font-weight: 600;
        }
        .accord-community-name{
            font-size: 0.875rem;
            font-weight: 700;
            color: var(--cu-white-200);
            @media screen and (max-width: 550px) {
                font-size: 0.625rem;
                font-weight: 500;
              }
        }
        .accord-body {
            color: var(--cu-black-5);
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.0625rem;
            .text-sm{
                @media screen and (max-width: 550px) {
                    font-size: 0.625rem;
                    align-items: start;
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
            }
            img {
                width: 1.875rem;
                height: 1.875rem;
                @media screen and (max-width: 550px) {
                    width: 1rem;
                    height: 1rem;
                  }
                &.active {
                    transform: rotate(180deg)
                }
            }
        }
        .accord-desciption {
            color: var(--cu-black-5);
            font-size: 0.72rem;
            line-height: 1.35714rem;
            span{
                font-size: 0.75rem;
                color: var(--cu-white-550);
                text-align: justify;
                word-break: break-word;
                white-space: pre-line;
                overflow-wrap: break-word;
                -ms-word-break: break-word;
                -ms-hyphens: auto;
                -moz-hyphens: auto;
                -webkit-hyphens: auto;
                hyphens: auto;
                @media screen and (max-width: 550px){
                    font-size: 0.675rem;
                }
            }
            .details-text{
                color:var(--cu-black-5);
                font-size: 0.625rem;
                font-weight: 500;
            }
            .banner-img{
                height: 100%;
                max-height: 10rem;
                width: 100%;
                img{
                    max-height: 10rem;
                    width: 100%;
                    height: 100%; 
                }
            }
        }
    }
}