.lg-card {
    grid-template-columns:repeat(4, 1fr);
}
.no-gap{
    gap: 0rem !important;
}
.sm-card {
    grid-template-columns: repeat(5, 1fr);
    @media screen and (max-width: 550px) {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr)) !important;
    }
}
        .refresh-img{
            width: 0.9375rem;
            height: 0.8125rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
.nft-collections {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    &.with-tabs {
        padding-bottom: 4.5rem;
    }
    .overlay {
        display: none;
    }

    .page-sm-tabs {
        display: none;

        .return-btn {
            color: #FFF;
            font-size: .75rem;
            line-height: 1.0625rem;
            border-radius: 0.6875rem;
            padding: .0625rem;
            background: linear-gradient(180deg, #00C5FF 0%, #4745ED 100%);
            width: 100%;

            button {
                border-radius: inherit;
                border: transparent;
                background: var(--cu-black-500);
                padding: .3125rem .625rem;
                width: 100%;
            }

        }
    }
    .my-collections {
        background: var(--neutral-900);
        display: flex;
        gap: 1.25rem;
        height: 100%;
        overflow: auto;
        position: relative;
        &.xlg {
            height: 100%;
        }
        @media screen and (max-width: 550px) {
            overscroll-behavior: contain;
        }
     
        .nft-display-panel {
            height: 100%;
            // overflow-y: auto;
            scroll-behavior: smooth;
            flex: 1;
            .panel-area {
                row-gap: 1rem;
                padding: 1rem;
            }
            @media screen and (max-width: 550px) {
                padding-bottom: 4rem;
                // padding-top: 0.6rem;
            }
        }

        .nft-display {
            background: var(--cu-black-700);
            /* Dark Mode / 300 */
            display: flex;
            opacity: 1;
            // &:hover{
            //      opacity: 0.5;
            // }
            flex-direction: column;
            gap: 0.465rem;
            width: 100%;
            .nft-img {
                border: 1px solid #20232A;
                &.list-nft-img {
                    // height: var(--collection-card-height);
                    aspect-ratio: 1/1;
                }
                &.enlarge-nft-img {
                    height: var(--collection-enlarge-card-height);
                    padding: 0.2rem;
                    border: unset;
                }
            }
            .collection-image {
                height: 100%;
            }
            &.selected:not(.selected-sec-lvl) {
                .nft-img {
                    // border-image: linear-gradient(#00C5FF, #4745ED) 20;
                    // border-width: 1px
                    //border:1px solid var(--cu-blue-70);
                    //position: relative;
                    border: unset;
                    padding: 0.2rem;
                    div:first-child {
                        border: 1px solid var(--cu-blue-70);
                    }                    
                }
                
            }
            &.selected-sec-lvl {
                .nft-img { 
                    border: unset;
                    padding: 0.2rem;
                    position: relative;
                    border: 1px solid var(--cu-blue-70);
                    // &::before {
                    //     content: "";
                    //     position: absolute;
                    //     inset: 0;
                    //     border-radius: 0.25rem;
                    //     padding: 1px;
                    //     background: linear-gradient(45deg, #00C5FF, #4745ED);
                    //     -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                    //     -webkit-mask-composite: xor;
                    //     mask-composite: exclude; 
                    //     z-index: 9;
                    //     top: 1px;
                    //     @media screen and (max-width: 550px) { 
                    //         padding: 1.5px;
                    //     }
                    // }
                }
            }
            .tool-box {
                min-height: 1.875rem;
                display: flex;
                align-items: center;
                padding-left: .5rem;

                img {
                    width: 1rem;
                    cursor: pointer;
                }
            }
            .nft-name,
            .nft-price,
            .nft-type {
                font-size: .625rem;
                text-align: left;
                min-height: 0.9375rem;

            }

            .nft-name-box {
                &.bg-blue {
                    background: var(--cu-black-700);
                }
            }

            .nft-name {
                font-size: 1rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .nft-img {
                width: 100%;
                border-radius: 0.25rem;
                cursor: pointer;
                min-height: 5rem;   
                overflow: hidden;             
                &:hover {
                    position: relative;   
                    .hover-overlay {
                        background: rgb(15 15 15 / 50%);
                        border-radius: 0.25rem;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 12;
                        overflow: hidden;
                    }
                }
            }

            .selected-skelton-card-lg {
                // min-height: 17.19rem;
                width: 100%;
                display: flex !important;

                @media screen and (max-width: 550px) {
                    min-height: 10.5rem;
                }
            }

            .selected-skelton-card-sm {
                width: 100%;
                display: flex !important;
            }

            &.nft-display-lg {

                .nft-img {
                    width: 100%;
                }
            }
            .slected-h-auto {
                height: auto;
            }
        }

        .nft-details {
            margin-left: auto;
            /* Dark Mode / 300 */
            border-radius: 4px;
            border: 1px solid var(--cu-black-400);
            .nft-panel {
                width: 32rem;
                padding-bottom: 1rem;
                background: var(--cu-black-500);
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
                overflow: auto;
-webkit-overflow-scrolling: touch;
                scroll-behavior: smooth;
                z-index: 9;
                &:hover {
                    .tool-bar {
                        display: flex;
                    }
                }

                @media screen and (max-width: 550px) {
                    overscroll-behavior: none;
                    touch-action: none;
                    -webkit-overflow-scrolling: none;
                    overflow: hidden;
                }

                &.zoom-panel {
                    width: 100%;
                    position: fixed;
                    z-index: 12;
                    right: 0;
                    left: 0;
                    top: 0rem;
                    bottom: 1rem;
                    width: 100%;
                    background-color: var(--cu-black-900);
                    padding: 0;
                    @media screen and (max-width: 550px) {
                        overscroll-behavior: none;
                        touch-action: none;
                        -webkit-overflow-scrolling: none;
                        overflow: hidden;
                    }
                }

                &::-webkit-scrollbar {
                    display: none;
                }

                -ms-overflow-style: none;
                /* IE and Edge */
                scrollbar-width: none;
                /* Firefox */

                .detail-wrapper{
                    padding: 0rem 1rem 0rem 1rem;
                }
                .tool-bar {
                    display: none;
                    align-items: center;
                    gap: 0.625rem;
                    margin-top: 0.4375rem;
                    margin-left: auto;
                    position: sticky;
                    z-index: 2;

                    @media screen and (max-width: 550px) {
                        right: 1rem;
                    }

                    .close,
                    .zoom {
                        cursor: pointer;
                        width: 1.75rem;
                        height: 1.75rem;
                    }
                }

                .nft-img {
                    line-height: 0;
                    border-radius: 0.25rem 0.25rem 0rem 0rem;
                    background: var(--cu-black-700);
                    width: 14.3rem;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    overflow: hidden;
                    padding: 1.25rem;
                    background-color: white;                    
                }

                .details {
                    width: 100%;
                    flex: 1;
                    overflow: hidden;
                    max-height: calc(100% - 14rem);
                    padding: 0rem 1rem 0rem 1rem;
                    .details-box {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 1rem;
                        font-size: .75rem;
                        color: var(--cu-white-200);
                        height: 100%;

                        .details-sm {
                            font-size: .5rem;

                            .details-title-sm {
                                color: var(--cu-black-5);
                            }
                        }



                        .details-description {
                            color: var(--cu-white-200);
                            width: 24.813rem;
                            text-align: left;
                            word-break: break-all;
                        }
                    }
                }

            }

            &.zoom-nft {
                width: calc(100% - 2.5rem);
                right: 1.8rem;
                max-width: 95vw;
                overflow-y: scroll;
                position: absolute;
                top: 0.9rem;
                left: 0.9rem;

                .nft-img {
                    display: none;
                }

                .nft-zoom-img {
                    line-height: 0;
                    display: flex;
                    flex: 1;
                    align-items: center;
                    width: 100%;
                    height:80vh;
                    padding: 5%;
                    // &.frame-image-container {
                    //     zoom: 1.7;
                    // }
                    .selected-zoom-frame-container {
                        img {
                            height: 100vh;
                        }
                    }
                }

                .details {
                    display: none;
                }
            }
        }

        .nft-display-table {
            font-size: .75rem;
            font-weight: 600;

            .arrow-flipped {
                transform: scaleY(-1);
                -moz-transform: scaleY(-1);
                -webkit-transform: scaleY(-1);
                -ms-transform: scaleY(-1);
            }

            tbody {
                tr {
                    .nft-frame-viewer {
                        height: 3rem;
                        width: 3rem;
                        margin: auto;
                    }

                    img {
                        height: 2rem;
                        width: 2rem;
                        cursor: pointer;
                    }

                    th {
                        margin: 0rem 0.3rem;
                    }

                    border-bottom: 0.5px solid var(--cu-black-trans-250);

                    @media screen and (max-width: 550px) {
                        border-bottom: 0px;
                    }
                }
            }

        }
    }

    .my-colection-list {
        padding: 0rem !important;
        overflow: unset;
    }

    @media screen and (max-width: 767px) {

        .my-collections {
            .nft-details {
                position: fixed;
                height: calc(100% - 1rem);
                right: 0;
                top: 1rem;
                z-index: 12;

                .nft-panel {
                    width: 100%;

                }
            }
        }
    }


    @media screen and (max-width: 602px) {
        .my-collections {
            .nft-details {
                .nft-panel {
                    width: 100%;
                }
            }
        }
    }

    @media screen and (max-width: 550px) {
        .my-collections {
            height: 100%;
            padding: 1rem;
            justify-content: center;

            .panel-area {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
                margin: auto;
                place-content: center;

                &.nft-display-lg {
                    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
                }

                &.nft-display-list {
                    display: block;
                }
            }

            .nft-details {
                right: auto;
                top: 0;
                height: -webkit-fill-available;
                height: 100vh;


                &.zoom-nft {
                    width: auto;
                    right: .8rem;
                    top: 2.7rem;
                }

                .nft-panel {
                    width: 100vw;
                    .detail-wrapper{
                        padding: 0rem .625rem 0.625rem;
                    }
                    .details {
                        .details-box {
                            gap: .625rem;

                            .tab-box {
                                flex-wrap: wrap;
                                margin-bottom: 0;
                                width: 100%;

                                .tab-sm {
                                    flex: 1 1 45%;

                                    .tab-btn-sm {
                                        width: max-content;
                                    }
                                }
                            }
                        }
                    }

                    .nft-img {
                        width: 100%;
                    }

                }
            }

            .nft-display-table {
                thead {
                    th {
                        .functional {
                            width: max-content;

                        }
                    }
                }

                tbody {
                    td {
                        img {
                            width: 5.58312rem;
                        }

                    }

                }
            }
        }

        .page-sm-tabs {
            position: absolute;
            top: -.05rem;
            width: 100%;
            display: flex;
            padding: 0.3rem 1rem;
            align-items: center;
            background: var(--cu-black-500);
            z-index: 1;
        }

    }
}

@media screen and (max-width: 550px) {


    .baner-lg-open {
        display: block;
        position: fixed;
        top: 5.1875rem;
        z-index: 10;
        width: 100%;
        background-color: var(--cu-black-90) !important;
        right: 1.125rem;
        width: 12rem;
        padding: 0.3rem 0rem;

        .overlay {
            display: block;
            position: absolute;
            width: 100%;
            z-index: -1;
        }
    }

    .tabs {
        display: flex;
        align-items: center;
        gap: .625rem;
        flex: 1;
        align-items: center;
        justify-content: center;
    }
}

.zoom-close {
    position: absolute !important;
    right: 2rem;
}

.round-btn {

    background: var(--cu-black-700) !important;

}

.btn-mob {
    display: none;

    @media screen and (max-width: 550px) {
        display: unset;
        position: fixed;
        z-index: 3;
        left: 0;
        right: 0;
        bottom: 0rem;
        margin-bottom: 0rem;
        background: var(--cu-black-700);
        padding: 0.6rem;
    }

}

.selected-det {
    height: 34vh;
    width: 100%;
}
.collectionTable {
    th{
        color: var(--cu-blue-70);
    }
}

@keyframes comeToDisplay {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    90% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  .visibleSlowly {
    animation: comeToDisplay 0.6s ease-out;
  }