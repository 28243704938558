.form {
    &-label {
        font-weight: 500;
        font-size: 1.14rem;
        color: var(--cu-gray-100);
    }

    &-box {
        background: var(--cu-gray-850);
        height: 3.28rem;
        border-radius: 0.35rem;
        font-weight: 400;
        font-size: 1.28rem;
        color: var(--cu-white);
        width: 100%;
        outline: none;
    }

}

.form-box:focus-within {
    border: 1px solid var(--cu-white);
}