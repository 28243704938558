.warning-wrap{
    padding: 1rem;
    background: var(--cu-black-500);
    border: 1px solid var(--cu-black-100);
    border-radius: 0.625rem;
    gap: 3rem;
    p{
   color: var(--cu-white-900);
   font-size: 0.875rem;
   text-align: center;
    }
.upgrade-link{
   text-decoration: underline;
   color:var(--cu-blue-150);
   cursor: pointer;
}
}