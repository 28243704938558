:root {
    --collection-card-height: 170px;
    --collection-enlarge-card-height: 170px;
    --collection-enlarge-layout-height: 170px;
    --collection-added-card-height: 170px;
    --collection-enlarge-layout-width: 11rem;
    --collection-gallery-previewcard-height: 170px;
    --frame-card-height: auto;
    --collection-gallery-listing-height: auto;
    --gallery-min-height: auto;
    --dicover-collection-ht: auto;
    --discover-collection-card-height: 15.25rem;
    --right-menu-width: 0px;
    --top-menu-height: 0px;
    --home-fcollection-height: 0px;
    --main-page-height: 100vh;
    --selected-gallery-height:auto;
    --skelton-count:5;
    --collection-sm-card-height:50px;
    --collection-card-lg-sm-height: 170px;
}