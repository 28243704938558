.gallery {
    width: 3.78rem;
    height: 3.78rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.selected-new.active {

    .gallery-images,
    .nftListing-image {
        border: 1px solid var(--cu-white-900);
    }
}