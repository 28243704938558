.list-right-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.42857rem;
    width: 100%;
    background-color: var(--neutral-900);
    @media screen and (width:100%){
        background-color: var(--cu-black-700);
        border: none;
        width: 100%;
    }
    .right-panel-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.42857rem;
        width: 100%;
        height: 100%;
        @media screen and (max-width:550px){
            padding:0rem;
        }
    }
    .right-panel-b-border {
        border-bottom-width: 1px;
        border-color: var(--cu-black-100);
        width: 100%;
    }
    .right-panel-v-border {
        border-right-width: 1px;
        border-color: var(--cu-black-100);
    }
}