.dropdown-select-box--container {
    background: var(--cu-black-700);
    border: 0.0625rem solid var(--cu-black-100);
    border-radius: .25rem;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 2rem;
}

* {
    box-sizing: border-box;
}

.dropdown-select-box--selected-item {
    display: inline-block;
    height: 100%;
    width: 100%;
    vertical-align: middle;
}

.dropdown-select-box--arrow {
    min-width: 1.5rem;
    padding-right: .625rem;
    margin-left: .125rem;
}

.dropdown-select-box {
    width: 100%;
    position: relative;
    cursor: pointer;
    

    &-items {
        position: absolute;
        top: 1.8rem;
        left: 0rem;
        right: 0.9rem;
        z-index: 9;
        overflow: hidden;
        max-height: 0;
        background: var(--dark-mode-900);
        border-radius: .25rem;
        border: 1px solid var(--cu-black-100);
        text-align: left;
        z-index: 100;
        color: var(--cu-white-900);
        cursor: pointer;
        white-space: nowrap;
        min-width: -moz-fit-content;
        min-width: -webkit-fill-available;
        min-width: fit-content;
        font-size: 0.875rem;
        @media screen and (max-width: 550px) {
            left: -5.4rem;
        }
        ul {
            min-width: 13.75rem;
            padding: .25rem 1rem;
        }
        li {
            padding: .5rem;
            margin: 0.4rem 0rem;
            color: var(--cu-black-5);
          
            &:hover,
            &.selected {
              color: var(--cu-white-200);
          
              .count span {
                color: var(--cu-white-200);
              }
            }
          
            .count {
              border-radius: 0.25rem;
              padding: 0.125rem 0.5rem;
              span {
                font-weight: 500;
                background: var(--cu-black-10);
                background-clip: text;
                -webkit-background-clip: text;
          
                &:hover, &.selected {
                  color: var(--cu-white-200);
                }
              }
            }
          }
        .drop-wallet-list {
            color: var(--cu-white);
            font-size: 0.8125rem;
            line-height: 1.0625rem;
            
            .wallet-container{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                color: var(--cu-white-900);
                padding: 0.5rem;
                margin: 0.4rem 0rem;
                p {
                    font-size: 0.875rem;
                    color: var(--cu-white-900);
                }
                .wallet{
                    color: var(--cu-white-900);
                }
                .total{
                    display: flex;
                    width: 2.5rem;
                    justify-content: space-around; 
                    .down-arrow-img{
                        @media screen and (max-width: 550px) {
                            width: 0.5rem;
                            height: 1.15rem;
                        }
                    }
                }
                &.active {
                    img {
                        transform: rotate(180deg);
                    }
                }
            }
            .child-wallet {
                .wallet_wrap {
                    padding: 0.5rem;
                    padding-left: 1rem;
                    margin: 0.4rem 0rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    color: var(--cu-black-5);
                    @media screen and (max-width: 550px) {
                        font-size: 0.75rem;
                    }
                    &:hover,
                    &.selected {
                        color: var(--cu-white-200);
                    }
                    .count {
                        border-radius: 0.25rem;
                        padding: 0.125rem 0.5rem;
                        span{                    
                            font-weight: 500;
                            background: var(--cu-black-10);
                            background-clip: text;
                            -webkit-background-clip: text;
                            &:hover {
                                color: var(--cu-white-200);
                              }
                        
                              &.selected {
                                color: var(--cu-white-200);
                              }
                        }
                    }
                }
            }
            
        }
    }

    &-selected-item {
        display: inline-block;
        height: 100%;
        width: 100%;
        vertical-align: middle;
    }

    &-closed {
        .dropdown-select-box-items {
            border: 0;
            animation: fadeOut .1s;
        }

        .dropdown-selectbox-label {
            &::after {
                transform: rotateZ(0deg);
            }
        }
    }

    &-open {
        .dropdown-select-box-items {
            animation: fadeIn .1s;
        }

        .frame-qty-select-item {
            max-height: 6rem !important;
            overflow: auto;
-webkit-overflow-scrolling: touch;
            filter: unset;
        }

        .top {
            top: unset;
            bottom: 100%;
        }

        .selectbox-label {
            &::after {
                transform: rotateZ(-180deg);
            }
        }
    }

}
@media screen and (max-width: 550px) {
    .dropdown-select-box-sm {
        width: 100%;
        font-size: 0.9rem;

        .rot {
            transform: rotate(270deg);
        }

        .dropdown-select-box-items {
            li {
                display: flex;
                align-items: center;
                gap: 1rem;
                min-width: 12.5rem;
                font-size: 0.7rem;
            }
        }

        .dropdown-select-box-items li.selected::after {
            position: unset !important;
        }

    }
}

.sort-txt-sm {
    display: none;
}

@media screen and (max-width: 550px) {
    .col-select {
        width: 100%;
    }
}

.col-gal-select {
    .dropdown-select-box-items {
        margin-top: 0.6875rem;
    }
}