.register {
    .auth-round {
        input {
            border-radius: 0.9rem;
        }
    }
    .error-validation {
        display: none;
    }
    .all-errors {
        font-size: 0.725rem;
        color: var(--cu-red-550);
        line-height: 1rem;
    }
}