.time-selector {
    &-wrapper {
        position: relative;
        font-size: 0.625rem;
        min-width: 10rem;
    }

    &-value {
        padding: 0rem 0.7rem;
        display: flex;
        align-items: center;
        min-height: 2rem;
        min-width: fit-content;
        text-align: left;
        background: var(--cu-black-700);
        border: 0.0625rem solid var(--cu-black-100);
        border-radius: 0.25rem;
        cursor: pointer;
        @media screen and (max-width: 550px) { 
            min-width: 8rem;
        }
    }

    &-dropdown {
        padding: .25rem;
        position: absolute;
        left: 0;
        right: 0;
        top: 2rem;
        background-color: var(--cu-black-100);
        border-radius: 0.25rem;
        overflow: hidden;
        border: 0.0625rem solid var(--cu-white-100);
        z-index: 10;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s ease;
    }

    &-dropdown-open {
        .time-selector-dropdown {
            opacity: 1 !important;
            visibility: visible !important;
        }
    }

    &-menu {
        flex: 1;

        canvas {
            width: 100%;
            height: 7rem;
        }

        ul {
            max-height: 7rem;
            overflow: auto;
-webkit-overflow-scrolling: touch;
            scroll-behavior: smooth;
            -webkit-scroll-behavior: smooth;
            scroll-snap-type: y mandatory;

            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none;
            scrollbar-width: none;

            li {
                padding: .5rem;
                scroll-snap-align: start;
                cursor: pointer;
                transition: background .3s ease;

                &.active {
                    background-color: var(--cu-grey-650);
                }
            }
        }
    }

    &-label {
        padding-bottom: .5rem;
        font-size: 0.857rem;
    }

}