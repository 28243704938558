.user-group-container  {
    position: relative;
    transition: all 0.4s;
    &:hover {
        .user-group-dropdown {
            visibility: visible;
            // display: block;
        }
        .filter-icon-wrapper{
        .fill-color{
            svg{
                path{
                    fill:var(--cu-white-200);
                }
            }
        }
        }
    }
    .icon {
        opacity: 0.6;
    }
}
.user-group-dropdown {
    // display: none;
    visibility: hidden;
    position: absolute;
    z-index: 2;
    background-color: var(--dark-mode-900);
    border: 1px solid var(--cu-black-100);
    border-radius: 0.25rem;
    transition: all 0.4s;
    cursor: auto;
    ul {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        width: 10rem;
        padding: 1rem;
        li {
            opacity: 0.8;
            text-align: start;
            color: var(--cu-black-5);
            font-size: 0.8125rem;
            &:is(:hover,&.active) {
               color: var(--cu-white-200);
                cursor: pointer;
            }
        }
    }
}