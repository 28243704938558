.temporary-mobile-view {
 
    .content {
        padding: 1rem 1rem 2rem 1rem;
        background: var(--cu-black-700);
        margin: 0rem 1rem;
        border-radius: 0rem 0rem 1.25rem 1.25rem;
        h1 {
            color:var(--cu-white-900);
            font-size: 1rem;
            line-height: 1.875rem;
            font-weight: 500;
        }
    }
}