.gallery-images-position {
    position: relative;

}

.close {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 0.2rem;
    z-index: 1;
}

.frame-loading {
    min-height: 7rem;
    align-items: center;
    width: 100%;
    height: 100%;
}

.gallery-view {
    .frame-loading {
        .gallery-nft-frame-viewer {
            width: 18rem;
        }
    }
}

// .gallery-preview-popup {
//     height: 38vh;
// }

.g-column {
    .gallery-images-position {
        img {
            max-height: 6rem;
            align-items: center;
            display: flex;
            margin: auto;
        }
    }
}

.nft-selected-name {
    background: var(--cu-black-700);
    height: 1rem;
    display: flex;
    align-items: flex-end;
    font-size: 0.56rem;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    z-index: 8;
    @media screen and (max-width: 550px) {         
        z-index: 8;
    }
    &.hidden-mb{
        @media screen and (max-width: 550px) {         
            display: none;
        }
    }
}

.name {
    max-width: 8rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &-sm {
        max-width: 12rem;
    }


    &-lg {
        max-width: 19.5rem;
    }
}


.nft-selected-full {
    border: 1px solid var(--cu-black-100);
    background: transparent;
    border-radius: 0.25rem;
    overflow: hidden;
    opacity: 1;
    &.drag-start{    
        border: 0.0625rem dashed var(--cu-white-900) !important;
        .collection-card{
            opacity: 0.6;
        }
    }
    &.drag-n-drop{
        :where(.added-card,.close){
            cursor: move !important;
        }
        .gallery-close{
            height: 1.15rem;
            width: 1.15rem;
        }
        .close{
        img{
            &:hover{
                cursor: pointer;
            }
        }
        }
    }
    .collection-card {
        border: 0px !important;
        background: none;
        border-radius: 0px !important;
    }
}

.nft-selected-full.active {
    // border-image: linear-gradient(#00C5FF, #4745ED) 20 !important;
    // border-width: 1px !important;  
    //border:1px solid var(--cu-blue-70) !important;
    position: relative;
    border: unset;
    // &::before {
    //     content: "";
    //     position: absolute;
    //     inset: 0;
    //     border-radius: 0.25rem;
    //     padding: 1px;
    //     background: linear-gradient(45deg, #00C5FF, #4745ED);
    //     -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    //     -webkit-mask-composite: xor;
    //     mask-composite: exclude; 
    //     z-index: 9;
    //   }
}
.added-nfts {
    .nft-selected-full.active {
        border: 0.0625rem solid white;
    }
}



.loader-image {
    opacity: 0;
    min-height: 4rem;
}

.loaded-image {
    opacity: 1;
}

.close:hover {
    background-color: var(--cu-black-trans-650)
}

.hiddens {
    display: none !important;
}