.accordion-list {
  // margin-top: 0.5rem;
  @media screen and (max-width: 550px) {
    margin-top: 0rem;
  }
  .accordion {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    background-color: transparent;
    @media screen and (max-width: 550px) {
      padding: 0rem !important;
    }
  }
  .time-title-wrapper {
    height: 100%;
    span.date {
      min-width: 5rem;
    }
  }
  .accordion-wrapper {
    .post_following_tag,
    .post_category_tag {
      transition: all 0.3s;
    }

    &:hover {
      .expander {
        .accordion-arrow {
          background: var(--cu-black-100);
          .post_following_tag,
          .post_category_tag {
            background: var(--cu-black-700);
          }
        }

        .expanded {
          background: var(--cu-black-500);
          &:hover {
            background: var(--cu-black-500);
          }
        }
      }
    }

    .expanded, .expander-content {
      background: var(--cu-black-500);
    }
  }
}
.add-button {
  background-color: var(--neutral-200);
  border: 1px solid var(--dark-mode-300); 
  padding: 0.375rem 0.625rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  &:hover {
    background: var(--Dark-Mode-300, #30333A) !important;
}
  @media screen and (max-width: 550px) {
    width: 100%;
    justify-content: center;
    &.select-all{
      width: auto;
      background-color: transparent;
      border: none; 
      font-size: 0.5rem;
      font-weight: 400;
      color:var(--cu-black-10);
      padding: 0rem;
    }
    
  }
}
.pro-img{
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1px solid var(--cu-black-100);
  overflow: hidden; 
  flex: 0 0 3rem; 
  @media screen and (max-width: 550px) {
    width: 1.25rem;;
    height: 1.25rem;;
    flex: 0 0 1.25rem;;
  }
  img{
    width:100%;
    height: 100%;
    object-fit: cover;
    overflow-clip-margin: unset;
  }
}
.list-announcement{
  @media screen and (max-width: 550px) {
    height: 100%;
    justify-content: space-between;
    padding-bottom: 3rem;
   }  
}
