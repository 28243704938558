.dis-gallery-container {
  grid-template-columns: repeat(auto-fill, minmax(17.375em, 1fr));
  grid-template-rows: max-content;
  display: grid;
  gap: 0.71429rem;
  width: 100%;
  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.5rem;
  }
  .screenshot-image img {
    height: 100%;
  }
}
.gallery-images-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17.375em, 1fr));
  grid-auto-rows: 1fr;
  gap: 0.71429rem;
  width: 100%;

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }
}

.gallery-image-item {
  width: 100%;
  aspect-ratio: 1 / 1; 
  border-radius: 0.28rem !important;
  border: 1px solid #20232A !important;
}
.sm-h-8{
  @media screen and (max-width: 550px) {
    min-height: 8rem;
  } 
}
.gallery-right-dis-creator {
  .card-half {
    max-width: 100%;
  }
  .slider-user {
    height: 100%;
  }
  .detail-wrapper {
    height: 100%;
    overflow: auto;
-webkit-overflow-scrolling: touch;
    .user-preview {
      max-height: calc(100vh - 24rem);
    }
  }
}
.mobile-view-gallery {
  .slider-user {
    background: transparent;
    border: none;
    .detail-wrapper {
      padding: 0px;
    }
  }
  .openbtn {
    padding: 0px;
  }
}
.view-gallery-detail-mob {
  @media screen and (max-width: 550px) {
    .card-half {
      max-width: 100%;
    }
    .shareModal {
      display: none;
    }
    .hidden-mo {
      display: none !important ;
    }
    .items-mob {
      max-width: 25%;
      border: none;
    }
    .right-panel-v-border {
      display: block;
    }
    .communityGallery_details-title-sm {
      li {
        color: var(--neutral-100, #e7eaee) !important;
        font-family: ZenKaku;
        font-size: 0.875rem !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: normal;
      }
    }
    .details-description {
      color: var(--neutral-100, #e7eaee) !important;
      font-family: ZenKaku;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
