.lock-component{
        background: var(--cu-grey-disabled);
        .tooltip-container {
            .tooltip-message-wrapper {
                top:1rem;
                .tooltip-message {
                    min-width: 8rem;
                    font-size: 0.5625rem;
                }
    
            }
        }
    
}