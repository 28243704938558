.places_input-container {
    position: relative;

    .places_input-box {
        width: 100%;
        background-color: var(--cu-black-700);
        border: 1px solid var(--cu-black-100);
        color: var(--cu-white-900);
        padding: 0.5rem 1rem;
        border-radius: 5rem;
        font-size: .75rem;
        &:placeholder {
            color: var(--cu-black-10);
        }
    }

    .places_input_select-box {
        position: absolute;
        top: 105%;
        width: 100%;
        left: 0;
        right: 0;
        background: var(--dark-mode-900);
        border-radius: 12px;
        border: 1px solid var(--cu-black-100);
        text-align: left;
        z-index: 100;
        cursor: pointer;
        white-space: nowrap;
        min-width: -moz-fit-content;
        min-width: -webkit-fill-available;
        min-width: fit-content;
        font-size: .75rem;
        padding: 0.5rem 0;
        li {
            display: flex;
            align-items: center;
            gap: 0.6rem;
            padding: 0.4rem 1rem;
            line-height: 1rem;
            color: var(--cu-black-10);
            font-size: .75rem !important;

            &:hover,
            &.selected {
                color: var(--cu-white-200);
            }
        }
    }
}