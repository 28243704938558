.btn-dark {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.375rem;
    gap: 0.625rem;

    width: 100%;
    height: 100%;
    &:disabled,
    &[disabled]{
        cursor: not-allowed;
    }
    /* Neutral / 800 */
    &.curate-gradient-button{
        position: relative;
        overflow: hidden;
        border: none !important;
        transition: all 0.3s ease;
        z-index: 5;
        background: linear-gradient(
          94deg,
          rgba(37, 141, 244, 0.6) -3.86%,
          rgba(65, 125, 246, 0.6) 7.42%,
          rgba(136, 88, 252, 0.6) 36.14%,
          rgba(164, 74, 255, 0.6) 49.48%,
          rgba(166, 72, 248, 0.6) 53.58%,
          rgba(172, 66, 230, 0.6) 59.74%,
          rgba(183, 58, 200, 0.6) 66.92%,
          rgba(198, 46, 159, 0.6) 75.12%,
          rgba(217, 30, 105, 0.6) 84.36%,
          rgba(239, 12, 42, 0.6) 93.59%,
          rgba(255, 0, 0, 0.6) 98.72%
        ) !important;
        &:after {
          position: absolute;
          content: " ";
          top: 0;
          left: 0;
          z-index: -1;
          width: 100%;
          height: 100%;
          border-radius: 0.625rem;
          transition: all 0.3s ease;
          -webkit-transform: scale(.1);
          transform: scale(.1);
        }
        &:hover {
          color: #fff !important;
          border: none !important;
          background: transparent !important;
        }
        &:hover:after {
          background: linear-gradient(
            94deg,
            rgba(37, 141, 244, 0.6) -3.86%,
            rgba(65, 125, 246, 0.6) 7.42%,
            rgba(136, 88, 252, 0.6) 36.14%,
            rgba(164, 74, 255, 0.6) 49.48%,
            rgba(166, 72, 248, 0.6) 53.58%,
            rgba(172, 66, 230, 0.6) 59.74%,
            rgba(183, 58, 200, 0.6) 66.92%,
            rgba(198, 46, 159, 0.6) 75.12%,
            rgba(217, 30, 105, 0.6) 84.36%,
            rgba(239, 12, 42, 0.6) 93.59%,
            rgba(255, 0, 0, 0.6) 98.72%
          );
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    background: var(--cu-black-700);
    /* Dark Mode / 300 */

    border-radius: 1.125rem;
    cursor: pointer;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.75rem;
}

.type-1 {
    border-radius: 0.6875rem;
    color: var(--cu-white-900);
    border: 0.0625rem solid var(--cu-white-900);
    border-radius: 0.6875rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}


.type-2 {
    background: var(--cu-black-400);
    border-radius: 0.625rem;
    color: var(--cu-white-200);
    font-size: 0.825rem;
    padding: .75rem 0;
    font-weight: 400;
    border: 1px solid var(--Dark-Mode-300, #30333a);
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}

.type-3 {
    color: var(--cu-white-900);
    border: 0.0625rem solid var(--cu-white-900);
    border-radius: .75rem;
    background-color: transparent;
    padding: 0.407rem 0.625rem;
    font-size: .75rem;
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}

.type-6 {
    color: var(--cu-white-900);
    border: 0.0625rem solid var(--cu-white-900);
    border-radius: .4rem;
    background-color: var(--cu-black-700);
    padding: 0.407rem 0.625rem;
    font-size: .75rem;
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}

.type-4 {
    color: var(--cu-white-900);
    border: 0.125rem solid var(--cu-white-900);
    border-radius: 1.25rem;
    background-color: transparent;
    font-size: .75rem;
    line-height: .875rem;
    padding: .5rem 0;
    font-weight: 600;
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}

.type-5 {
    border: 1px solid #0000;
    border-radius: 0.71rem;
    background:
        linear-gradient(var(--cu-black-500), var(--cu-black-500)) padding-box,
        linear-gradient(#00C5FF,
            #4745ED) border-box;
    font-size: 0.75rem;
    padding: 0.64rem;
    width: fit-content;
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}

.type-7 {
    border-radius: 10px;
    border: 0.0625rem solid var(--cu-white-900);
    background: var(--cu-black-700);
    font-size: 0.875rem;
    padding: 0.64rem;
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}

.type-8 {
    border-radius: 0.71rem;
    border-radius: 10px;
    border: 1px solid var(--dark-mode-300, #30333A);
    background: var(--cu-black-500);
    font-size: 0.875rem;
    padding: 0.64rem;
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}

.type-9 {
    background-color: var(--neutral-900);
    padding: 0.42857rem 0.71429rem;
    border-radius: 0.25rem;
    border: 1px solid var(--cu-black-10);
    font-size: 0.75rem;
    font-weight: 400;
    display: flex;
    width: fit-content;
    height: fit-content;
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }

}

.type-10 {
    color: var(--Neutral-500, #64748B);;
    border: 1px solid var(--dark-mode-300);
    padding: 0.42857rem 0.71429rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: 400;
    display: flex;
    width: fit-content;
    height: fit-content;
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}

.type-11 {
    border: 1px solid var(--cu-black-70);
    border-radius: 0.71rem;
    background: var(--cu-black-500);
    font-size: 0.75rem;
    padding: 0.64rem;
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}

.type-12 {
    display: flex;
    padding: .635rem 0;
    justify-content: center;
    align-items: center;
    gap: .635rem;
    align-self: stretch;
    border-radius: 1.25rem;
    border: 1px solid var(--Dark-Mode-300, #30333A);
    background: var(--Neutral-900, #0D0F11);
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}
.type-13 {
    border-radius: .75rem;
    border: 1px solid var(--dark-mode-300, #30333A);
    background: var(--neutral-900) !important;
    font-size: 0.875rem;
    padding-bottom: .5rem;
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}

.type-14 {
    border-radius: full;
    border: 0.0625rem solid var(--cu-white-900);
    border-radius: full;
    border: 1px solid var(--dark-mode-300, #30333A);
    background: var(--cu-black-700);
    font-size: 0.875rem;
    padding: 0.64rem;
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}
.type-15 {
    border-radius: full;
    border: 1px solid var(--dark-mode-300, #30333A);
    background: var(--cu-black-500);
    font-size: 0.875rem;
    padding: 0.64rem;
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}

.type-16 {
    background: var(--cu-black-400);
    border-radius: 0.625rem;
    color: var(--cu-white-200);
    font-size: 0.825rem;
    padding: .75rem 0;
    font-weight: 400;
    border: 1px solid var(--cu-white-900);
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}

.type-17, .type-18 {
    padding: 0.65rem 1rem;
    border-radius: 5rem;
    font-size: 0.75rem;
    transition: all 0.3s;
    color: var(--cu-white-900);
    border: none;
    box-shadow: 0 0 0 1px var(--cu-black-100);
    &:hover {
      background: var(--dark-mode-300);
    }
}
.type-17 {
    background: var(--dark-mode-900);
}
