.description-tab {
    padding: 0rem 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    width: 100%;
    .details {
      width: 100%;
      flex: 1;
      overflow: hidden;
      max-height: calc(100% - 14rem);
  
      .details-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        font-size: 0.75rem;
        color: var(--cu-white-200);
        height: 100%;
        padding-top: .75rem;
        .gallery-title {
          color: var(--cu-white-200);
          font-family: ZenKaku;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
  
        .details-sm {
          font-size: 0.5rem;
  
          .details-title-sm {
            color: var(--cu-grey-600);
          }
        }
  
        .details-description {
          color: var(--cu-white-200);
          text-align: left;       
          font-weight: 400;
          font-size: .875rem;
        }
  
        .details-container {
          display: flex;
          margin: 0.375rem 0rem;
        }
  
        .data-box {
          display: flex;
          flex-direction: column;
          width: 100%;
          border: 0.5px solid #30333a;
          border-top: none;
          border-left: none;
          border-bottom: none;
          flex: 1 1 5rem;
          padding: 0rem 1rem 0 0.5rem;
          margin-right: 0.5px;
          gap: 0.28571rem;
          &:last-child {
            border: none;
          }
          .data-title {
            text-align: left;
            font-weight: 400;
            font-size: 0.85714rem;
            color: var(--cu-white-100);
            line-height: 1.21429rem;
            @media screen and (max-width: 550px) {
              font-size: 0.75rem;
            }
          }
  
          .data-value {
            flex: 1;
            word-break: break-word;
            text-align: left;
            font-weight: 400;
            color: var(--cu-white-200);
            font-size: 0.85714rem;
            line-height: 1.21429rem;
            display: flex;
            align-items: center;
            @media screen and (max-width: 550px) {
              font-size: 0.75rem;
            }
          }
        }
  
        .data-box-one {
          flex: 1 1 10rem;
        }
  
        .details-sm {
          font-size: 1rem;
  
          ul.details-title-sm {
            display: flex;
            gap: 0.25rem;
            list-style-type: none;
            font-size: 1rem;
  
            li {
              color: var(--cu-white-200);
              font-weight: 600;
              font-size: 1.125rem;
              &::marker {
                margin: 3rem;
              }
            }
          }
        }
      }
    }
  }