.wallet-select {
    position: relative;
    // height: 100%;
    // width: 3rem !important;
    border-radius: 0.375rem;
    display: grid;
    align-items: center;
    cursor: pointer;
    .connect-text{
       font-size: 0.8125rem;
    }
    .wallet-icon-wrapper{
        width: 1.25rem;
        height: 1.25rem;
        display: inline-block;
        img{
            height: 100%;
        }
        // z-index: 9;
        // &:hover{
        //     border-color: var(--cu-black-30);
        // }
    }
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 0.25rem;
        padding: 1px;
        pointer-events: none;
        // border: 1px solid #30333A;    
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude; 
        z-index: 9;
      }


    &.loading {
        padding: 0.5rem;
    }

    &-label {
        height: 100%;
        display: grid;
        grid-template-columns: 1.8rem auto;
        align-items: center;
        // overflow: hidden;
        border-radius: 0.3125rem;
        cursor: pointer;

        &-connector {
            display: grid;
            align-items: center;
            height: 100%;
            padding: 0.5rem 0.3125rem;
            grid-template-columns: auto .875rem;
            background: var(--cu-black-500);
        }

        img {
            width:100%;
        }
    }

    &.close-popup .wallet-select-available {
        visibility: hidden;
        opacity: 0;

    }

    &.open-popup {
        // .wallet-icon-wrapper{
        //     z-index: 9;
        //     &:hover{
        //         border-color: var(--cu-black-30);
        //     }
        // }

        .connect-text{
            color: var(--cu-white-200);
        }
        .stroke-color{
            svg{
                path{
                    stroke: var(--cu-white-200);
                }
            }
        }
        .wallet-select-available {
        visibility: visible;
        opacity: 1;
    }
}

    &-available {
        position: absolute;
        right: -1.5rem;
        top: 2.8rem;
        width: 100%;
        z-index: 102;
        background: var(--dark-mode-900);
        border: 1px solid var(--dark-mode-300);
        border-radius: 0.3125rem;
        transition: .5s ease;
        min-width: 8rem;
        ul {
            padding: .375rem 0;
            color: var(--cu-white-200);
            font-size: .8125rem;
            margin-top: 1rem ;
            padding-bottom: .5rem;
            text-align: left;
            li {
                grid-gap: 0.5rem;
                align-items: center;
                color: var(--cu-black-5);
                cursor: pointer;
                display: grid;
                font-size: 0.8125rem;
                gap: 0.5rem;
                justify-content: start;
                grid-template-columns: 2rem calc(100% - 5rem) 2rem;
                text-align: left;
                text-transform: capitalize;
                padding: 0.5rem;
                &:hover {
                    background-color: none;
                    color: var(--cu-white-200);
                }
                // &.activeWallet {
                //     position: relative;
                //     padding-right: 1rem;
                //     background-color: var(--cu-black-500);
                //     margin: 0.5rem 0;
                // }
            }
        }
    }
    .walletlist-drop-drown{
        min-width: 10rem; 
        ul{
            display: flex;
            flex-direction: column;
            padding-bottom: 1rem;
            hr{
                margin: 0.5rem 0rem;
                color: var(--cu-black-20);
                border-color:var(--cu-black-20);
                background-color: var(--cu-black-20);;
            }
            li{
                &.connect-wallet{
                    width: auto;
                }
                display: flex;
                justify-content: space-between;
                border-radius: 0.25rem;
                padding: 0.25rem 0.375rem;
                margin: 0.25rem 0.625rem;
                .wallet-select-available-icon{
                    width: 1.5rem;
                    height: 1.5rem;
                    img{
                        width: 100%;
                        height: 100%;  
                    }
                }
                :is(&:hover,&.activeWallet) {
                    background: var(--cu-black-50);
                    color: var(--cu-white-200);
                }
            }
        }
    }
    .connect-wallet {
        width: 100%;
        height: 100%;
        font-size: 0.8125rem;
        padding: 0.5rem;
        margin-top: .15rem;
        img{
            max-width: 1.5rem;
            margin: auto;
        }
        &:hover{
            img{
                filter: grayscale(0.7) brightness(1.9); 
            }
        }
    }
    .refresh-wallet {
        width: 100%;
        height: 100%;
        border-bottom-width: 1px;
        border-bottom-color: var(--cu-black-20);
        font-size: 0.75rem;
        padding: 0.5rem;
        img{
            max-width: 1.5rem;
            margin: auto;
        }
        &:hover {
            img {
              filter: grayscale(0.7) brightness(1.9); 
            }
        }
    }
}

.wallet-select-available-icon {
    img {
        max-width: 1.5rem;
        margin: auto;
    }
}

.header-wallet {

    .wallet-select-label-connector {
        padding: 0.5rem !important;
    }

    .wallet-select.loading {
        padding: 0rem 0.5rem;
    }

    .wallet-select {
        min-width: 2rem;
    }
}
