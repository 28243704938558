.account {

    .wallet-enter {
        input {
            width: 18.563rem;
            padding: 0.625rem;
            border-radius: 0.25rem;
            color: var(--cu-black-700);
        }

        button {
            background-color: var(--cu-white-900);
            color: var(--cu-black-900);
            padding: 0.25rem .375rem;
            line-height: .625rem;
            border-radius: 0.25rem;
        }
    }

    .date-select {
        font-weight: 400;
        font-size: 0.625rem;
        flex-wrap: wrap;

        .instruction-box {
            width: 10.25rem;
            height: 3.375rem;
            text-align: left;
            font-size: .875rem;
        }

        input,
        select {
            background-color: var(--cu-black-700);
            color: var(--cu-white-100);
            padding: 0.313rem;
            border-radius: 0.25rem;
        }

        input {
            width: 3.63rem;
            height: 1.38rem;
        }

        input[type=date]::-webkit-datetime-edit {
            color: transparent;
        }

        input[type=date]:focus::-webkit-datetime-edit {
            color: transparent;
        }

        button {
            width: 3.45rem;
            border: 0.0625rem solid var(--cu-white-900);
            border-radius: .25rem;
        }
    }

    .table-container {
        padding-top: 1rem;
        white-space: nowrap;
        overflow: auto;
-webkit-overflow-scrolling: touch;

        table {
            width: 100%;

            tbody tr {
                line-height: 1.532rem;
            }

            th {
                font-weight: 400;
                text-align: center;
                padding: 0 1.3rem;
            }

            td:first-child,
            th:first-child {
                text-align: left;
                padding-left: 0;
            }
        }
    }

    .responsive-table {
        display: none;
    }

    @media screen and (max-width: 767px) {
        .wallet-enter {
            input {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 550px) {
        .table-container {
            display: none;
        }

        .responsive-table {
            display: block;
            width: 100%;

            table {
                width: 100%;
            }
        }


    }
}