//NOTE: this file used for creator subscription as well
.subscriptioncard {
  display: flex;
  flex-direction: column;
  width: 23rem;
  height: 43rem;
  border-radius: 0.625rem;
  border: 1px solid #202e40;
  gap: 1.875rem;
  padding: 2.5rem 1.25rem;
  position: relative;
  transition: all 0.3s;
  // margin-bottom: 1.5rem;
  
  @media screen and (max-width: 767px) {
    width: 99%;
    margin-bottom: 1rem;
    &:last-child{
      margin-bottom: 0rem;
    }
  }
  @media screen and (max-width: 550px) {
    padding: 1rem;
    width: 100%;
    background: var(--cu-black-500);
    border: 1px solid var(--cu-black-400);
  }
  h3 {
    color: var(--cu-black-5);
    font-family: ZenKaku;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media screen and (max-width: 550px) {
      font-size: 1rem;
    }
  }
  h4 {
    color: var(--cu-white-200);
    font-family: ZenKaku;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media screen and (max-width: 550px) {
      padding: 0.75rem 0;
      font-size: 1.25rem;
    }
  }
  .pricespan {
    color: var(--cu-white-200);

    font-family: ZenKaku;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    .year {
      color: var(--cu-white-100);
      font-family: ZenKaku;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .items {
    padding-left: 10%;
    flex-direction: column;
    margin-bottom: 4.375rem;
    @media screen and (max-width: 550px) {
      display: block;
      margin: 0 auto;
      width: fit-content;
      margin-bottom: 0rem;
      padding-left:0rem;
    }
    .each-options {
      margin-bottom: 1.25rem;
      @media screen and (max-width: 550px) {
        margin-bottom: 0.625rem;
      }
      label {
        color: var(--cu-white-200);
        font-family: ZenKaku;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        @media screen and (max-width: 550px) {
          font-size: 0.75rem;
        }
      }
    }
  }
  .Subscribed-btn {
    display: flex;
    padding: 0.625rem;
    justify-content: center;
    margin-bottom: 1.875rem;
    align-items: center;
    gap: 0.125rem;
    flex: 1 0 0;
    width: 90%;
    align-self: stretch;
    border-radius: 0.688rem;
    border: 0.063rem solid var(--cu-blue-60);
    position: absolute;
    bottom: 0.625rem;
    background: var(--cu-black-500);
    transition: all 0.3s;
    @media screen and (max-width: 550px) {
      position: static;
      width: 100%;
      border-radius: 0.75rem;
      border: 1px solid var(--cu-black-100);
      font-size: 0.75rem;
      font-weight: 400;
      &.active{
        border: 1px solid var(--cu-blue-60);
        background: var(--cu-black-500);
      }
    }
  }
}
.subscriptioncard:hover {
  .Subscribed-btn {
    background: var(
      --MemberPlan_selected,
      linear-gradient(
        154deg,
        rgba(49, 110, 202, 0.9) 2.26%,
        rgba(20, 47, 88, 0.9) 100.03%
      )
    );
    box-shadow: 0px 4px 4px 0px #162029;
  }
}
.subscriptioncard.active {
  background: var(
    --MemberPlan_selected,
    linear-gradient(
      154deg,
      rgba(49, 110, 202, 0.9) 2.26%,
      rgba(20, 47, 88, 0.9) 100.03%
    )
  );
  box-shadow: 0px 4px 4px 0px #162029;
  h3 {
    color: var(--neutral-50, var(--cu-white-200));
    font-family: ZenKaku;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
span.monet {
  display: flex;
  justify-content: center;
  gap: 0.438rem;
  align-items: center;
  .hold {
    color: var(--cu-white-100);
    font-family: ZenKaku;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}