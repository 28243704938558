.wallet-list {
    padding: 1.25rem;

    ul {
        display: grid;
        grid-template-columns: 100%;
        text-align: left;
        gap: 0.5rem;
        align-items: center;

        li {
            display: grid;
            grid-template-columns: minmax(4rem, 4.5rem) auto;
            gap: .5rem;
            text-overflow: ellipsis;
            word-wrap: break-word;
            word-break: break-all;
            font-size: 0.75rem;

            .wallet-list-count {
                border-radius: 0.25rem;
                text-align: center;
                padding: .2rem;
                border: 1px solid transparent;


                &.edit-mode {
                    background: var(--cu-black-700);
                    border-color: var(--cu-black-100);
                }
            }
        }
    }
}

.wallet-add {
    .wallet-icon {
        width:1.5rem;
        margin-right: 0.5rem;
    }
}

.wallet-address {
    max-width: 20rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    font-size: 0.84rem;
    color: var(--cu-white-900);
}

.wallet-name {
    font-size: 0.84rem;
    color: var(--cu-white-900);
}

.wallet-nfts {
    font-size: 0.84rem;
    color: var(--cu-white-100);
}

.wallet-full {
    flex: 0 1 31rem;
    width: 100%;
    flex-wrap: wrap;

}


@media screen and (max-width: 1008px) {
    .address-wrap {
        flex-wrap: wrap;
    }

    .wallet-address {
        max-width: 14rem;
    }
}

@media screen and (max-width: 550px) {
    .mob-connected {
        gap: 0.5rem !important;
        padding: 2rem 1rem 1rem 1rem !important;

        .address-wrap {
            flex-wrap: nowrap !important
        }

        .wallet-address {
            max-width: 20rem;
        }
    }
}

.wallet-add {
    font-size: 0.84rem;
    @media screen and (max-width: 550px) { 
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }    
}

input{
    &.input-field{
        background-color: var(--cu-black-700);
        border: 1px solid var(--cu-black-100);
        border-radius: 0.25rem;
        color: var(--cu-white-200);
        font-size: .625rem;
        max-height: 7rem;
        padding: 0.5rem;
    }
}
.edit-section{
    .error-validation{
        text-align: left;
    }
}
.edit-icons{
    img{
        width: 1.25rem;
        height: 1.25rem;
    }
}
.linked-wallet{
    .no-data{
        min-height: 10rem;  
    }
}