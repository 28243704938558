.slider-image-wrp{
    cursor: pointer;

    .slider-image-cls{
        background-size: cover;
        height: 6rem;
        background-position: center;
        background-repeat: no-repeat;
        border: 0.4rem solid var(--cu-black-500);
        margin: 0 0.5rem 0 0;
        @media screen and (max-width: 550px) { 
            height: 10rem;
        }
    }
    .slider-image-cls-active{
        border: 0.4rem solid var(--cu-blue-400);
    }
}


