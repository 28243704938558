.infobackBtn {
    background: linear-gradient(var(--cu-black-500) 0 0) padding-box, linear-gradient(to bottom, var(--cu-blue-300), var(--cu-blue-700)) border-box;
    color: var(--cu-white-900);
    padding: 0.3125rem;
    border: .0625rem solid transparent;
    border-radius: 0.625rem;
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    margin-top: auto;
    font-size: 0.5625rem;

    @screen md {
        margin-top: 0;
        font-size: 0.875rem;
        line-height: 1.0625rem;
        padding: .625rem
    }
}

.viewButtons {
    gap: 0.1875rem;

    img {
        filter: grayscale(0.7) brightness(1.3);
        -webkit-filter: grayscale(0.7) brightness(1.3);
        transition: opacity .3s ease;
        height: 1.1rem;

        &.activeDis {
            filter: none;
            -webkit-filter: none;
        }
    }

    @media screen and (max-width: 550px) {
        .list {
            display: none;
        }
    }
}