.event-details-overlay {
    // position: fixed;
    // top: 0rem;
    // left: 0rem;
    // right: 0rem;
    // bottom: 0rem;
    // width: 100%;
    // height: 100%;
    // backdrop-filter: blur(2px); /* Apply the blur effect */
    // z-index: 1000;
    .details-container {
      // display: flex;
      height: 600px;
      // padding-bottom: 3rem;
      // border-radius: 0.25rem;
      // border: 1px solid var(--dark-mode-300);
      // overflow: hidden;
      padding: 2rem 2rem 2rem 2rem;
      // position: fixed;
      // top: 10rem;
      // left: 8rem;
      // right: 8rem;
      // bottom: 15rem;
    }
    .body {
      display: flex;
      width: 100%;
      @media screen and (max-width: 550px) {
        height: 5.375rem;
      }
    }
    .head {
      .time {
        color: var(--cu-black-5);
        font-size: 0.75rem;
        @media screen and (max-width: 550px) {
          font-size: 0.625rem;
        }
      }
      .title {
        color: var(--cu-white-200);
        font-size: 1rem;
        text-align: left;
        @media screen and (max-width: 550px) {
          font-size: 0.625rem;
        }
      }
      @media screen and (max-width: 550px) {
        flex-direction: column;
        align-items: start;
      }
    }
  }