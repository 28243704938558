.frame-display-ui {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    height: 100%;
    flex: 1;
    overflow: auto;
-webkit-overflow-scrolling: touch;
    gap: .625rem;
    padding-bottom: 3rem;
    &.no-apply-btn{
        padding-bottom: 0rem;   
    }
    .side-scroller{
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar{
            width: 0.1875rem;
    }
}
    .frames-list-view {
        display: flex !important;
    }


    @media screen and (max-width: 550px) {
        padding: 0rem;
        padding-bottom: 4rem;
        padding-top: 0.625rem;
    }

    &::-webkit-scrollbar {
        display: block;
        width: 0.1875rem;
    }

    .frame-title {
        display: flex;
        .refresh-img{
            width: 0.9375rem;
            height: 0.8125rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .purchase-link {
            margin-left: auto;
            font-weight: 400;
            font-size: .75rem;
            line-height: .875rem;
            text-decoration-line: underline;
            color: var(--cu-white-200);
            cursor: pointer;
        }
    }

    .frames {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
        gap: 0.625rem;
        overflow-x: hidden;
    }

    .view-more-btn {
        button {
            border: .0625rem solid var(--cu-white-900);
            padding: 0 1rem;
        }
    }

    .button-bar {
        position: absolute;
        width: 100%;
        bottom: 0rem;
        display: flex;
        justify-content: flex-end;
        z-index: 2;
        padding-right: 0.437rem;
        .apply-btn {
            border: 1px solid var(--cu-blue-150);
            border-radius: .5rem;
            padding: 0.375rem .625rem;
            background: var(--cu-black-500);
            gap: .625rem;
        }
        .apply-button {
            background: var(--cu-black-500);
        }
    }

    .frame-display {

        &.selected {
            border: .0625rem solid var(--cu-white-200);
        }
        &.bg-gray{
            background: var(--cu-black-500);
        }
        border-radius: .25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;
        .frame-box {
            width: 100%;
            height: 100%;
            display: grid;
        }

        p {
            margin-top: auto;
            font-size: .4375rem;

        }
        .no-frame-text{
            padding: 1.125rem;
            border-radius: 0.5rem;
            border: 1px solid var(--Dark-Mode-300, #30333A);
            p{
                color: var(--cu-white);
                font-size: 0.75rem;
                margin: 0 auto;
            }
        }
        &.no-frame {
            background: var(--cu-black-700);

            .x {
                padding: 1.172rem 0.75rem;

                p {
                    font-size: 2rem;
                }


            }
        }
        .add-frame-text{
            padding: 1.125rem;
            border-radius: 0.5rem;
            border: 1px solid var(--Dark-Mode-300, #30333A);
            p{
                color: var(--cu-white);
                font-size: 0.75rem;
                margin: 0 auto;
            }
        }
        &.add-frame {
            background: #000;

            .x {
                padding: 1.172rem 0.75rem;

                p {
                    font-size: 2rem;
                }


            }
        }
    }

    .asset-frame-selection-container {
        grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    }

    @media screen and (max-width: 550px) {
        flex: 1 1 12rem;

        .button-bar {
            left: 0rem;
            right: 0rem;
            bottom: 0rem;
            position: fixed;
            width: unset;
            padding: 1rem;

            .apply-btn {
                width: 100%;

            }
        }
    }
}

.x-size {
    font-size: 3rem !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: -webkit-linear-gradient(180deg, #00C5FF 0%, #4745ED 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.x-font {
    font-size: 0.75rem !important;
    margin-top: 1rem !important;
}

.no-frame-display {
    background: var(--cu-black-700);
    // min-height: 6.5rem;
}

.frame-image-size {
    width: 3rem;
}

.frame-font-size {
    font-size: 0.8rem !important;

}