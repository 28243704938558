.my-NFT {
    height: 100%;
    gap: .625rem;
    max-height: 100%;
    overflow: auto;
-webkit-overflow-scrolling: touch;

    @media screen and (max-width: 550px) {
        margin-top: 3rem;
    }

    .add-policy-panel {
        position: sticky;
        z-index: 1;
        top: 0rem;

        @media screen and (max-width: 550px) {
            top: -0.2rem;
        }

        .add-policy {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.25rem;

            button {
                border: 0.125rem solid var(--cu-white-900);
                border-radius: .5rem;
                width: 2.75rem;
                height: 1.875rem;
                font-size: .75rem;
            }
        }
    }

    .scroll-box {
        padding-right: 0.5625rem;
        max-height: 100%;

    }

    .update-btn,
    .view-control {
        position: absolute;
        right: 0.625rem;
    }

    .policy-form {
        position: relative;

        .form-row {
            &.view-policy-id {
                margin-right: 4.25rem;
            }

            .policy-id-row {
                display: flex;
                flex: 1;
                // margin-right: 2.25rem;
            }

            .form-title {
                font-size: .875rem;

                @media screen and (max-width: 550px) {
                    color: var(--cu-white-100);
                    font-size: 10px;
                    letter-spacing: 0.02rem;
                }
            }

            .form-value {
                font-size: .875rem;
                color: var(--cu-white-700);

                @media screen and (max-width: 550px) {
                    flex-basis: 100% !important;
                }


            }


            input,
            textarea,
            select {
                background: var(--cu-black-700);
                border: 0.0625rem solid var(--cu-black-100);
                border-radius: 0.25rem;
                color: var(--cu-white-700);
                font-size: .875rem;
                padding: 0.425rem;

                @media screen and (max-width: 550px) {
                    word-break: break-all;
                    text-align: left;
                    padding: 5px;
                    border: 1px solid;
                    background: var(--cu-black-700);
                    border: 1px solid var(--cu-black-100);
                    border-radius: 4px;
                    font-size: 0.75rem;
                    min-width: 16rem;
                    color: #d9dbdf;
                    letter-spacing: 0.02rem;
                    max-height: 7rem;
                    overflow: auto;
-webkit-overflow-scrolling: touch;
                    width: 100%;
                }
            }
            textarea {
                resize: none;
            }
            textarea::-webkit-scrollbar {
                display: none;
            }

            .col-gal-select {
                .select-box-sm {
                    font-size: 1rem;
                }
            }

            select {
                padding: 0.1875rem 0.3125rem;
                width: 4.375rem;
            }


            .input-long {
                flex: 1;

            }
        }

        .message {
            span {
                font-size: .875rem;
            }
        }

    }

    @media screen and (max-width: 550px) {
        .scroll-box {
            padding-right: 0;
        }

        .policy-form {
            .form-row {
                flex-wrap: wrap;
                gap: 0.625rem;
                flex-direction: row;

                &:first-child {
                    align-items: flex-start;
                }

                .policy-id-input {
                    padding-right: 0;

                    @media screen and (max-width: 550px) {
                        flex-basis: 100%;
                    }
                }

                .policy-id-row {
                    flex-wrap: wrap;
                    margin-right: 0;

                    @media screen and (max-width: 550px) {
                        gap: 1rem;
                    }
                }

                .input {
                    flex-basis: 100%;
                }

                .styles-select {
                    width: 100%;
                }

                .form-title {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.span-text {
    word-break: break-all;
    text-align: left;
    padding: 5px;
    border: 1px solid;
    background: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    border-radius: 4px;
    font-size: 0.75rem;
    min-width: 16rem;
    color: #d9dbdf;
    letter-spacing: 0.02rem;
    max-height: 7rem;
    overflow: auto;
-webkit-overflow-scrolling: touch;
    width: 100%;
    margin-bottom: 0.2rem;
}

.label-color {
    color: var(--cu-white-100);
    font-size: 10px;
    letter-spacing: 0.02rem;

}

.policy-row {
    @media screen and (max-width: 550px) {
        display: contents;
    }
}

.creation-desc {
    min-height: 2.25rem;
}