.create {
  .no-data{
    min-height: 1rem;
    font-size: 0.75rem;
  }
  .target-collection{
    .skelton-card{
      padding: 0rem;
    }
  }
  h2 {
    font-size: 1.375rem;
    font-weight: 600;
  }
  .section-title {
    font-size: 1rem;
    font-weight: 400;
    .verification-text{
      font-size: 1.125rem;
    }
  }
  .brd-btm {
    border-bottom: 1px solid var(--cu-black-100);
    padding-bottom: 0.625rem;
  }
  label {
    font-size: 0.625rem;
    font-weight: 400;
    color: var(--cu-white-100);
  }
  :is(input, textarea)::placeholder {
    color: var(--cu-black-5);
  }
  input,
  textarea {
    background-color: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    color: var(--cu-white-200);
    font-size: 0.75rem;
    max-height: 7rem;
    padding: 0.425rem;
    border-radius: 4px;
  }
  input {
    padding: 0.6rem 1rem;
    border-radius: 50px;
  }
  h3 {
    font-size: 0.875rem;
    font-weight: 500;
  }
  textarea {
    max-height: 33.5rem;
    resize: none;
    @media screen and (max-width: 550px) {
      max-height: 6rem;
    }
  }
  .new-checkbox span {
    background: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    border-radius: 4px;
    color: var(--cu-black-10);
    padding: 0.2rem 0.625rem;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    &.active {
      border-color: var(--cu-white-900);
    }
  }
  .subject-container {
    .subject-list-box {
      .mono-subject {
        font-size: 0.8rem;
        padding: 0.3rem 0.65rem;
        border-radius: 8px;
        transition: all 0.3s;
        color: var(--cu-black-5);
        border: none;
        box-shadow: 0 0 0 1px var(--cu-black-100);
        &:hover {
          color: var(--cu-white-100);
          background: var(--dark-mode-300);
          cursor: pointer;
        }
      }
      .mono-subject.active {
        color: var(--cu-white-100);
        background: var(--cu-grey-350);
      }
    }
    .flex-3-per {
      display: flex;
      gap: 3.5rem;
    }
    .grid-2-per {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .button {
    width: 20%;
    padding: 0.5rem 0.875em;
    background: unset;
    color: var(--cu-black-10);
    border-color: var(--cu-grey-650);
    &.save {
      background: var(--cu-black-700);
      color: var(--cu-black-10);
      border-color: var(--cu-grey-650);
    }
    &.active {
      color: var(--cu-white-900);
      border-color: var(--cu-white-900);
    }
    @media screen and (max-width: 550px) {
      font-size: 0.75rem;
      font-weight: 400;
      border-radius: 0.75rem;
    }
  }
  .sub-title{
    font-size: 0.75rem;
    @media screen and (max-width: 550px) {
      font-size: 0.625rem;
      font-weight: 400;
      color:var(--cu-white-100)
    }
  }
  .popup-template {
    top: 7rem;
    .popup-template-body {
      max-height: calc(100vh - 6rem);
    }
    .rmdp-arrow-container {
      border: none !important;
    }
    .rmdp-time-picker {
      padding: 0.187rem 0rem 0rem 0rem;
      justify-content: center;
      position: relative;
        &::after{
          position: absolute;
          content: " UTC";
          color: var(--cu-white-900);
          font-size: 0.65rem;
          margin-left: 8rem;
          margin-top: 2.5rem;
        }
      div {
        flex: unset;
        input {
        width: 3rem;
        background: transparent;
      }
    }
    }
  }
  .date-time-wrapper {
    .date-wrapper {
      width: 100%;
      flex-direction: column;
      align-items: start;
      @media screen and (max-width: 550px) {
     align-items: start;
     flex-direction: column;
      }
      .select-box-new {
        padding: 0.3rem 0.6rem;
        font-size: 0.625rem;
        color: var(--cu-black-10);
      }
    }
    .select-box--container {
      border-radius: 5rem;
    }
  }
  @media screen and (max-width: 550px) {
    .date-time-wrapper {
      flex-direction: column;
      gap: 0rem;
      .date-wrapper {
        .sub-title {
          //width: 2rem;
        }
        .select-box-new {
          text-align: center;
        }
      }
    }
    .select-box--container{
      justify-content: center;
    }
  }
  @media screen and (max-width: 550px) {
    &.create {
        .button{
            width: 100%;  
        }
    }
  }
  .body {
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    @media screen and (max-width:550px) {
      grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    }
}
.button-container-create-events-post {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  .add-btn, .cancel-btn {
    width: 40%;
    padding: 0.65rem 1rem;
    border-radius: 5rem;
    transition: all 0.3s;
    color: var(--cu-white-900);
    border: none;
    box-shadow: 0 0 0 1px var(--cu-black-100);
    &:hover {
      background: var(--dark-mode-300);
    }
  }
  .add-btn {
    background: var(--dark-mode-900);
  }
}
}
.share-social-pop{
  padding: 0.25rem 2.625rem 1.5rem 2.625rem !important
}
.create-edit-community {
  .button{
    @media screen and (max-width: 550px) {
      font-size: 0.75rem;
      font-weight: 400;
    } 
  }
  h2 {
    font-size: 1.375rem;
    font-weight: 600;
  }
  .section-title {
    font-size: 1rem;
    font-weight: 400;
    @media screen and (max-width: 550px) {
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
  .brd-btm {
    padding-bottom: 0.625rem;
    @media screen and (max-width: 550px) {
      border-bottom:none;
      padding-bottom: 0.5rem;
    }
  }
  label {
    font-size: 0.75rem;
    font-weight: 400;
    color: var(--cu-white-100);
  }
  :is(input, textarea)::placeholder {
    color: var(--cu-black-5);
  }
  input,
  textarea {
    background-color: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    color: var(--cu-white-200);
    font-size: 0.75rem;
    max-height: 7rem;
    padding: 0.425rem;
    border-radius: 4px;
  }
  textarea {
    max-height: 7rem;
  }

  .new-checkbox span {
    background: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    border-radius: 4px;
    color: var(--cu-black-10);
    padding: 0.2rem 0.625rem;
    font-weight: 500;
    font-size: 0.75rem;
    cursor: pointer;
    &.active {
      border-color: var(--cu-white-900);
    }
  }
  .button {
    background-color: var(--neutral-900);
    padding: 0.42857rem 0.71429rem;
    border-radius: 0.25rem;
    border: 1px solid var(--dark-mode-300);
    font-size: 0.75rem;
    font-weight: 400;
    display: flex;
    width: fit-content;
    height: fit-content;  
    @media screen and (max-width: 550px) {
      width: 100%;
    }
    &.save {
      background-color: var(--neutral-900);
      padding: 0.42857rem 0.71429rem;
      border-radius: 0.25rem;
      border: 1px solid var(--dark-mode-300);
      font-size: 0.75rem;
      font-weight: 400;
      display: flex;
      width: fit-content;
      height: fit-content;  
    }
    &.active {
      color: var(--cu-white-900);
      border-color: var(--cu-white-900);
    }
  }
  &.create {
    h3 {
      font-size: 1.375rem;
      font-weight: 600;
    }
    textarea {
      max-height: 33.5rem;
      resize: none;
      @media screen and (max-width: 550px) {
        max-height: 7.5rem;
      }
    }
    .new-checkbox span {
      color: var(--cu-white-900);
    }
    .sub-title{
      font-size: 0.75rem;
    }
  }
  @media screen and (max-width: 550px) {
    &.create {
        .button{
            width: 100%;  
        }
    }
  }
}

// Thumbnail related styles
.upload-thumbnail-image {
  background: var(--cu-black-700);
  border: 1px solid var(--cu-black-100);
  border-radius: 22px;
  overflow: hidden;
  // height: 8rem;
  aspect-ratio: 12/6 !important;
  // width: 100%;
  label {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .upload-icon {
    display: none;
    background-color: var(--cu-black-700);
    margin: auto;
  }
  .dummy-wrap{
    p{
      text-align: center;
      font-size: 0.75rem;
      color: var(--cu-black-10);
    }
    .image-uploaded{
      @media screen and (max-width: 550px) {
        height: 2.125rem;
        width: 2.125rem;
        }
    }
    &.long-banner{
.image-uploaded{
@media screen and (max-width: 550px) {
  height: 3.75rem;
  width: 3.75rem;
  }
}
    }
  }
  .image-uploaded {
    padding: 0px;
    margin: auto;
    overflow: hidden;
    object-fit: contain;
    height: 100%;
    &.actual-img{
      width: 100%;
      object-fit: initial;
    }
  }
  &.profile-img{
    width: 8.75rem;
    height: 8.75rem;
    border-radius: 5rem;
    overflow: hidden;
    @media screen and (max-width: 550px) {
      margin-right: auto;
      margin-left: initial;
    }
    .image-uploaded {
      max-height: 100%;
      object-fit: cover;
    }
  }
  &:hover {
    .upload-icon {
      display: inline;
    }
    .dummy-wrap,.image-uploaded {
      display: none;
    }
  }
  .upload-banner-image {
    background: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    border-radius: 4px;
    height: 8rem;
    label {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .upload-icon {
      display: none;
      background-color: var(--cu-black-700);
      margin: auto;
    }
    .dummy-wrap{
      p{
        text-align: center;
        font-size: 0.75rem;
        color: var(--cu-black-10);
      }
      .image-uploaded{
        @media screen and (max-width: 550px) {
          height: 2.125rem;
          width: 2.125rem;
          }
      }
      &.long-banner{
.image-uploaded{
  @media screen and (max-width: 550px) {
    height: 3.75rem;
    width: 3.75rem;
    }
}
      }
    }
    .image-uploaded {
      padding: 0px;
      margin: auto;
      overflow: hidden;
      object-fit: contain;
      max-height: 9.875rem;
      height: 100%;
      &.actual-img{
        width: 100%;
        object-fit: initial;
      }
    }
    &.profile-img{
      width: 8rem;
      height: 8rem;
      border-radius: 100%;
      overflow: hidden;
      @media screen and (max-width: 550px) {
        margin-right: auto;
        margin-left: initial;
      }
      .image-uploaded {
        max-height: 100%;
        object-fit: cover;
      }
    }
    &:hover {
      .upload-icon {
        display: inline;
      }
      .dummy-wrap,.image-uploaded {
        display: none;
      }
    }
  }
} 

.event-date-scheduler-box {
  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .event_radio-input {
    // background-color: var(--cu-grey-500);
    background-color: var(--cu-black-900); 
    border-radius: 5rem; 
    border: 1px solid var(--cu-white-900);
    padding: 0;
    margin: 0;
    height: 1.2rem;
    width: 1.2rem;
    &:checked {
      background-color: var(--cu-black-900); 
      border-color: var(--cu-white-900); 
    }
  }
  .event_radio-input.active {
    position: relative;
    &::before {
      content: "";
      padding: 0;
      margin: 0;
      position: absolute;
      height: 0.45rem;
      border-radius: 5rem; 
      width: 0.45rem;
      top: 50%;
      left: 50%;
      transform: translate(-51%, -48%);
      background-color: var(--cu-white-900); 
    }
  }
}