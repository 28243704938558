
  @media only screen and (min-width: 1281px) {
    .iframe-design{
        height: 36rem;
    }
  }

.image{
    width: 100%;
    height: 80dvh;
}

.h-35rm{
  height: 35rem;
}
