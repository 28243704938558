.tooltip_element {
    position: relative;
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        1% {
            opacity: 1;
        }
    }
    .tooltip {
        display: none;
    }
    &:hover {
        .tooltip {
            display: block;
            animation: fadeIn 0.2s ease;
        }
    }
}

.tooltip {
    position: absolute;
    padding: 0.5rem 0.75rem;
    font-size: 0.8rem;
    border-radius: 0.8rem;
    border: 1px solid var(--dark-mode-300);
    background: var(--Neutral-900, #0D0F11);
}
.tooltip-position {
    top: 120%;
    left: 50%;
    transform: translate(-0%, -0%);
    z-index: 999 !important;
}

// custom child position
.tooltip-bottom-position {
    top: 90%;
    left: 50%;
    transform: translate(-0%, -0%);
    z-index: 1;
}

.react-tooltip {
  border-radius: 0.75rem !important;
  border: 1px solid var(--dark-mode-300) !important;
  background-color: var(--Neutral-900, #0D0F11) !important;
  font-size: 0.75rem !important;
  margin-top: 4.5rem;
  .react-tooltip-arrow {
    display: none !important;
  }
}