.r-multi-dropdown-container  {
    position: relative;
    transition: all 0.4s;
    &:hover {
        .r-multi-dropdown {
            visibility: visible;
            // display: block;
        }
        .filter-icon-wrapper{
        .fill-color{
            svg{
                path{
                    fill: var(--cu-white-200);
                }
            }
        }
        }
    }
    // .icon {
    //     opacity: 0.6;
    // }
}
.r-multi-dropdown {
    visibility: hidden;
    position: absolute;
    z-index: 4;
    background-color: var(--dark-mode-900);
    border: 1px solid var(--cu-black-100);
    border-radius: 0.25rem;
    transition: all 0.4s;
    cursor: auto;
    padding: 0.5rem 0;
    height: auto;
    max-height: 30rem;
    width: 15rem;
    overflow: auto;
    &::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
      }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    .clear-box {
        text-align: end;
        button {
         color: var(--cu-black-5);
         font-size: 0.8125rem;
         padding: 0rem 0.8rem;
            &:hover {
                color: var(--cu-white-200);
                cursor: pointer;
            }
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;       
        li {
            text-align: start;
            color: var(--cu-black-5);
            font-size: 0.8125rem;
            &:hover {
                color: var(--cu-white-200);
                cursor: pointer;
            }
            label {
                cursor: pointer;
                padding: 0 0 0 1rem;
                margin: 0 0 0.375rem 0;
            }
        }
    }
    .ul-2 {
        padding: 0.5rem 0;
        button{
            padding: 0 0 0 1rem;
            font-size: 0.8125rem;
            display: flex;
            align-items: center;
            gap: 0.8rem;
            width: 100%;
        }
    }
}