.preferences {
    width: 100%;
    @media screen and (max-width: 550px) { 
        margin-top: 1.5rem;
    }
    .preference-options {
        display: flex;
        gap: .625rem;
        justify-content: flex-start;
        color: var(--cu-white-700);

        .option {
            text-align: left;
            font-size: .75rem;
            line-height: .875rem;
        }

        .option-select {
            font-size: .625rem;

            select {
                padding: 0.1875rem 0.2rem;
                color: var(--cu-white-100);
            }
        }


    }

    @media screen and (max-width: 550px) {
        .panel-frame {
            padding: 1.25rem .625rem;
        }

        .tabs-list {
            width: 100%;
        }

        .preference-options {
            &:first-child {
                align-items: flex-start;
            }

            .option-select {
                &.timer-option-select {
                    flex-direction: column;
                    align-items: flex-start;

                }

            }
        }
    }
}

.label-color {
    color: var(--cu-white-100);
    letter-spacing: 0.02rem;
}