.details-info {
  .name-text {
    color: var(--cu-white-550);
    font-size: 0.825rem;
    font-weight: 600;
  }
  .details-desc-short {
    color: var(--cu-white-550);
    font-size: 0.75rem;
    margin-right: auto;
    h5 {
      font-weight: 500;
    }
    p {
      font-weight: 400;
      word-break: break-all;
    }
  }
  .profile-social{
    h5{
        margin-right: auto;
    }
  .social-link-media{
    img{
      width: 100%;
      height: 100%;
      max-width: 1.25rem;
      max-height: 1.25rem;
    }
.twitter-x{
  width: 1rem;
  height: 1rem;
  margin-left: -0.5rem;
}
  }
}
}
