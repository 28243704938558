.community-details-overview{
  .posts-skeleton-wrap{
    margin-top: 0rem;
  }
  .tab-container{
    @media screen and (max-width: 550px) {
      padding: 0rem;
      margin: 0rem 1rem;
      border-bottom: 1px solid var(--cu-black-100);
    .tab-list{
      padding: 0rem;
      height: 30px;
      overflow-x: auto;
      box-sizing: border-box;
      width: calc(100% - 0.1875rem);
      -ms-overflow-style: none;
      scrollbar-width: none; 
      &::-webkit-scrollbar { 
        display: none;  
    }
    .tab-data{
      width: auto;
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: 0.03429rem;
      position: relative;
      display: flex;
      gap: 0.25rem;
    }
    }
    }

  }
}
.community-overview {
    @media screen and (max-width: 550px) {
        padding: 1.25rem 1rem 0.5rem 1rem;
        .announcement-event-wrapper{
          border: none;
          padding: 0rem;
          .create :is(input,textarea){
            padding: 0.625rem;
          }
        }
        .no-data{
          min-height: 8rem;
        }
      }
      .list-event{
        @media screen and (max-width: 550px) {
          gap: 0.5rem;
          padding-bottom: 4rem;
          height: 100%;
          justify-content: space-between;
        }
      }
      .expander-content{
        &.expanded{
          margin-top: 0rem;
        }
      .acco-child{
        padding-left: 3.65rem;
      }
    }
      .accordion-list{
        .brief-desc{
          padding-right: 2em;
        }
      }
}
.verify-collection-modal{
  .community-collection-main-form{
    height: 95%;
    .community-pro{
      height: 100%;
      justify-content: space-between;
      .create-edit-collection{
        &.curator-collection{
          height: 90%;
          max-height: 100%;
        }
      }
    }
  }
}