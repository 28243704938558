.frameit-tab {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .625rem;
    flex: 1;
    position: relative;
    overflow: auto;
-webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    height: 100%;
    .sub-tabs {
        display: flex;
        color: var(--cu-black-30);
        gap: 1rem;
        font-size: .625rem;
        padding: .1875rem;
        margin-bottom: .375rem;
        cursor: pointer;

        .active {
            color: var(--cu-white-900);
        }
    }

    .frame-display-ui {
        display: flex;
        flex-direction: column;
        padding: .625rem;
        border: 1px solid var(--cu-black-100);
        border-radius: .5625rem;
        flex: 1 1 25rem;
        max-width: 25rem;
        max-height: 19.188rem;
        overflow: scroll;
        gap: .625rem;

        .frame-title {
            text-align: left;
        }

        .frames {
            display: flex;
            gap: .625rem;
            flex-wrap: wrap;
        }

        .frame-display {
            &.selected {
                border: .0625rem solid var(--cu-white-900);
            }

            border: .0625rem solid var(--cu-black-100);
            border-radius: .25rem;
            width: 5.422rem;
            height: 5.563rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.selected {
                border: .0625rem solid var(--cu-white-900);
            }

            .frame-box {
                width: 100%;
                height: 100%;
                padding: 1.1875rem 1.210625rem .25rem;
            }

            p {
                margin-bottom: .3125rem;
                margin-top: auto;
                font-size: .4375rem;
                line-height: .75rem;
            }

            &.no-frame {
                background: var(--cu-black-700);

                .x {
                    padding: 1.172rem 0.75rem;

                    p {
                        font-size: 2rem;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 550px) {
        max-height: calc(100% - 2rem);
    }
}