.gallery-slider {
    position: relative;
    .slick-dots li.slick-active button:before {
        color: var(--cu-blue-300) !important;
    }
    .slick-dots {
        height: 2rem;
        overflow: hidden;
    }
    .slick-dots li button:before {
        color: var(--cu-blue-300) !important;

    }

    .slick-slider {
        width: 100%;
    }

    .slick-list {
        max-height: 21.21rem;

        @media screen and (max-width: 650px) {
            max-height: 16rem;
        }
    }
    .slick-arrow.slick-next, .slick-arrow.slick-prev{
        position: absolute;
        z-index: 2;
        background-color: rgba(0,0,0,0.8);
        border-radius: 50%;
        transition: all 0.3s;
        &:hover {
            transform: scale(1.4);
            background-color: rgba(0,0,0,1);
        }
    }
    .slick-arrow.slick-next{
        right: 2%;
    }
    .slick-arrow.slick-prev{
        left: 2%;
    }
}
.gallery-slider-1 {
    .slick-dots {
        width: 50%;
        margin: 0 25%;
    }
}
.gallery-slider-2 {
    .slick-dots {
        width: 70%;
        margin: 0 15%;
    }
}


.card-half {
    @media screen and (max-width: 650px) {
        width: 100%;
    }

}