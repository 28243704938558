.details-section {
    padding: 0.42857rem 0rem;
    justify-content: start;
    @media screen and (max-width: 550px){ 
        justify-content: start;
    }
    .details-item {
        display: flex;
        justify-content: center;
        gap: 0.3rem;
        font-size: 0.85rem;
        padding: 0 0.5rem;
        @media screen and (max-width: 550px){ 
            font-size: 0.675rem;
        } 
        .item-name {
            color: var(--cu-white-100);
        }
    }
    .blockchains{
        display: flex;
        align-items: center;
        .blockchain-image{
            width: 1.5rem;
            height: 1.5rem;
            margin-left: -0.25rem;
            &:first-child{
                z-index: 1;
                margin-left: 0rem;
            }
            &:nth-child(2){
                z-index: 2;   
            }
            &:nth-child(3){
                z-index: 3;   
            }
            &:nth-child(4){
                z-index: 4;   
            }
            &:nth-child(5){
                z-index: 5;   
            }
            &:nth-child(6){
                z-index: 6;   
            }
            &:nth-child(7){
                z-index: 7;   
            }
            &:nth-child(8){
                z-index: 8;   
            }
            &:nth-child(9){
                z-index: 9;   
            }
            &:nth-child(10){
                z-index: 10;   
            }
            &:nth-child(11){
                z-index: 11;   
            }
            &:nth-child(12){
                z-index: 12;   
            }
            &:nth-child(13){
                z-index: 13;   
            }
            &.remaining-count-box{
                position: relative;
                border-radius: 0.75rem;
                border: 1px solid var(--cu-black-50);
                overflow: hidden;
                .count-text{
                  position: absolute;
                  inset: 0;
                  background: var(--cu-grey-dim);
                  display: flex;
                  color: var(--cu-white-900);
                  justify-content: center;
                  align-items: center;
                  font-size:0.5rem;
                }
              }
        }
    }
    .border-right {
        border-right-width: 1px;
        border-right-color: var(--cu-black-100)
    }
}