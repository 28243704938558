.community-announcements {
  .accordion-list {
    margin-top: 0px;
    .accordion {
      padding-top: 0px;
      padding-bottom: 0.65rem;
    }
  }
  @keyframes comeToDisplay {
    0% {
      overflow-y: hidden;
      height: 0px;
      opacity: 0;
    }
    50% {
      overflow-y: hidden;
      opacity: 0.5;
      height: 50%;
    }
    100% {
      overflow-y: hidden;
      opacity: 1;
      height: 100%;
    }
  }
  .visibleSlowly {
    animation: comeToDisplay 0.5s ease-out;
    // height: 100%;
  }
  
}

