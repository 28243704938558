.customeTable{
    width:100%;
    table-layout: fixed;   
    top: 0;
    z-index: 40;
    th {
        padding: 1.25rem 0.6rem;
        text-align: left ;
        table-layout: fixed;   
        top: 0;
    }
    td{
        padding: 0.5rem 0.938rem;
        text-align: left !important;
        vertical-align:middle;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
} 
.tbl-content {
    // height:calc(100vh - 15rem);
    margin-top: 0px;
}
.tbl-header{
    border-bottom: 0.5px solid var(--cu-black-trans-250);
    position: sticky;
    top: 0rem;
    background: var(--neutral-900);
    z-index: 2;
    min-height: 50px;
}
.tbl-header-asset{
    border-bottom: 0.5px solid var(--cu-black-trans-250);
    position: sticky;
    top: 0rem;
    background: var(--neutral-900);
    z-index: 2;
    min-height: 50px;
}
.flip-icon {
    rotate: (180deg);
}