.banner-image {
  width: 100%;
  .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  h5 {
    color:var(--cu-grey-light)
  }
  h4{
    font-weight: 600;
  }
}
