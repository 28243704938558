.checkbox {
    display: flex;
    text-align: left;
    vertical-align: middle;
    align-items: center;

    .input-box {

        display: flex;
        align-items: flex-end;
        justify-content: center;

        img {
            height: 1rem;
            cursor: pointer;
        }
    }

    label {
        cursor: pointer;
        color: var(--cu-white-700);
        font-size: 0.75rem;
    }
    &.disabled{
        :is(img,label,svg) {
            cursor: no-drop;
    }
}
}
