.properties_container-main {
    label {
        margin: 0 0 0.5rem 0;
    }
    .properties_delete-box {
        .properties_delete-box-icon {
            transition: all 0.2s;
            opacity: 0.5;
            margin: 0 0 1rem 0;
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }
}