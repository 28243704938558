.input-slider {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none !important;
        width: 0.714rem;
        height: 0.714rem;
        background: var(--neutral-100);
        border: 2px solid var(--neutral-100);
        border-radius: 50%;
        cursor: pointer;
    }
    input[type="range"] {
        appearance: none !important;
        -webkit-appearance: none !important;
        background: var( --neutral-500);
        height: 1px;
    }
    .icon {
        width: auto;
        height: auto;
    }
}