
.reward-card {

    background: var(--cu-black-500);
    min-width: 15%;
    border-radius: 0.65rem;
    font-size: 14px;
    border: 1px solid var(--cu-black-100);
    font-weight: 600;
    color: var(--cu-white-550);
    @media screen and (max-width: 550px) { 
        width: 100%;
        font-size: 0.75rem;
        font-weight: 500;
    }
    &:hover, &.active {
        background: var(--cu-black-50);
    }
}
.grey-w-color{
    color: var(--cu-white-550) !important;
}
.transaction-img{
    @media screen and (max-width: 550px) { 
    img{
        width: 2rem;
        height: 2rem;
    }
}
}
.bal-count{
    @media screen and (max-width: 550px) {
        font-size: 0.625rem;
        font-weight: 400;
     }
}
.balance-text{
    font-size: 1.5rem !important;
    font-weight: 500;
    @media screen and (max-width: 550px){
        font-size: 0.875rem !important;
    }
}
.rewards-section {

    .sm-none {
        @media screen and (max-width: 550px) { 
            display: none;
        }
    }
    .rewards-select-filter {
        .select-box--container {
            min-width: 7rem;
        }
    }
    .pruchase-filter-items {
        left: unset;        
        min-width: -webkit-fill-available;
    }
    table {
        th, td {
            width: 15%;
            span {
                word-break: break-all;
            }
        }
    }
    .rewards-table-head {
        tr {
            border-bottom: 1px solid var(--cu-black-100);
        }
        th {
            padding: 0 1rem 1rem;
            color: var(--cu-black-10);
            font-weight: 600;
        }
    }
    .rewards-table-body {
        td {
            padding: 1rem;
        }
    }
}

.sm-block {
    display: none;
    @media screen and (max-width: 550px) { 
        display: block;
        margin-top: 0rem;
        .rewards-container-sm {
            .rewards-select-filter {
                .select-box--container {
                    min-width: 7rem;
                }
            }
            .pruchase-filter-items {
                left: unset;
                min-width: 7rem;
            }
            .rewards-item {
                padding: 1rem 0;
                border-bottom: 1px solid var(--cu-black-100);
                font-size: 0.625rem;
                &:first-child{
                    padding: 0rem 0rem 1rem 0rem;
                }
                &:last-child{
                    border-bottom: none;
                }
                .highlight-color{
                    color: var(--cu-blue-70);
                    font-weight: 600;
                }
                .sm-date {
                    color: var(--cu-black-10);
                }
                .font-normal{
                    font-weight: 400;
                }
                .item-desc {
                    font-size: 0.75rem;
                }
                .item-name { 
                    color: var(--cu-white-100);
                    font-weight: 600;
                }
                .item-right {
                    .sm-date {
                        color: var(--cu-black-10);
                    }
                }
            }
        }
    }
}
.token-icon{
    width: 1rem;
    height: 1rem;
    }
    .no-data-found{
        font-size: 0.75rem;
        color: var(--cu-black-10);
    }