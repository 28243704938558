.collection-skeleton {
    height: -moz-fit-content;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    grid-template-rows: max-content;
    &.sm-skelton-card {
        grid-template-columns: repeat(5, 1fr);
    }
    .react-loading-skeleton {
        aspect-ratio: 1;
    }
}
.other-collection-list {
    row-gap: 1rem;
    padding: 1rem;
    overflow: auto;
}

.my-colection-selected-collection {
    padding: 1rem 0rem;
    .nft-details {
        padding: 0rem 1rem;
    }
    .tab-data {
        margin: 0 1rem;
    }
}