.rounded-input {
    position: relative;

    img {
        position: absolute;
        width: 1.5rem;
        top: .375rem;
        left: .75rem;
    }  
    
    img:nth-child(3) {
        left: initial;
        right: 0.75rem;
        background-color: white;
        cursor: pointer;
        width: 1.2rem;
        top: 0.6rem;
    }
}

.input {
    background: var(--cu-white-900);
    border-radius: 1.25rem;
    padding: 0.75rem 0.75rem 0.75rem 2.875rem;
    gap: 0.625rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: 0.02em;
    width: 100%;
    color: var(--cu-black-10);
    &.passwordfield {
        padding-right: 2rem;
    }
}

.error {
    border: 0.0625rem solid var(--cu-red-500);
}
.btnIcon { 
    &.btnIcon-eye {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100%;
        border: none;
        height: 1rem;
        outline: none;
        width: 1rem;
        position: absolute;
        right: 0.5rem;
    }
    &.btnIcon-eye-on {
        background-image: url(~/src/Assets/Images/eye-visible.png);
    }
    
    &.btnIcon-eye-off {
        background-image: url(~/src/Assets/Images/eye-hide.png);
        // opacity: 0.2;
    }
}
