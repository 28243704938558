.details-container {
    padding: 1rem;
&.no-banner{
    padding-top: 0rem;
    // overflow: hidden;
    &.nft-gallery-full-card{
        padding-bottom: 14rem;
    }
}
    @media screen and (max-width:550px) {
        padding: 1rem;
    }
    &.reduce-pad{
        @media screen and (max-width:550px) {
            padding: 0rem 1rem 1rem 1rem;
        }   
    }
}

.head-title {
    display: flex;
    // padding: 0.71429rem 0rem;
    align-items: center;
    gap: 1.42857rem;
    justify-content: space-between;

    &.border-title {
        @media screen and (max-width:550px) {
            padding: 0.25rem 0.71429rem 0.5rem 0rem;
            border-bottom: 1px solid var(--cu-black-100);
        }
    }

    &.hidden {
        display: none;
    }

    span {
        font-weight: 500;
    }

    .title {
        font-size: 1rem;
        letter-spacing: 0.03429rem;
        position: relative;
        &.w-auto{
            width: auto;
        }

        &.active {
            @media screen and (max-width:550px) {
                &::after {
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 2rem;
                    content: "";
                    width: 100%;
                    height: 1px;
                    background: var(--cu-white-550);
                }
            }
        }
    }

    .link {
        cursor: pointer;
        color: var(--cu-black-5);
        font-size: 0.75rem;
        letter-spacing: 0.02571rem;
    }
}

.discover-gallery-preview-section {
    .preview-card-image {
        overflow: unset;
    }

    .nfts-selected {
        max-height: 100%;
    }

    .slider-user {
        height: 100%;
    }

    .description-tab {
        max-height: 100%;
    }
}

.discover-list-container {
.home-grid{
    @media screen and (max-width:550px) {
        margin-top: 0rem;
    }  
}
    // overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 0;
    }
}

.sm-pt-0 {
    @media screen and (max-width:550px) {
        padding-top: 0rem;
    }
}

.discover-module{
    .home-grid{
        margin-top: 0rem;
    }
    @media screen and (max-width:550px) {
        min-height: 94vh;
    }  
}