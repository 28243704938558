.modern-gallery-preview-container {
    transition: all 1s ease-in-out;
    &.multi-view {
        .modern-gallery-multi-loader.show {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
    &.mono-view {
        .modern-gallery-mono-loader.show {
            position: absolute;
            width: 100%;
            height: 100%;
        }
        .nft-frame-container {
            .nftframe {
                padding-top: 0; 
            }
        }
        .mono-slider-section {
            padding: var(--image-size-padding);
            transition: all 1s;
        }
        &.ful-scr {
            .mordern-gallery-slider {
                &.slide {
                    .slick-slide {
                        &.slick-active {
                            .mono-slider-section {
                                opacity: 1;
                                transform: var(--mono-slide-transform);
                            }
                        }
                        .mono-slider-section {
                            transition: all 5s;
                        }
                    }
                }
                &.fade {
                    .slick-slide {
                        .mono-slider-section {
                            transform: var(--mono-slide-transform);
                        }
                    }
                }
                
                
            }
            .nftframe {
                height: 100vh !important;
                width: 100vw !important;
                .nft-img-sec {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            } 
        }
        
    }
    &.multi-view {
        .mordern-gallery-slider {
            transform: var(--multi-slide-transform);
        }
    }
}