.tab-box{
    border-radius: 0.25rem;
    color: var(--cu-white-100);
    border:1px solid var(--cu-black-100);
    font-size: 0.75rem;
    padding: 0.3rem 0.75rem;
    line-height: 1.21429rem;
    cursor: pointer;
    transition: all 0.3s;
    &:is(&.active,:hover){
        color: var(--cu-white-200);
        background: var(--cu-black-500); 
        border:1px solid var(--cu-black-5);  
    }
}