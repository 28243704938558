.collection-container {
    @media screen and (max-width: 550px) {
       height: 100%;
       justify-content: space-between;
       padding-bottom: 3rem;
      }  
 .skelton-card{
    @media screen and (max-width: 550px) {
    padding: 0rem 0rem 1rem 0rem;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    }
    }
        .no-data-text{
            font-size: 0.875rem;
            max-width: 32rem;
            min-height: 13.75rem;
            margin: 0 auto;
            color: var(--cu-black-10);
            @media screen and (max-width: 550px) {
                font-size: 0.75rem;
                color: var(--cu-black-10);
              }  
        }  
    

        .collection-header{
            font-size: 1.25rem;
            font-weight: 500;
            @media screen and (max-width: 550px) {
                font-size: 0.875rem;
              }  
            span{
                font-size: 1.25rem;
                font-weight: 500;
                color: var(--cu-grey-350);
                @media screen and (max-width: 550px) {
                    font-size: 0.875rem;
                  }
            }
        }
        .community-filter {
            span {
                padding: 0.14286rem 0.71429rem;
                border-radius: 0.28571rem;
                // border: 1px solid var(--cu-black-400);
                background: var(--Neutral-800, #191D23);
                font-size: 0.85714rem;
                @media screen and (max-width: 550px) {
                    font-size: 0.75rem;
                    padding: 2px 0.625rem;
                    font-weight: 400;
                  }
                &.active {
                    position: relative;
                    background: var(--Dark-Mode-300, #30333A);
                    &::before {
                        content: "";
                        position: absolute;
                        inset: 0;
                        border-radius: 0.25rem;
                        padding: 0.5px;
                        // background: linear-gradient(45deg, #00C5FF, #4745ED);
                        // mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                        // -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                        // -webkit-mask-composite: xor;
                        // mask-composite: exclude; 
                        // border: 1px solid var(--cu-white-550);
                        
                        z-index: 9;
                    }
                }
            }
        
        .add-button {
            background-color: var(--neutral-200);
            border: 1px solid var(--cu-black-100);
            padding: 0.375rem 0.625rem;
            border-radius: 0.25rem;
            font-size: 0.875rem;
            font-weight: 500;
            &:hover {
                background: var(--Dark-Mode-300, #30333A) !important;
            }
        }
    }
    .header{
        .add-button {
            background-color: var(--neutral-200);
            border: 1px solid var(--cu-black-100);
            padding: 0.375rem 0.625rem;
            border-radius: 0.25rem;
            font-size: 0.875rem;
            font-weight: 500;
            &:hover {
                background: var(--Dark-Mode-300, #30333A) !important;
            }
        }
        &.community-title-sec{
            .add-button {
                @media screen and (max-width: 550px) {
                    width: fit-content;
                    padding: 0.25rem 0.5rem;
                  }  
            } 
        }  
    }
    .body {
        grid-template-columns: repeat(5,1fr);
        @media screen and (max-width: 550px) {
            grid-template-columns: repeat(2, 1fr);
          }  
        .image-item {
            border-radius: 0.28571rem;
            // max-height: 25rem;
            //aspect-ratio: 1;
            .image-container{
                height: var(--collection-card-height);
            }
            .card-drop {
                border-radius: 0.25rem;
                border: 1px solid var(--cu-black-900);
                background: var(--cu-black-100);
            }
            .drop-name, .image-text {
                color: var(--cu-white-900);
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 0.85714rem;
                letter-spacing: 0.02rem;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                // -webkit-line-clamp: 1;
            }
            .image-container {
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 0.25rem;
                opacity: 1;
                background-position: center;
                transition: background-image 1s ease;
                &:hover{
                   // opacity: 0.5;
                   transition: 1s ease;
                }
                img.card-image {
                    border-radius: 0px 0px 4px 4px;
                    margin: auto;
                }
            }
            .image-text {
                font-weight: 500;
                @media screen and (max-width: 550px) {
                    font-size: 0.5rem;
                    color: var(--cu-white-550);
                  }  
            }
            .collection-text {
                font-weight: 500;
                font-size: .875rem;
                @media screen and (max-width: 550px) {
                    font-size: 0.5rem;
                    color: var(--cu-white-550);
                  }  
            }
            .name-text {
                font-weight: 500;
                font-size: .875rem;
                color: var(--neutral-500, #64748B);
                @media screen and (max-width: 550px) {
                    font-size: 0.5rem;
                    color: var(--cu-white-550);
                  }  
            }
        }
    }
}
.community-panel {
    padding: 1rem 1rem 1rem 1rem;
    @media screen and (max-width: 550px) {
        padding: 1rem;
      }
}
.add-button{
&.bottom-full-button{
    background-color: transparent;
    padding: 0.25rem 0.5rem;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 400;
    color: var(--cu-white-550);
    &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
}
}
.community-header{
    font-size: 1.25rem;
    font-weight: 500;
    @media screen and (max-width: 550px) {
        font-size: 0.875rem;
      }  
    span{
        font-size: 1.25rem;
        font-weight: 500;
        color: var(--cu-grey-350);
        @media screen and (max-width: 550px) {
            font-size: 0.875rem;
          }
    }
}
.creator-collection-container {
    min-height: 20rem;
}