//NOTE: this file used for creator subscription as well
.subscriptionmaindiv {
  width: 100%;
  gap: 1rem;
  @media screen and (max-width: 550px) {
    margin-top: 1rem;
  }
  .about-monet{
    color: var(--cu-black-5);
    font-size: 0.8rem;
    font-weight: 400;
    text-align: start;
    .monet_link {
      color: #14a0fa;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .learn-more{
    color: var(--cu-black-5);
    font-size: 0.625rem;
    font-weight: 400;
  }
  .title {
    width: 100%;
    h3 {
      color: var(--cu-white-200);
      text-align: center;
      font-family: ZenKaku;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.03rem;
    }
  }
  .subscription-tab {
    justify-content: center;
    width: 100%;
    gap: 1.25rem;
    @media screen and (max-width: 550px) {
      gap: 0.75rem;
      margin-bottom: 0rem;
    }
    button {
      color: var(--neutral-500, #64748b);
      font-family: ZenKaku;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.063rem;
      @media screen and (max-width: 550px) {
        font-size: 0.75rem;
      }
    }
    button.active {
      color: var(--neutral-50, var(--cu-white-200));
    }
  }
  .Subscriptionlist {
    display: flex;
    gap: 1.25rem;
    justify-content: center;
    width: 100%;
    padding: 2rem;
    padding-bottom: 0rem;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    @media screen and (max-width: 550px) {
      margin-top: 0rem;
    }
  }
  .monetprogress {
    width: 100%;
    margin-top: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: center;
    @media screen and (max-width: 550px) {
      gap: 1.5rem;
      margin-bottom: 0rem;
    }
    .title {
      color: var(--neutral-50, var(--cu-white-200));
      font-family: ZenKaku;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem; /* 114.286% */
      letter-spacing: 00.018rem;
      @media screen and (max-width: 550px) {
        font-size: 0.75rem;
        font-weight: 400;
      }
    }
    .progress-div {
      display: flex;
      width: 60rem;
      align-items: center;
      gap: 0.313rem;
      .totalMonetdiv {
        width: 100% ;
        position: relative;
        margin: -4.8px 0 0 0;
        .totalMonetLabel {
          position: absolute;
          text-align: right;
          top: -1.125rem;
          width: auto !important;
          @media screen and (max-width: 767px) {
            top: -0.7rem;
          }
          @media screen and (max-width: 550px) {
            top: -0.875rem;
          }
        }
        progress {
          width: 100%;
          margin-top: 0.438rem;
          position: relative;
          background-color: #14a0fa;
          height: 0.438rem;
        }
      }
      label {
        color: #14a0fa;
        font-family: ZenKaku;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem; /* 133.333% */
        letter-spacing: 0.015rem;
      }
    }
  }
}