table.nftListing {
    font-size: .75rem;
    font-weight: 100;

    th,
    td {
        padding: 0.6rem;
        text-align: left;

        &:nth-child(2) {
            min-width: 10rem;
        }
    }

    thead {
        th {
            .th {
                display: flex;
            }
        }
    }

    tbody {
        td {
            padding: 0.6rem;

            img {
                width: 4.063rem;
                // max-height: 2.93rem;
            }
        }
    }
}

table {
    width: 100%;

    thead.collection-table-head {
        z-index: 2;
        position: sticky;
        top: -1px;
        background: var(--cu-black-700);


        th {
            padding: 0.6rem;
        }

    }


}

thead.gallery-head {
    background: var(--cu-black-900);

    th {
        padding: 0.6rem 0.6rem 0.6rem 0rem;
    }
}

.collection-table {
    width: 100%;
}

.col-art-full {
    &-img {
        width: 2rem;
        height: 2rem !important;
    }
}

.collection-table-image {
    height: 2rem !important;
    width: 2rem !important;
}