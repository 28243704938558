.form {
    text-align: left;

    &-input-error {
        border: 0.0625rem var(--cu-red-500) solid !important;
    }

    &-error {
        font-size: x-small;
        color: var(--cu-red-500);
        font-style: italic;
    }

    .error-box {
        // min-height: 1rem;
        text-align: left;


        p {
            line-height: .8rem;
        }
    }
}