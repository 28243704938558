.layout-design-header {
    .counter {
        span {
            border-right: 1px solid var(--cu-black-100)
        }
    }

}

.layout-design-body {
    .layout-section{
        grid-template-columns: repeat(5, 1fr);
        .layout-image {
            width: 4.375rem;
            height: 2.5rem;
            .skeltonMainClass {
                top: -1px;
            }
        }
        .active{    
            border: 0.0625rem solid var(--cu-white-900);
     }
     }
}