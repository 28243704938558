.tooltip-container {
    position: relative;
    .tooltip-message-wrapper {
        position: absolute;
        top: 1.83rem;
        right: 50%;
        transform: translate(50%, 0);
        display: none;
        .tooltip-message {
            pointer-events: none;
            min-width: 9.5rem;
            white-space: pre-wrap;
            height: auto;
            background: var(--cu-black-700);
            position: absolute;
            right: 50%;
            transform: translate(50%, 10%);
            font-size: 0.75rem;
            padding: 0.625rem;
            border-radius: 0.25rem;
            transition: all 0.3s ease;
            position: relative;
            color: var(--cu-white-900);
            text-align: left;

            &::before {
                content: "";
                position: absolute;
                pointer-events: none;
                right: 50%;
                bottom: 98%;
                transform: translate(50%, 0%);
                transition: all 0.3s ease;
                border-style: solid;
                border-width: 27px 6px 6px;
                border-color: transparent transparent var(--cu-black-700) transparent;
            }
        }

   
    }
    &:hover {
        .tooltip-message-wrapper {
            display: inline-block;
            z-index: 105;
        }
    }
}