.discover-details-profile {
    .profile-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .profile-img {
            &.background-banner{
                background-size: 100% 100%;
                width: 100%;
                height: 6.25rem;
                background-repeat: no-repeat;
                background-position: center;
                padding: 1rem;
                border-radius: 0.25rem 0.25rem 0rem 0rem;
                @media screen and (max-width:550px){
                    border-radius: 0.25rem;
                }
                position: relative;
                // &::after{
                //     position: absolute;
                //     width: 100%;
                //     height: 100%;
                //     content: "";
                //     top: 0;
                //     bottom: 0;
                //     left: 0;
                //     right: 0;
                //     background: rgba(0, 0, 0,0.6);
                // }
            }
        }
        .pro-img{
            border-radius: 8.07143rem;
            background: var(--cu-black-500);
        }
        img {
            width: 8.07143rem;
            height: 8.07143rem;
            
            @media screen and (max-width:550px){
                width: 3.75rem;
                height: 3.75rem; 
                margin: 0.625rem 0rem;
            }
        }
        span {
            color: var(--cu-blue-70);
            font-size: 1.15rem;
            font-weight: 600;
            line-height: 1rem; 
            @media screen and (max-width:550px){
                font-size: 0.875rem;
                color: var(--cu-white-550);
                text-align: center;
            }
        }
        .details-wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .details-desc-short{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            margin: 1rem 0rem 0.375rem 0rem;
            p{
                font-size: 0.75rem;
                color: var(--cu-white-550);
            }
            img{
                width: 1rem;
                height: 1rem;
                margin: 0rem;
            }
        }
        .profile-social {
            img {
                width: 1.14286rem;
                height: 1.14286rem;
            }
        }       
        .follow-section{
            span{
                @media screen and (max-width:550px){
                    font-size: 0.625rem;
                    font-weight: 400;
                } 
            }
        }     
    }
    
    .panel-data-container {
        .panel-title {
            span {
                color: var(--cu-blue-70);
                font-size: 1.15rem;
                font-weight: 600;
            }
            img {
                width: 1.83336rem;
                height: 1.57143rem;
            }
        }
        .panel-image {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .description {
        text-align: left;
        word-break: break-word;
        span {
            color: var(--cu-white-200);
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.57143rem;
        }
    }
    .follow-btn-container {
        border-radius: 0.375rem;
        border: 1px solid var(--cu-black-100);
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1.21429rem; 
        padding: 0.375rem 0.875rem;
        cursor: pointer;
        color: var(--cu-white-200);
        transition: all 0.3s;
        &:hover {
            color: var(--cu-white-900);
            background: var(--dark-mode-300);
        }
        @media screen and (max-width:550px){
            padding: 0.25rem 1rem;
        } 
    }
}
.z-1{
    z-index: 1;
}
.z-3{
    z-index: 3;
}