.gallery-container  {
    @media screen and (max-width: 550px) {
        height: 100%;
        justify-content: space-between;
        padding-bottom: 3rem;
       }  
    .body {

        @media screen and (max-width: 550px) {
            display: block;
          }

        .image-container {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            overflow: hidden;
            // height: 100%;
            width: 100%;
            background-position: top center;
            @media screen and (max-width: 550px) {
                height: 13rem;
              }
        }
    }
    .home-grid{
        margin-top: 0rem;
    }
}