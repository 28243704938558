.release-label {
    font-weight: 600;
    font-size: 1rem;
    flex-basis: 9rem;
    color: var(--cu-blue-lite);
    text-align: initial;
}

.release-value {
    font-weight: 400;
    font-size: 1rem;
    text-align: initial;
    color: var(--cu-white-200);
}