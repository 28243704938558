.filter-select {
    position: relative;
    height: 100%;
    border-radius: 0.375rem;
    display: grid;
    align-items: center;
    cursor: pointer;

    &.close-popup .filter-selection {
        visibility: hidden;
        opacity: 0;

    }

    &.open-popup .filter-selection {
        visibility: visible;
        opacity: 1;
    }
    

    .filter-selection {
        position: absolute;
        left: 0;
        top: 2.5rem;
        width: 100%;
        z-index: 15;
        background: var(--dark-mode-900);
        border: 1px solid var(--dark-mode-300);
        border-radius: 0.3125rem;
        transition: .5s ease;
        min-width: 18rem;
        max-height: 30rem;
        overflow-y: auto;
        padding-bottom: 0.5rem;
        &::-webkit-scrollbar {
            display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
          }
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          
        @media screen and (max-width:550px){ 
            position: fixed;
            left: 0;
            right: 0;
            max-height: 13rem;
            top: auto;
            bottom: 2.875rem;
            // top: calc(100% - 32%);
        }
        ul {
            padding: .375rem 0;
            color: var(--cu-white-200);
            font-size: .8125rem;
            text-align: left;
            li {
                align-items: center;
                color: var(--cu-black-5);
                cursor: pointer;
                display: flex;
                font-size: 0.8125rem;
                justify-content: start;
                text-align: left;
                padding-top: 0.5rem;
                padding-left: 1rem;
                text-transform: capitalize;
                &:hover{
                    color: var(--cu-white-200);
                }
                .item-arrow {
                    &.open-arraow {
                        transform: rotate(180deg);
                    }
                }
                .item-name {
                    max-width: 8rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        ul.item-first-level {
            > li {
                padding-left: 0;
            }            
        }
    }
    
}

.filter-selection {
    position: absolute;
    top: 100%;
    background: var(--dark-mode-900);
    border-radius: 0.25rem;
    right: 0rem;
    font-size: .8125rem;
    z-index: 101;
    color: var(--cu-white-200);
    text-align: left;
    overflow: hidden;

    input {
        width: 0;
        height: 0;
        position: absolute;
    }

    li {
        cursor: pointer;
        white-space: nowrap;
        line-height: 1.5rem;

        .link {
            align-self: end;
            margin-right: 10px;
        }
    }

    .res-elements {
        display: none;
    }

    &-closed {
        .drop-down-items {
            border: 0;
            animation: fadeOut .1s;
        }
    }
    
        .drop-down-items {
            &.item-first-level {
                padding-right: 0.5rem;
            }
            margin-top: 0 ;
            li {
                
              
                font-size: 0.875rem;
                color: var(--cu-black-5);
                .hover-icon {
                    display: none;
                }
                .non-hover-icon {
                    display: block;
                }
                .drop-icon {
                    width: 1rem;
                } 
                &.selected {
                    color: var(--cu-white-200);
                    .hover-icon {
                        display: block;
                    }
                    .non-hover-icon {
                        display: none;
                    }
                }
            }
        
    }

    ul {
        z-index: 100;
    }

    @media screen and (max-width: 768px) {
        li {
            font-size: .625rem;
        }
    }
}
.imghgt {
    height: 100%;
    width: 100%;

    @media screen and (max-width: 550px) {
        height: 100%;
        width: 100%;
    }
}