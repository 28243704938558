.card {
    width: 100%;
    position: relative;
    display: flex;
    cursor: pointer;
    .profile-image-container {
        height: 8.75rem;
        background: var(--cu-black-700);
        border: 1px solid var(--cu-black-100);
        border-radius: 4px;
        width: 100%;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &:hover {
            .upload-btn { 
                opacity: 1;
            }

        }
        .upload-btn {
            position: absolute;
            opacity: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media screen and (max-width: 450px) {
            left: auto;
            transform: translate(65%, -50%);
            }
            span {                
                padding: 1rem 1.14rem;
                border: 1px solid var(--cu-black-100);
                border-radius: 6px;
                background: var(--cu-black-700);
                color:var(--cu-white-100);
            }
            &:hover{
                span{
                    color:var(--cu-white-200); 
                }
            }
        }
    }
    .profile-pic-box {
        position: absolute;
        left: 3rem;
        top: 1rem;
        z-index: 99 !important;
        img{
            height: 6.75rem !important;
            width: 6.75rem !important;
        }
        &:hover .img-top {
            display: inline;
        }
        
    }
    .profile-name {
        display: none;
        font-size: .75rem;
        font-weight: 600;
        line-height: 1rem;
        margin-top: .6875rem;

        img {
            width: 1rem;
            height: 1rem;
        }
    }

    .user-avatar,
    .img-top {
        border: 0.0625rem solid var(--cu-white-900);
        border-radius: 50%;
        padding: .7813rem;
        width: 3.75rem;
        height: 3.75rem;
    }

    .profile-pic {
        padding: 0;
        overflow: hidden;
        object-fit: cover;
        background-image: linear-gradient(180deg, #00C5FF 0%, #4745ED 100%);
    }

    .img-top {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        background-color: var(--cu-black-500);
    }

    .hidden-input {
        display: none;
    }

    @media screen and (max-width: 550px) {
        width: 100%;

        &:hover .img-top {
            display: none;
        }

        .profile-pic-box {
            display: flex;
            justify-content: center;
        }

        .profile-name {
            display: flex;
            justify-content: center;
            gap: .625rem;
        }
    }
}