.nftframeskel {
  .react-loading-skeleton {
    line-height: normal;
    border-radius: 0;
  }
}
.skelton-loading-nft-frame {
  opacity: 0;
}
.data-type {
  opacity: 0;
  color: var(--cu-white-700);
  font-size: 0.875rem;
  position: absolute;
  transition: all 0.5s ease;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--cu-black-410);
  .media-file-type{
    scale: 0.7;
    transition: all 0.5s ease;
  }
}
