.preview-gallery-collections-container {
    padding: 0rem;
}

.preview-gallery-collections-container {
    @media screen and (max-width: 550px) {
        position: relative;
    }




    .collection-box {
        display: grid;
        padding: 0 1rem;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
        grid-template-rows: max-content;
        gap: 0.75rem;
        @media screen and (max-width: 550px) {
            grid-template-columns: repeat(2, 1fr);
        }

        .collection-detail {
            padding: 0rem 0.42857rem;

            .round-img img {
                border-radius: 1.72rem;
                width: 1.72rem;
                height: 1.72rem;
            }
        }
    }

    .collector_explore-collection {
        .category-list-loader {
            position: absolute;
            width: 100%;
            padding-right: 1rem;
        }

        .infinite-scroll-component {
            padding: 0 1rem;
            padding-bottom: 4rem;
        }

        .collection-sq-card.image-item {
            max-height: 100%;
        }
    }
    .skeltonMainClass {
        .details-skeleton  {
            display: flex !important;
        }
    }
}