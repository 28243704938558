.nft-frame-shop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: var(--cu-black-700);
    // box-shadow: inset 0.125rem 0.125rem .25rem var(--cu-black-900);
    height: 100%;
    width: 100%;
    &.hidden{
    display: none;
}
    .frame-content {
        padding: 1rem 0rem 1rem 1rem;
        display: flex;
        gap: .625rem;
        height: 100%;
        width: 100%;
        overflow-y: scroll;

        .overlay {
            display: none;
        }
    }

    .baner-lg {
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        .filter {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            left: 1rem;
            width: 90%;
            transform: translateY(100);
            z-index: -1;
            transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
        }

        .filter.active {
            transform: translateY(0%);
            visibility: visible;
            opacity: 1;
            z-index: 11;
            transition-delay: 0s, 0s, 0.3s;
            transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

        }

        .filter-display {
            visibility: hidden;
        }

        .name-bar .profile-nav-toggle {
            display: block;
        }

        .baner-icon-box {
            display: flex;
            margin-left: auto;
            align-items: center;
            gap: .625rem;
        }
    }

    @media screen and (max-width: 550px) {
        .baner-lg {
            display: none;
        }

        .frame-content {
            padding: 1rem 1rem 1.5rem 1rem;
            overflow: hidden;
            min-height: calc(100vh - 5rem);

            .overlay {
                display: block;
                width: 100vw;
                height: 100%;
                z-index: 10;
                position: absolute;
            }
        }

        .filter {
            right: auto;
            left: 0;
            top: 0;
            background-color: var(--cu-black-500);
            padding: .625rem;
            width: 50vw;


            @media screen and (max-width: 550px) {
                position: fixed;
                top: 6rem;
                bottom: 0;                
            }
            &.show-filter {
                @media screen and (max-width: 550px) {
                    visibility: visible;
                    opacity: 1;
                    z-index: 3;
                }
            }
        }
    }
}