.frame-selection-sm {
    display: none;

    @media screen and (max-width: 550px) {
        display: block;

        &.active {
            flex: 1 1 40%;

            &.nft-b-select {
                flex: 1 1 80%;
            }
        }
    }

    .selection-head {
        display: flex;
        justify-content: space-between;
        padding: 0.6rem 0rem;

        .search {
            input {
                background-color: inherit !important;
                border-image: var(--cu-white-900) !important;
            }
        }
    }

    .selection-item-head {
        gap: 1rem;
        align-items: center;
    }

    .selection-continer {
        height: 100%;
        padding: 0.4rem;

        .selection-frames {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
            gap: 0.6rem;
            overflow-y: auto;
            max-height: 27vh;
            height: 100%;
            &.active {
                max-height: 25rem;

                @media screen and (max-width: 375px) {
                    max-height: 18rem;
                }
            }

            .frame-sm-card {
                border: 1px solid var(--cu-black-100);
                background: var(--cu-black-500);
                border-radius: 0.28rem;
                cursor: pointer;
                height: 100%;
                display: flex;
                justify-content: center;
                position: relative;
                padding: 0.5rem;
                @media screen and (max-width: 550px) {
                    padding: 1px;
                    aspect-ratio: 1;
                }
                &.active {
                  border:  0.0625rem solid var(--cu-blue-60);
                    // position: relative;
                    // &::before {
                    //     content: "";
                    //     position: absolute;
                    //     inset: 0;
                    //     border-radius: 0.25rem;
                    //     padding: 1px;
                    //     background: linear-gradient(45deg, #00C5FF, #4745ED);
                    //     -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                    //     -webkit-mask-composite: xor;
                    //     mask-composite: exclude; 
                    //     z-index: 9;
                    //   }
                }
                @media screen and (max-width: 550px) {
                    .card-layout-container{
                    .gallery-images-position {
                        height: 100%;
                        .nft-gallery-card-image{
                            height: 100%; 
                        }
                    }  
                    }
                }
            }
        }
    }
}

.frame-title {
    @media screen and (max-width: 550px) {
        margin-bottom: 0.4rem;
    }

    .sm-block {
        display: none;

        @media screen and (max-width: 550px) {
            display: block;
        }
    }

    @media screen and (max-width: 550px) {
        span {
            font-size: 0.875rem;
            font-weight: 500;
        }
    }
}

.frame-selection-section {
    .card-layout-container{
        align-items: baseline;
        .gallery-images-position {
            height: 100%;
        }
    }
}
.nftdrop-options {
    .gallery-preview-image img{
        max-height: 6rem;
    }
    @media screen and (max-width: 550px) {
        overflow-y: scroll;
        flex: 1 1 60%;
        padding: 0.5rem;

        &.nft-b-select {
            flex: 1 1 20%;
        }
    }


}


.gallery-frame-size {
    background: var(--cu-black-700);
    height: 17rem;
    padding: 0.6rem;
    border-bottom: 1px solid var(--cu-black-100);
    .frame-size {
        max-height: 15rem !important;
    }

    .gallery-images {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        object-fit: contain;
    }

    .frameless-image {
        max-height: 15rem;
        padding: 0.5rem;
        background: var(--cu-black-700);
        border-radius: 0.25rem;
    }

    .nft-frame-background {
        border-radius: 0.25rem;
        background: var(--cu-black-700);
    }

    .loader-image {
        min-height: 9rem;
    }
}

.frames-listing-gallery {
    padding: 0 0.625rem 0.625rem 0.625rem;
    border-radius: 0.5625rem;
    max-height: 100vh;
    overflow: auto;
-webkit-overflow-scrolling: touch;


    @media screen and (max-width: 550px) {
        padding: 0rem 1rem 1rem 1rem;
    }


}

.gallery-view {
    .nft-frame-background {
        border-radius: 0.25rem;
        background: var(--cu-black-700);
        padding: 0.6rem;
    }
}

.frame-border {
    border-bottom: 1px solid var(--cu-black-100);
}


.frame-bord {
    border: 0.0625rem solid var(--cu-black-100);
    border-radius: 0.25rem;
    overflow: hidden;
    aspect-ratio: 1;
    width: 100%;
    &.border-none{
        border: 1px solid transparent;
    }
}

.frame-popup {
    .popup-template {
        bottom: 1rem;
        top: 1rem;
        left: 1.5rem;
        right: 1.5rem;
        max-width: calc(100vw - 5rem);
    }

    .popup-overlay {
        backdrop-filter: grayscale(1);
        background: transparent;
    }

    .popup-template-body {
        background: var(--cu-black-500);
        border: 1px solid var(--cu-black-100);
        border-radius: 0.25rem;
    }

    .frameless-image {
        margin: auto;
    }
}

.gallery-switch {
    @media screen and (max-width: 550px) {
        display: none;
    }
}

.settings-btn {
    padding: 0.2rem 0.64rem;
    border-radius: 4px;
}

.settings-sm {
    display: none;

    @media screen and (max-width: 550px) {
        display: flex;
    }
}

.frame-popup {
    .frame-size {
        max-height: 41vh !important;
    }
}

.search-icon-close {
    display: none;
}

.search-icon-search {
    display: none;
}

.search {
    width: 100%;
    line-height: 1.0625rem;
    &.nav-search{
        width: auto;
    }
    @media screen and (max-width: 550px) {
        position: absolute;
        left: 0;
        right: 0;
        height: 2.5rem;
        background: var(--cu-black-500);
        top: auto;
        bottom: 2.875rem;


        input {
            height: 100%;
            background-color: #23252a !important;
            padding: 0rem 2.5rem 0rem 2.5rem !important;
        }
    }


}

.sort-txt,
.search-icon {
    display: none;
}

.search-icon-search {
    position: absolute;
    top: 0px;
    left: 0px;
    display: unset;

    @media screen and (max-width: 550px) {
        bottom: 0.7rem;
        left: 1rem;
        top: unset;
    }
}

.search-icon-close {
    position: absolute;
    top: 0.4375rem;
    right: -2px;
    display: unset;

    @media screen and (max-width: 550px) {
        bottom: 0.5rem;
        right: 1rem;
        top: unset;
    }
}

.frame-border {
    @media screen and (max-width: 550px) {

        button {
            font-size: 0.6875rem;

        }
    }
}

.nft-select li {
    padding: 0.3rem 2rem !important;
}

.frame-display {
    &.selected {
        border: .0625rem solid white;
    }
    &.bg-gray{
        background: var(--cu-black-500);
    }
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .frame-box {
        width: 100%;
        height: 100%;
        display: grid;
    }

    p {
        margin-top: auto;
        font-size: .4375rem;

    }
    .no-frame-text{
        border-radius: 0.5rem;
        border: 1px solid var(--cu-black-70);
        p{
            color: var(--cu-white);
            font-size: 0.75rem;
            margin: 0 auto;
        }
    }
    &.no-frame {
        background: var(--cu-black-700);

        .x {
            padding: 1.172rem 0.75rem;

            p {
                font-size: 2rem;
            }


        }
    }
}
.no-frame-display {
    height: 6rem;
}
.buy-wrapper{
    padding-bottom: 1.25rem;
    border-bottom: 1px solid var(--cu-black-100);
    align-items: center;
}
.side-scroller{
    overflow-y: auto;
    overflow-x: hidden;
    .warning-wrap {
        gap: 1rem;
        padding: 1.5rem;
    }
    &::-webkit-scrollbar{
        width: 0.1875rem;
}
}
