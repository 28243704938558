.event-grid{
    display: grid;
 
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    @media screen and (max-width: 1616px) {
        grid-template-columns: repeat(3, 1fr) !important;
    }
    gap: 1rem;
    // max-height: 100%; 
    @media screen and (max-width: 550px) {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}