.community-container {
  
  .asset-loading {
    .skelton-card {
      @media screen and (max-width: 550px) {
        padding: 0rem;
      }
    }
  }
  .no-data-text {
    font-size: 0.875rem;
    max-width: 32rem;
    min-height: 13.75rem;
    margin: 0 auto;
    color: var(--cu-black-10);
    @media screen and (max-width: 550px) {
      font-size: 0.75rem;
      color: var(--cu-black-10);
    }
  }
  .header {
    
    .add-button {
      background-color: var(--neutral-200);
      border: 1px solid var(--cu-black-100);
      padding: 0.375rem 0.625rem;
      border-radius: 0.25rem;
      font-size: 0.875rem;
      font-weight: 500;
      &:hover {
        background: var(--Dark-Mode-300, #30333A) !important;
    }
    }
  }
  .community-title-sec {
    &.header {
      .add-button {
        @media screen and (max-width: 550px) {
          width: fit-content;
          padding: 0.25rem 0.5rem;
        }
        &:hover {
          background: var(--Dark-Mode-300, #30333A) !important;
      }
      }
    }
  }
  .body {
    gap: 0.5rem;
    // grid-template-columns: repeat(auto-fill, minmax(14.5rem, 1fr));
    @media screen and (max-width: 550px) {
      gap: 0.5rem;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(44%, 1fr));
    }
    .image-item {
      @media screen and (max-width: 550px) {
        aspect-ratio: auto;
      }
      .image-container {
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        transition: background-image 1s ease;
        &:hover{
           transition: 1s ease;
        }
        @media screen and (max-width: 550px) {
          width: 100%;
          border-radius: 2px;
          height: 6.25rem;
          // height: var(--collection-card-height);
        }
        img {
          border-radius: 0px 0px 4px 4px;
          margin: auto;
        }
      }
      .image-text {
        font-weight: 500;
        white-space: break-spaces;
        max-width: 9.75rem;
        @media screen and (max-width:550px) {
            font-size: 0.625rem; 
        }
      }
    }
  }
}
.community-panel {
  padding: 1rem 2rem 1rem 1rem;
  @media screen and (max-width: 550px) {
    padding: 1rem;
  }
}
.community-content-section {
  margin-top: 0.234rem;
  @media screen and (max-width: 550px) { 
    margin-top: 1.5rem;
  }
}

.creator-profile-banner {
  background-color: var(--cu-black-500);
  .mobile-head {
      font-size: 0.75rem;
      font-weight: 0 !important;
      color: var(--cu-black-10);
      padding-left: 0.5rem;
      .nav-link {
          color: var(--cu-white-450);
      }
      .linkActive {
          color: var(--cu-white-550);
      }
  }
  @media screen and (max-width: 550px) { 
      overflow: auto;
      white-space: nowrap;
      position: fixed;
      width: 100%;
      z-index: 4;
      top: 50px;
      left: 0;
      right: 0;
      -ms-overflow-style: none;
      scrollbar-width: none; 
      &::-webkit-scrollbar { 
        display: none;  
    }
  }
}

.list-container {
  grid-template-rows: max-content;
  display: grid;
  gap: 0.625rem;
  row-gap: 1rem;
  width: 100%;
  // height: 100%;
  &::-webkit-scrollbar {
      width: 0;
  }
  .card {
      // display: flex;
      // flex-direction: column;
      // gap: 0.71429rem;
      height: 16rem;
      @media screen and (max-width: 550px){
          height: fit-content;
      }
      cursor: pointer;
      .card-image-container {
          aspect-ratio: 16/10;
          border-radius: 0.42857rem;
          @media screen and (max-width: 550px){
              height: 6.25rem;
          }
          .card-image {
              height: 100%;
              border-radius: 0.42857rem;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              display: flex;
              align-items: center;
              justify-content: center;
          }
          .screenshot img {
              min-height: 9rem;
              margin: 0 auto;
              object-fit: contain;
              height: 100%;
              @media screen and (max-width: 550px){
                  min-height: auto;
              }
          }
      }
      .card-detail-container {
          padding: 0rem 0.51429rem;
          @media screen and (max-width: 550px){
              padding: 0rem 0.71429rem 0rem 0.124rem;
          }
          .card-details {
              display: flex;
              align-items: center;
              gap: 0.71429rem;
              align-self: stretch;
              .detail-profile {
                  flex: 1 0 2.14286rem;
                  @media screen and (max-width: 550px){
                      flex: 1 0 1.25rem;
                  }
                  img {
                      border-radius: 2.14286rem;
                      border: 1px solid var(--cu-black-100);
                      width: 2.14286rem;
                      height: 2.14286rem;
                      object-fit: cover;
                      @media screen and (max-width: 550px){
                          width: 1.25rem;
                      height: 1.25rem;
                      }
                  }
              }
              .detail-name span{
                  font-size: .875rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 0.85714rem; /* 85.714% */
                  letter-spacing: 0.02rem;
                  display: flex;
                  @media screen and (max-width: 550px){
                      font-size: 0.625rem;
                  }
              }
          }
      }
  }
}