.add-nft-content {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    overflow-y: scroll;
    max-height: 80dvh;

    .add-nft-content_form-box {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .add-nft-content_field-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;

        label {
            font-size: 0.875rem;
            font-weight: 400;
            color: var(--cu-white-100);
            text-align: start;
        }

        input,
        textarea {
            background-color: var(--cu-black-700);
            border: 1px solid var(--cu-black-100);
            color: var(--cu-white-200);
            font-size: 0.875rem;
            border-radius: 12px;
            padding: 0.5rem 0.8rem 
        }
        input {
            border-radius: 5rem;
            padding: 0.6rem 0.8rem;
        }
    }

    .add-nft-content_file-upload-container {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        transition: all 0.3s;

        .upload-banner-image {
            position: relative;
            background: var(--cu-black-700);
            border: 1px solid var(--cu-black-100);
            border-radius: 12px;
            width: 25rem;
            height: 25rem;

            label {
                display: flex;
                height: 100%;
                width: 100%;
                justify-content: center;
                align-items: center;
            }

            .upload-icon {
                display: none;
                background-color: var(--cu-black-700);
                margin: auto;
            }

            .image-uploaded {
                padding: 0px;
                margin: auto;
                overflow: hidden;
                object-fit: contain;
                max-height: 4.25rem;
                border-radius: 12px;
                height: 100%;

                &.actual-img {
                    width: 100%;
                    object-fit: initial;
                }
            }

            &.profile-img {
                transition: all 0.3s ease-in-out;

                .dummy-wrap {
                    display: inline-block;
                    transition: 0.4s 0.2s ease-in-out;
                    opacity: 1;
                    transform: scale(1);
                }

                .upload-icon {
                    position: absolute;
                    inset: 0;
                    transform: scale(0.4);
                    opacity: 0;
                    transition: 0.4s 0.2s ease-in-out;
                    display: inline-block;
                }

                .image-uploaded.actual-img {
                    object-fit: cover;
                    width: 100%;
                    max-height: 100%;
                }

                .select-image-button {
                    width: 0;
                    padding: 0.425rem 0.625rem;
                    border-radius: 0.5rem;
                    border: 1px solid var(--cu-black-100);
                    background: var(--cu-black-700);
                    font-size: 0.85rem;
                    color: var(--cu-white-200);
                    aspect-ratio: 1 / 0.03;
                    z-index: 1;
                }

                &:hover {
                    .upload-icon {
                        transform: scale(1);
                        opacity: 1;
                        width: 8.5rem;
                    }

                    .dummy-wrap {
                        opacity: 0;
                        transform: scale(0);
                    }

                    .image-uploaded {
                        display: inline-block;
                        opacity: 0.6;
                    }
                }
            }
        }

    }
    .info-label {
        font-size: 0.775rem !important;
    }

    .add-nft-actions {
        width:100%;         
        gap: 1rem;   
        display: flex;

        button {
            flex: 1;
        }
    }
    .cancel-button,.save-button {
        border: 0.0625rem solid var(--dark-mode-300);
        color: var(--cu-white);
        border-radius: 1.25rem;
        font-size: 0.875rem;
        padding: 0.625rem;        
    }    

    .drop-files {
        width: 100%;
        height: 15rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px dashed var(--cu-black-10);;
        border-radius: 10px;
    }
}
