html,
body,
canvas,
#root,
.rootdiv {
  width: 100%;  
  height: 100%;
  margin: 0;
  padding: 0;  
}

.rootdiv, .rootdiv canvas {
  z-index: 0;
  position: relative;
  pointer-events: none;
}

.rootdiv .content {
  width: 1000px;
  height: 1000px;
  background: transparent;
  border-radius: 3px;
  overflow: none;
  border: 0px;
  padding: 0;
}

.debugoverlay {
  position: absolute;
  z-index: 999;
  left: 10px;
  top: 10px;
  white-space: pre-wrap;
}

.loadinglogo {
  width: 100%;
  height:100%;
  background: black;
  opacity: 0;
  transition: opacity 1.5s;  
  position: absolute;
  top: 0;
  z-index:1;
  pointer-events: none;
}

.loadinglogo.visible {
  opacity: 1;
}

.loadinglogo img {
  width: 80%;
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modern-gallery-preview-container .caption-container {  
  width: 200px;
  padding: 0.5rem;
  gap: 0.4rem;
}



.modern-gallery-preview-container .caption-container span.nft-name, .modern-gallery-preview-container .caption-container .created-name {
  font-size: 14px !important;  
  padding-right: 0.1rem;
}