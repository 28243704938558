.curate-collections{
    .tab-container{
        padding-bottom: 0.5rem;
    }
    &.wallet-connected{
        .tbl-header{
            top: -1rem;
            .customeTable th {
                padding: 0rem 0.6rem 1.25rem 0.6rem;
            }
        }
    }
}
.my-collection-tile-view {
    .skeltonMainClass {
        top: 2rem;
    }
}