.reset-pass {
    display: grid;
    justify-content: center;
    margin: 5rem auto;


    .reset-pass-button {
        height: 2.5rem;
    }



    .reset-pass {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 0.625rem;
        line-height: 0.75rem;
        /* identical to box height, or 120% */

        letter-spacing: 0.02em;

        /* Shades/White */

        color: var(--cu-white-900);
    }
}