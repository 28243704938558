.register {
    display: grid;

    form {
        max-width: 15.75rem;
        width: 100%;
        padding: 1.875rem 0rem 1rem 0rem;
    }
    .terms-policy {
        font-size: 1rem;
        font-weight: 400;
        line-height: normal;
        span {
            color: var(--cu-white-900);
            span {
                color: var(--cu-blue-70);
            }
        }
    }
    .iframe {
        width: 75vh;
        height: 80vh;
    }
    .back-btn {
        background-color: transparent;
        border: 1px solid var(--cu-black-500);
    }
    .not-avail-icon {
        filter: brightness(0.5) sepia(1) saturate(10000%);
    }
    .register-pop-term {
        .pop-content {
            overflow-y: hidden;
        }
    }
    .info-container {
        .info-icon {
            position: absolute;
            right: -1.7rem;
            top: -1.7rem;
        }        
        .info-body {
            display: none;
        }
        &:hover {
            .info-body {
                display: block;
                display: block;
                position: absolute;
                font-size: 0.625rem;
                background: var(--cu-black-500);
                padding: 1rem;
                right: 0;
                top: 1rem;
                z-index: 12;
            }
        }
    }
    .login-link {
        font-size: 1rem;
        span {
            color: var(--cu-blue-70);
        }
    }
    @media screen and (max-width: 550px) {

        form {
            padding: 1.875rem 1rem 1rem;
        }
    }

    .validator-positon {
        position: absolute;
        right: -7.5rem;
        top: .8rem;
    }     
    .validator-positon2 {
        position: absolute;
        right: -11.7rem;
        top: -1.7rem;
    }   
    .validator-positon3 {
        position: absolute;
        right: -6.0rem;
        top: -1.8rem;
    } 
}