.gallery-selection {
  display: flex;
  gap: 1rem;
  
  .option {
    position: relative; 
    border-radius: 0.25rem;
    cursor: pointer;
    // border: 1px solid var(--dark-mode-300, #30333A);
    overflow: hidden;
    
    .mono {
      background: var(--cu-black-100);
    }
    
    .img {
      width: 100%;
      aspect-ratio: 16/9;
      border-radius: 0rem;
      background-size: cover;
      @media screen and (max-width: 550px) { 
        height: 3.625rem;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        
        &.object-cover {
          object-fit: cover;
        }
      }
    }
    
    &:is(.active, :hover) {
      
      
      
      &::before {
        content: ""; 
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('/Assets/Icons/play-hover.svg'); 
        background-repeat: no-repeat;
        background-size: 2.5rem;
        background-position: center;
        background-color: var(--cu-black-trans-650); 
        z-index: 1;
      }
    }
    
    p {
      color: var(--cu-white-900);
      font-size: 0.75rem;
      background: var(--cu-black-700);
    }
  }
}
.sidebar-section{
  .gallery-selection {
    .img {
      @media screen and (max-width: 550px) { 
        height: 3.625rem;
      }
    }
  }
}
.icons-overlay {
  display: flex;
  position: absolute;
  top: .625rem;
  right: 0;
  &.hidden{
    display: none;
  }
}

.open-btn{
  display: flex;
  height: 1.625rem;
  padding: 0.25rem 0.625rem 0.35rem  0.625rem;
  width: 5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.25rem;
border: 1px solid var(--Dark-Mode-300, #30333A);

color: #FFF;
font-size: .635rem;
font-style: normal;
font-weight: 400;
line-height: normal;
&:hover{
  background: var(--Neutral-800, #191D23);
}
}

.icons-overlay-edit-delete {
display: flex;
gap: 1;
cursor: pointer;
}