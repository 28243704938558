.display-header {  
  font-size: 3rem;  
  margin: auto;
  margin-top: 6rem;
}
.banner-sub-image-wrapper{
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  .content{
    justify-content: center;
    align-items: center;
    padding: 0rem 4rem;
    gap: 2rem;

  }
}
.full-width-banner{
  .content{
    align-items: start;
    justify-content: center;
    max-width: 25rem;
    margin-left: 6rem;
    h1{
      text-align: left;
    }
  }
}
.anywall-content {  
  font-size: 2.5rem;  
  margin: auto;
  margin-top: 4rem;
  width:fit-content;
  padding:4rem;
  border:1px solid white;
  background-image: url("https://uploads-ssl.webflow.com/64d081dc6c344d903666a3f9/64dc78c01b62dc3a96d6dc9d_Anywall%20hero.png");  
  height: 24rem;
  background-position: center;  
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.display-actions {  
  display: flex;
  justify-content: space-around;
  width:50%;
  margin:auto;
}

.display-page-button {
  background-color: var(--neutral-900);
  padding: 0.42857rem 0.71429rem;
  border-radius: 0.25rem;
  border: 1px solid var(--dark-mode-300);
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  align-items: center;
    &:hover {
      background: var(--Dark-Mode-300, #30333A) !important;
  }
}

.category-container {
  display: flex;
  width: 100%;
  gap: 1rem;
  background-color: var(--cu-black-400);
  padding: 0.125rem 0.375rem;
  align-items: center; 
  text-align: left;
  font-size: 0.75rem;
  color: var(--cu-white-100);
  border-radius: 0.125rem; 
}

.display-learnmore {
  position: absolute;
  bottom: 1.25rem;
  left: 49%;
  transform: translateX(-50%);
  background: var(--neutral-900);
  width: 25rem;
  border-radius: 1rem;
  border: 1px solid var(--Dark-Mode-300, #30333A);
  padding: 0.625rem 0.625rem;
  font-size: 0.875rem;
  color: var(--Neutral-50, var(--cu-white-200));
  z-index: 3;
  &:hover {
    background: var(--Dark-Mode-300, #30333A);
}
}
.display-grid{
  grid-template-columns: repeat(3, 1fr);
    grid-template-rows: max-content;
    row-gap: 1rem;
}
.display-list{
  .home-grid{
    margin-top: 0rem;
  }
  .no-data{
    min-height: 18.3rem;
    padding-top: 6rem;
  }
}
.see-all-text {
  cursor: pointer;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: .675rem;
  color: var(--cu-black-5);
}

.display-banner-wrapper{
  .add-display-btn-wrapper{
    max-width: 21rem;
    width: 100%;
    .warning-toolkit{
      top: 2.5rem;
      min-width: 29rem;
    }
  }
.display-banner{
  img{
    width: auto;
    height: auto;
    margin: 0 auto;
  }
}
.btn-dark {
  color: var(--cu-white-200);
  font-size: 0.875rem;
  padding: 0.875rem;
  border-radius: 1.25rem;
  max-width: 21rem;
}
a{
  width: 100%;
  max-width: 21rem;
}
}