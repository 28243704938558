.forgot-pass {
    justify-content: center;
    margin: 0rem auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 0.75rem;
    /* identical to box height, or 120% */

    letter-spacing: 0.02em;

    /* Shades/White */

    color: var(--cu-white-900);

    .forgot-pass-button {
        height: 2.5rem;
    }

    .auth-round {
        input {
            border-radius: 0.9rem;
        }
    }


    .label {
        margin-top: 2.6875rem;
    }

    @media screen and (max-width: 1025px) {
        form {
            padding-top: 1.375rem;
        }
    }

    @media screen and (max-width: 550px) {
        .label {
            margin-top: 2.25rem;
        }
    }
}