.en-large-container {
    height: calc(100% - var(--collection-enlarge-layout-height));
    padding-left: 9rem;
    .active-card {
        background: #000;
        height: 100%;
        flex: 1;
        transition: all 3s cubic-bezier(0.2, 0, 0.2, 0) !important;
        &.in-active {
            height: calc(100vh - 9.5rem);
            @media screen and (max-width: 550px) { 
                height: calc(100vh - 11.5rem);
            }
            .nftListing-image {
                // max-height: 79.5vh;
                height: 100%;
                @media screen and (max-width: 550px) { 
                    max-height: 45vh;
                    height: 100vh;
                }
            }
        }
        .nftListing-image {
            height: 100%;
            @media screen and (max-width: 550px) { 
                max-height: 40vh;
                height: 100vh;
            }
        }
        .enlarge-card {
            background: unset;
            border: unset;
            &:hover {
                background-color: unset;
            }
        }
        .enlarge-nft-img {
            min-height: 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            .selected-nft-cards-collection {
                max-height: 18rem;
            }
        }
    }
    .frame-option-container {
        position: absolute;
        padding: 0.625rem;
        top: .2rem;
        left: -9rem;
        height: calc(100vh - 6.95rem);
        border-radius: 10px;
        visibility: hidden;
        opacity: 0;    
        transition: visibility 0s linear 0.5s, opacity 0.5s linear;
        z-index: 12;
        border: 1px solid var(--dark-mode-300);
        background: var(--dark-mode-900);
        min-width: 7.4rem;
        &.active {
            visibility: visible;
            opacity: 1;            
            transition-delay: 0s;
            
        }
        .no-frame-option {
            padding: 0 0.625rem;
            button {
                display: flex;
                padding: 6px 10px;
                border-radius: 8px;
                border: 1px solid var(--dark-mode-300);
                background: #0D0F11;
                color: var(--neutral-500);
                font-size: 0.75rem;            
            }
            .inactive {
                button {
                    border: 1px solid var(--dark-mode-300);
                    background: none;
                    color: blue;
                    color: #FFF;
                    font-size: 0.75rem;
                }
            }
            .apply {
                button {
                display: flex;
                padding: 6px 10px;
                border-radius: 8px;
                background: #0D0F11;
                color: #FFF;
                font-size: 0.75rem; 
            }
            }
        }
    }
    .frame-slider-container {
        max-height: calc(100% - 10rem);
        overflow-y: auto;
        height: 100%;
        img.nftListing-image {
            max-height: 10vh;
        }
        .frame-container {
            background: #000;
            border-radius: 0.25rem;
            display: flex;
            flex-direction: column;
            border-radius: 4px;
            width: 6rem;
            height: 6rem;
            &.frameApplied {
                border: unset;
            }
            .enlarge-frame {
                div:first-child {
                    height: 100%;
                }
                &.active {
                    position: relative;
                    // padding: 0.2rem;
                    border: 0.0625rem solid var(--cu-blue-70);
                }
            }
        }
        .enlarge-frame {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            .nftListing-image {
                max-height: 100%;
                height: 100%;
            }
            .enlarge-frame-img {
                height: 100%;
            }
        }
    }
    .enlarge-apply-btn {
        padding: 0 1rem;
    }
    .slider-arrow {
        position: relative;
        z-index: 2;
        .arrow-btn {
            position: absolute;
            &.prev {
                left: 0;
            }
            &.next {
                right: 0;
            }
            top: 3rem;
            @media screen and (max-width: 550px) {  
                top: 2.2rem;                
            }
        }
    }
    .frame-asset-card {
        max-height:9.8rem;
        border: 1px solid var(--dark-mode-300);
        background: var(--dark-mode-900);
        border-radius: 10px;
        position: absolute;
        bottom: 1rem;
        right: 2.15rem;
        padding:0 0.5rem;
        overflow: scroll;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        transform: translateY(calc(100% - 2.15rem));
        overflow: hidden;
        justify-content: center;
        z-index: 1;
        transition: all 3s cubic-bezier(0.2, 0, 0.2, 0) !important;
        width: calc(100% - 12rem);
        .expand-icon img{
            transform: rotate(180deg);
        }
        &.active {
            transform: translateY(0);
            height: max-content;
            .expand-icon  img{
                transform: rotate(0deg);
            }
        }
        .asset-slider-container{
            padding: 1.25rem 0.625rem 1.25rem 0.625rem;
            grid-template-columns: repeat(9, minmax(0, 1fr));
            display: grid;
            gap: 1rem;
            @media screen and (max-width: 550px) { 
                grid-template-columns: repeat(4, minmax(0, 1fr));
                padding: 0 2rem;
            }
            .asset-slide-items {
                aspect-ratio: 1;
                width: 100%;
            }
            .enlarge-card {
                background: unset;
                .enlargeskelton {
                    min-height: unset;
                }
            }
        }
        
    }
    .slick-list {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        .slick-track {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
        }
        .enlarge-card {
            background: #000;
            .enlarge-nft-img {
                max-height: 6.875rem;
                width: 100% !important;
                .selected-nft-cards-collection {
                    max-height: 6.875rem;
                    height: 6.875rem;
                }
            }
        }
    }
    
}