.activities {
    .event-skeleton-wrap {
        // .details-skeleton {
        // .skeleton-wrapper{
        //     height: 25rem;
        //     aspect-ratio: unset;
        // }}

    }

    .posts-skeleton-wrap {
        height: calc(100dvh - 11rem);
        overflow-y: auto;
    }

    .events-list-wrapper {
        height: calc(100dvh - 10rem);
    }

    .announcements-listing {
        height: calc(100dvh - 18rem);
    }

    .communities-section-wrapper {
        width: 25.1875rem;
        padding: 1rem 0rem 1rem 1rem;
        border-radius: 0.75rem;
        background: var(--cu-black-500);

        .details-skeleton {
            grid-template-columns: repeat(1, 1fr);

            .skeleton-wrapper {
                aspect-ratio: unset;
            }
        }

        .communities-section {
            height: calc(100dvh - 9rem);
            overflow-y: auto;
            padding-right: 1rem;

            // &:hover {
            //     overflow-y: auto;
            //     padding-right: 0.75rem;
            // }

            .community-list {
                &:first-child {
                    .img-round-box {

                        border: 1px solid var(--cu-black-30);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: auto;
                            height: auto;
                            object-fit: unset;
                        }
                    }
                }
            }
        }
    }
}

.see-all {
    cursor: pointer;
    color: var(--cu-black-5);
    font-size: 0.75rem;
    letter-spacing: 0.02571rem;
}