.floating-create-gallery{
    background: var(--dark-mode-900);
    border-radius: 2rem;
    padding: 1rem;
    z-index: -1;
    border: 1px solid var(--cu-black-100);
    &:hover{
        background: var(--cu-black-50);
        svg{
            path{
                fill:var(--cu-white-900);
            }
        }
    }
    &.disabled{
        cursor: no-drop;
    }
}