.listing-collection{
    .skeltonMainClass{
        position: static;
    }
    .count-span{
        padding: 0rem 0rem 0rem 0.25rem !important;
    }

    .add-dropdown {
        position: absolute;
        margin-top:2rem;
        z-index:2;
        background: black;
        padding: 1rem;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        cursor: pointer;
    }
}