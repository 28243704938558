.page-listing-community {
  &.home-grid {
    &.list-container {
      .categories {
        .detail-name {
          display: inline-block;
          width: 100% !important;
          line-height: unset !important;
          font-size: 0.875rem !important;
          color: var(--cu-black-5);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }


}
.community-grid {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content;
  row-gap: 1rem;
  margin-top: 1rem;
}
