.content-sec {
    display: flex;
    flex-direction: column;
    padding: 0 0 0rem 0;
    // border-radius: 11px;
    // background: var(--neutral-900);
    @media screen and (max-width: 550px) {
        border-radius: 0rem;
    }
    border-radius: .625rem;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    &.border-r-1 {
        border-radius: .25rem;
        overflow: hidden;
    }

    &.borderless {
        border: 0;
        
    }

    &.transparent {
        @media screen and (max-width: 550px) {
            border: 0;
            background: transparent;
            padding: 0;
        }
    }

}