.gs_collection_list-container {
    @media screen and (max-width: 550px) {
        position: relative;
    }

    .body {
        grid-template-columns: repeat(auto-fill, minmax(13.75rem, 1fr));
        grid-template-columns: repeat (auto-fill, minmax(13.75rem, 1fr));
        gap: 1.25rem;

        // margin-top: 2rem;   
        @media screen and (max-width: 550px) {
            margin-top: 0rem;
            margin-bottom: 1rem;
        }

        .image-item {
            @media screen and (max-width: 550px) {
                width: 100%;
            }
        }
    }
}

.collection-container {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: max-content;

    @media screen and (max-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
    }

    .collection-detail {
        padding: 0rem 0.42857rem;

        .round-img img {
            border-radius: 1.72rem;
            width: 1.72rem;
            height: 1.72rem;
        }
    }
}

.gs_explore-collection {
    .category-list-loader {
        position: absolute;
        width: 100%;
        padding-right: 1rem;
    }

    .infinite-scroll-component {
        padding: 0 1rem;
        padding-bottom: 4rem;
    }

    .collection-sq-card.image-item {
        max-height: 100%;
    }

    .skeltonMainClass {
        .body {
            grid-template-columns: repeat(5, 1fr);

            .react-loading-skeleton {
                aspect-ratio: 1;
            }
        }
    }
}