.collection_results-container {
    .results-wrapper {
        display: flex;
        flex-direction: column;
        max-height: 26.5rem;
        overflow-y: auto;
        padding: 0 1rem 1rem 1rem;
        gap: 1rem;
    }

    .collection_results-see-more {
        padding: 0.6rem 0 1rem 0;
        button {
            font-size: 0.875rem;
            transition: all 0.3s;
            &:hover {
                transform: scale(1.05);
            }
        }
    }
    .collection_no-results-found {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--cu-white-100);
        font-size: 0.75rem;
    }

    .collection_results-details-loader {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 0.2rem 0.4rem;
        gap: 1rem;
        min-height: 40px;
        overflow: hidden;
    }
    
    .collection_results-details-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 0.2rem 0.4rem;
        transition: all 0.3s;

        &:hover {
            background: var(--cu-black-50);
        }

        .details_column-one,
        .details_column-two {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;

            .card-icon-box {
                img {
                    width: 20px;
                }
            }

            .card-piece-image-box {
                img {
                    border-radius: 5px;
                }
            }

            .card-piece-name-box,
            .card-piece-category-box,
            .card-piece-count-box {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                text-align: start;
            }

            .card-piece-name-box {
                h2 {
                    font-size: 0.95rem;
                }

                h3 {
                    font-size: 0.85rem;
                }
            }

            .card-piece-category-box {
                h2 {
                    padding: 0.2rem 0.4rem;
                    color: var(--cu-white-100);
                    background: var(--cu-black-400);
                    font-size: 0.75rem;
                    border-radius: 3px;
                }
            }

            .card-piece-count-box {
                h3 {
                    color: var(--cu-white-100);
                    font-size: 0.825rem;
                }

                h2 {
                    font-size: 0.875rem;
                }
            }
        }
    }
}