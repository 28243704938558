.tabs {
    font-size: .625rem;
    line-height: .75rem;
    width: 100%;

    button {
        background: transparent;
        width: 100%;
        font-size: 0.875rem;
        color: var(--neutral-500);
        padding: 0.375rem 0.75rem 0.625rem 0.75rem;
    }
}

.tab-selected {
    button {
        border-bottom: 0.0625rem solid var(--cu-black-5);
        width: 100%;
        color: var(--cu-white-200);
    }

}

.select-tabs {
    button {
        color: var(--cu-blue-70) !important;
        text-decoration: underline;
        text-underline-offset: 0.25rem;
    }
}