.list-container {
    grid-template-rows: max-content;
    display: grid;
    gap: 0.625rem;
    row-gap: 1rem;
    width: 100%;
    // height: 100%;
    &::-webkit-scrollbar {
        width: 0;
    }
    .card {
        // display: flex;
        // flex-direction: column;
        // gap: 0.71429rem;
        .no-hoverd-details{
            &:hover{
              opacity: 0.5;
            }
          }
        min-height: 16rem;
        height: 100%;
        @media screen and (max-width: 550px){
            height: fit-content;
        }
        cursor: pointer;
        .card-image-container {
            aspect-ratio: 16/10;
            border-radius: 0.42857rem;
            opacity: 1;
            &:hover{
                // opacity: 0.5;
            }
            @media screen and (max-width: 550px){
                height: 6.25rem;
            }
            .card-image {
                height: 100%;
                border-radius: 0.42857rem;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.5s ease-in-out;
            }
            .screenshot {
                img {
                min-height: 9rem;
                margin: 0 auto;
                object-fit: contain;
                height: 100%;
                @media screen and (max-width: 550px){
                    min-height: auto;
                }
            }
        }
        }
        .card-detail-container {
            // padding: 0rem 0.71429rem;
            @media screen and (max-width: 550px){
                padding: 0rem 0.71429rem 0rem 0.124rem;
            }
            .card-details {
                display: flex;
                align-items: start;
                gap: 0.71429rem;
                justify-content: start;
                width: 100%;
                padding: .625rem .0rem;
                .has-categories {                    
                    .categories .detail-name {
                        color: var(--cu-white-100);
                        font-size:50%;
                        width: inherit; 
                        line-height: normal;                   
                    }
                    .categories .detail-name span {
                        margin: 0 0.2rem;
                        line-height: normal;  
                        color: var(--neutral-500, #64748B) 
                    }

                    .categories .detail-name:first-child span:first-child {
                        margin-left: 0;
                        line-height: normal;  
                        color: var(--neutral-500, #64748B)  
                    }
                }
                .detail-profile {
                    flex: 1 0 2.14286rem;
                    @media screen and (max-width: 550px){
                        flex: 1 0 1.25rem;
                    }
                    img {
                        border-radius: 2.14286rem;
                        border: 1px solid var(--cu-black-100);
                        width: 1.875rem;
                        height: 1.875rem;
                        object-fit: cover;
                        @media screen and (max-width: 550px){
                            width: 1.25rem;
                        height: 1.25rem;
                        }
                    }
                }
                .detail-name {
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    // line-height: 0.85714rem; /* 85.714% */
                    letter-spacing: 0.02rem;
                    display: flex;
                    width: 100%;
                    @media screen and (max-width: 550px){
                        font-size: 0.625rem;
                    }
                }
            }
        }
    }
}