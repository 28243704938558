.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #000000B2;
}
.edit-selection {
    .edit-button {
        border-color:var(--cu-blue-60);
    }
}