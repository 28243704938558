.mono-preview {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    .ful-scr {
        .skelton-loaded {
            width: 100% !important;
            height: 100% !important;
        }
        .nft-img-sec {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .custom {
        .skelton-loaded {
            padding-top: 0;
        }
    }
}
.mono-settings-body {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}