.auth-pop {
    .popup-template {
        top: 0;
        bottom: 0;
        left: 41%; 
        @media screen and (max-width: 550px) {
            left: 1rem;
            min-width: unset;
            right: 1rem;
          }       
        .popup-template-body {
            padding: 2.5rem;
        }
    }
    .popup-overlay {
        z-index: 11;
    }
    &.close {
        display: none;
    }
    .auth-label {
        font-size: 1.125rem;
    }
    .auth-button {
        padding: 0.5rem 3rem;
        font-size: 0.75rem;
        border-radius: 12px;
        width: 100%;
        border: 1px solid var(--neutral-100, #E7EAEE);
        @media screen and (max-width: 550px) {
            padding: 0.5rem 2rem;
          }  
        &.register {
            border-color: var(--cu-blue-60);
        }
    }
}
