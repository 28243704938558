.baner-sm {
    height: 1.875rem;
    background: var(--cu-black-500);
    text-align: left;
    padding: 0 1rem 0 1rem;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 4;

    @media screen and (min-width: 551px) {
        display: none;
    }

    @media screen and (max-width: 551px) {
        top: 3.125rem;
        position: fixed;
        height: 3rem;
    }

}