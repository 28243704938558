.input-field {
    position: relative;
    &.input-icon {
        input {
            padding-left: 3rem;
        }
    }
    img {
        position: absolute;
        position: absolute;
        top: .72rem;
        left: 1rem;
    }
}