.collection-details-right {
  padding: 1rem;
  margin-bottom: 1rem;
  background: var(--neutral-900);
  max-height: 13rem;
  width: 100%;
  display: flex;
  border-radius: 8px;
  align-items: flex-start;
  gap: 1.6rem;
-webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  border-bottom: 0;
  z-index: 2;
  position: relative;
  justify-content: space-between;
  background: #14171F ;
  .image-container1 {
    @media screen and (min-width: 550px) {
      height: 12.75rem;
      width: 12.75rem;
    }
    
    img {
      height: 15rem;
      width: 15rem;
      gap: 10px;
      align-self: stretch;
      border-radius: 0.2rem;
    }
  }
  .image-container2 {
    height: 13.75rem;
    width: 18.75rem;
    img {
      height: 13.75rem;
      width: 18.75rem;
      gap: 10px;
      align-self: stretch;
      border-radius: 0.2rem;
    }
  }
  .collection-detail-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: .5rem;
    @media screen and (max-width: 550px) {
      padding: 0rem;
    }
    .collection-title span {
      color: var(--neutral-50, var(--cu-white-200));
      font-family: ZenKaku;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media screen and (max-width: 550px) {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
    .community-name {
      color: var(--Neutral-400, #A0ABBB);
      font-size: 0.875rem;
      font-style: italic;
    }
    .collection-attributes-container {
      gap: 0.71429rem;
      .collection-attributes {
        margin: 0.42857rem 0rem;
        .attribute-section {
          padding: 0rem 0.57143rem 0rem 0rem;
          font-size: 0.875rem;
          line-height: 1.21429rem;
          gap: 0.28571rem;
          text-align: left;
          .shapeicon {
            margin: auto;
          }
          
          @media screen and (max-width: 550px) {
            font-size: 0.75rem;
          }
          .lable {
            color: var(--cu-black-10);
          }
          .value {
            color: var(--cu-white-700);
          }
        }
      }
      .common_coll__data-box , .data-box-last {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0rem 1rem;
        border-top: none;
        border-left: none;
        border-bottom: none;
        border-right: 0.5px solid #30333a;
        gap: .2rem;
        .data-title {
            text-align: left;
            font-weight: 400;
            font-size: 0.875rem;
            color: var(--cu-white-100);
        }

        .data-value {
            text-align: left;
            font-weight: 400;
            color: var(--cu-white-200);
            font-size: 0.9rem;
            align-items: center;
            text-decoration: none;
        }
        .common_image_style {
            width: 1.1rem;
            height: 1.1rem;
            margin: 0 0.1rem 0 0;
        }
        .shapes_box-style{
          width: 1.5rem;
          height: 1.5rem;
          // left: -0.1rem;
          position: relative;
      }
    }
    .data-box-last {
      border-right: none;
    }
    }
    .collection-description {
      color: var(--cu-white-200);
      font-size: .875rem;
      font-weight: 400;
      line-height: 1.57143rem;
      text-align: left;
      word-break: break-word;
      @media screen and (max-width: 550px) {
        font-size: 0.75rem;
      }
    }
    .view-less {
      cursor: pointer;
      padding: 0 !important;
      align-self: start;
  }
  .view-more {
      transform: rotate(180deg);
      padding: 0 !important;
      cursor: pointer;
      align-self: start;

  }

  }
}
.discover-creator-item-details {
  @media screen and (max-width: 550px) {
    .collection-details-right {
      flex-direction: column;
    }
    .collection-details-right .image-container img {
      width: 100%;
      border-radius: 0;
    }
    .collection-detail-container {
      padding: 0;
    }
    .collection-image-cotainer {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #30333a;
    }
    .collection-title {
      color: var(--neutral-100, #e7eaee);
      font-family: ZenKaku;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .profile-social-icon {
      display: none;
    }
    .collection-attributes {
      .w30 {
        width: 24%;
      }
      .w20 {
        width: 20%;
      }
      .w50 {
        width: 50%;
      }
      .w100 {
        width: 100%;
      }
    }
    .hidden-mo {
      display: none !important ;
    }
    .collection-details-right
      .collection-detail-container
      .collection-description {
      color: var(--CU-WHITE-200);
      font-family: ZenKaku;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media screen and (max-width: 550px) {
        padding: 0rem;
      }
      .collection-title span {
        color: var(--cu-white-200);
        font-size: 1.28571rem;
        font-weight: 600;
        display: flex;
      }
      .collection-attributes-container {
        gap: 0.71429rem;
        .collection-attributes {
          padding: 0.42857rem 0rem;
          gap: 1.25rem 0.57143rem;
          .attribute-section {
            padding: 0rem 0.57143rem 0rem 0rem;
            font-size: 0.85714rem;
            line-height: 1.21429rem;
            gap: 0.28571rem;
            text-align: left;
            .lable {
              color: var(--cu-black-10);
            }
            .value {
              color: var(--cu-white-900);
            }
          }
        }
      }
      .collection-description {
        color: var(--cu-white-200);
        font-size: 1rem;
        line-height: 1.57143rem;
        text-align: left;
        white-space: pre-line;
      }
    }
  }
}
.all-collection{
padding: 6px 14px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 6px;
border: 1px solid var(--dark-mode-300, #30333A);
}

.collection-id {
  font-size: 0.875rem;
  line-height: 1.21429rem;
  gap: 0.28571rem;
  text-align: left;
  @media screen and (max-width: 550px) {
    font-size: 0.75rem;
  }
  .value {
    color: var(--cu-black-10);
  }
}