.content-sec {
    .profile {
        .span-text {
            word-break: break-all;
            text-align: left;
            padding: 5px;
            background: #0D0F11;
            border: 1px solid var(--cu-black-70);
            border-radius: 4px;
            font-size: 0.75rem;
            width: 100%;
            color: #d9dbdf;
            max-height: 7rem;
            letter-spacing: 0.02rem;
            max-width: 31.25rem;
            overflow: auto;
-webkit-overflow-scrolling: touch;
        }
        .max-width-full{
            max-width: 100%;
        }

        input{
            padding: 0.5rem 1rem;
            border-radius: 5rem;
        }
        textarea {
            padding: 0.2rem 0.6rem;
            border-radius: 15px;
        }
        input,
        textarea {
            color: var(--cu-white-200);
            /* Neutral / 900 */

            background: var(--cu-black-700);
            /* Dark Mode / 300 */

            border: 0.0625rem solid var(--cu-black-100);

            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 0;
            font-size: .75rem;
        }


        .light-txt {
            input {
                max-width: 31.25rem;
                width: 100%;
            }

            input,
            textarea {
                color: var(--cu-white-200);
            }
            textarea {
                resize: none;
            }
        }

        .select {
            width: 100%;
        }

        .nation-select {
            .select-box-items {
                max-height: 20rem !important;
                overflow: scroll;
                
            }
        }
        
        .profile-input-textarea{
            min-height: 4rem;
        }
        textarea {
            width: 100%;
            min-height: 5.625rem;
        }

        .change-password {
            button {
                font-size: .75rem;
                line-height: .875rem;
                padding: .5rem 3.593rem;
                min-width: 8rem;
                min-height: 1.88rem;
                border: 0.125rem solid var(--cu-white-900);
                border-radius: 1.25rem;
            }
        }

        .label-box {
            min-width: 16rem;
            font-weight: 400;
        }

        .label-box-view {
            flex: 1 1 8.523rem;
            font-weight: 400;
        }
    }

    .change-password-panel {
        .page-title {
            font: .75rem;
        }

        .password-label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 0.625rem;
            word-break: normal;
            font-size: 0.75rem;
            color: var(--cu-white-100);
        }

        .confirm-btn-box {
            padding: 1.25rem 0.625rem;
            width: 12rem;
        }

        input {
            color: var(--cu-white-100);
            padding: 0.625rem;
            /* Neutral / 900 */

            background: var(--cu-black-700);
            /* Dark Mode / 300 */

            border: 0.0625rem solid var(--cu-black-100);
            border-radius: 0.25rem;
            font-size: .0.75rem;
            line-height: .625rem;
        }
    }

    .wallet-connect {
        display: flex;
        justify-content: center;

        .connect-button {
            width: 15.25rem;
        }
    }

    @media screen and (max-width: 550px) {
        .profile {
            .profile-row {
                flex-wrap: wrap;
                display: block;
                .profile-update {
                    position: absolute;
                    top: -1.2rem;
                    right: 1rem
                }

                .profile-input {
                    flex-basis: 100%;

                    .select {
                        width: 100%;
                    }
                }

                .label-box {
                    font-size: .75rem;
                    font-weight: 600;
                   // margin-bottom: .625rem;
                }
            }

            .edit-icn {
                display: none;
            }

            .change-password {
                button {
                    border-radius: .5rem;
                    width: 100%;
                    font-weight: 600;
                }
            }
        }

        .change-password-panel {
            .content-box {
                flex-wrap: wrap;
                gap: 0.625rem;
            }

            .confirm-btn-box {
                width: auto;
                flex: 1;
            }

            .input-row {
                flex-wrap: wrap;
                flex-basis: 100% !important;

                .input {
                    width: 100%;
                }

                label {
                    font-size: .75rem;
                    font-weight: 600;
                }

                .password-label {
                    margin-bottom: 0.2rem;
                }

            }

            .page-title {
                display: none;
            }

            .instructions {
                font-size: .75rem;
            }
        }
    }
}

.label-color {
    color: var(--cu-white-100);
    font-size: 0.75rem;
    letter-spacing: 0.02rem;
}

.info-head {
    // border-bottom: 1px solid var(--cu-black-100);
    font-size: 0.9rem;
    text-transform: capitalize;
}

.profile-pic-size {
    img {
        width: 6.25rem !important;
        height: 6.25rem !important;

        @media screen and (max-width: 550px) {
            width: 5rem !important;
            height: 5rem !important;
        }
    }

}

.input-color {
    word-break: break-all;
    text-align: left;
    padding: 0.3125rem;
    background: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    border-radius: 0.25rem;
    font-size: 0.75rem;
    min-width: 16rem;
    color: #d9dbdf;
    letter-spacing: 0.02rem;
    max-height: 7rem;
    overflow: auto;
-webkit-overflow-scrolling: touch;

    input,
    textarea {
        background: var(--cu-black-700);
    }


}

.select-new {
    min-width: 16rem;
}

.password-instructions {
    font-size: 0.75rem;
    color: var(--cu-black-5);

    p {
        font-size: 0.75rem;
        color: var(--cu-white-200);
    }
}

.confirm-btn-cancel-box {
    border-radius: 0.71rem;
}
