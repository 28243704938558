.collectors_results-container {
    .results-wrapper {
        display: flex;
        flex-direction: column;
        max-height: 26.5rem;
        overflow-y: auto;
        padding: 0 1rem 1rem 1rem;
        gap: 1rem;
    }

    .collectors_results-see-more {
        padding: 0.6rem 0 1rem 0;

        button {
            font-size: 0.875rem;
            transition: all 0.3s;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .collectors_no-results-found {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--cu-white-100);
        font-size: 0.75rem;
    }

    .collectors_results-details-loader {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 0.2rem 0.4rem;
        gap: 1rem;
        min-height: 40px;
        overflow: hidden;
    }

    .collectors_results-details-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 0.2rem 0.4rem;
        transition: all 0.3s;

        &:hover {
            background: var(--cu-black-50);
        }

        .details_column-one {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;

            .collectors-image-box {
                img {
                    border-radius: 50px;
                }
            }

            .collectors-name-box {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                text-align: start;

                h2 {
                    font-size: 0.95rem;
                }

                h3 {
                    font-size: 0.85rem;
                }

            }
        }
    }
}