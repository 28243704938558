.frame-filter {
    flex: 0 0 11rem;
    height: 100%;
    gap: .625rem;

    @media screen and (max-width: 551px) {
        // max-height: calc(100% - 4.625rem) !important;
    }

    .filter-cnr-inner {
        @media screen and (min-width: 551px) {
            max-height: calc(100vh - 20rem);
        }
        @media screen and (max-width: 768px) {
            flex: 1 1;
            transition: max-height .1s linear;
            overscroll-behavior-y: contain;
            -webkit-overflow-scrolling: touch;
            overflow: auto;
-webkit-overflow-scrolling: touch;
        }
    }

    .filter-search {
        border: 0.0625rem solid var(--cu-white-900);
        border-radius: .5rem;
        background-color: transparent;
        color: var(--cu-white-700);
        font-size: .625rem;
        font-weight: 600;
        padding: .25rem .375rem;
        width: 96%;
        margin-left: 0.2rem;
        margin-right: .2rem;
        padding-right: 1.5rem;

    }

    .search-icon {
        height: 1.1875rem;
        position: absolute;
        top: 0.2rem;
        padding: 0.1875rem 0.375rem;
        right: 2%;
    }


    .title-box {
        padding: .3125rem;
        font-size: .625rem;
        text-align: left;
        font-weight: 600;
    }

    .frame-colors {
        display: flex;
        flex-direction: column;


        .color-box {
            display: flex;
            gap: .5rem;
            flex-wrap: wrap;
            padding: .625rem 0.5em;

            .color-circle {
                width: 1rem;
                height: 1rem;
                border-radius: 0.125rem;
                cursor: pointer;
                border: 1px solid var(--cu-black-500);
                outline: 1px solid transparent;

                &.multi {
                    background: linear-gradient(red, yellow, blue, orange);
                }

                &.selected {
                    outline-color: var(--cu-white-900);
                }
            }
        }
    }

    .filter-checkbox {
        display: flex;
        flex-direction: column;
        gap: .625rem;
    }

    .searching {
        display: flex;
        flex-direction: column;
        border-radius: 0.6875rem;
        aspect-ratio: 1/1;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        justify-content: center;
        // background: var(--neutral-900);
        .selected-nft-image {
            border-radius: 0.6875rem;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        p {
            margin-top: 3.094rem;
            margin-bottom: 3.094rem;
            font-size: 14px;
            
        }
    }

    .search-panel {

        .search-result {
            display: flex;
            flex-wrap: wrap;
            gap: .25rem;

            .nft-box {
                cursor: pointer;
                width: 3rem;
                height: 3rem;
                padding: 0;
                border: .0625rem solid transparent;
                border-radius: .25rem;
                overflow: hidden;
                &.selected-nft {
                    border-color: var(--cu-white-900);
                }
            }
        }
    }

    .btn-box {
        flex: 0 0;
        display: flex;
        gap: .625rem;
        justify-content: center;
        @media screen and (min-width: 768px) {
            margin-top: auto;
        }
        width: 100%;
        padding: 0.5rem;

        .apply {
            // border: 0.0625rem solid var(--cu-blue-60);
            background: var(--cu-black-700);
            color: var(--cu-white);
            font-size: 0.875rem;
            width: 100%;
            border-radius: 1rem;
            padding: .2rem .675rem .375rem .675rem;
            border: 1px solid var(--dark-mode-300);

            &:hover {
                background: var(--dark-mode-300);
                border: 1px solid var(--dark-mode-300);
            }
        }

        .reset {
            border: 0.0625rem solid var(--dark-mode-300);
            color: var(--cu-white);
            border-radius: 1rem;
            font-size: 0.875rem;
            padding: .2rem .675rem .375rem .675rem;
            width: 100%;
            &:hover {
                background: var(--dark-mode-300);
                border: 1px solid var(--dark-mode-300);
            }
        }
    
    }

    &::-webkit-scrollbar {

        height: 12rem;
    }

    @media screen and (max-width: 768px) {
        .btn-box {
            width: 95%;
            padding-bottom: 2rem;
        }
    }

    @media screen and (max-width: 550px) {
        // max-height: calc(100vh - 4.625rem);

        .btn-box {
            padding-bottom: 1rem;
        }

        .searching {
            width: 95%;
        }
    }

}