.display-update-overlay {
    position: fixed;
    top:0rem;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px) brightness(30%); /* Apply the blur effect */
    z-index: 1000; /* Set a high z-index to ensure it appears on top of other elements */

    .display-update-content {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width:40rem;        
        background: var(--dark-mode-900);
        border-radius: 0.75rem;
        border: 1px solid var(--Dark-Mode-300, #30333A);
        padding: 2rem;
        gap: 2rem;

        .anywall-logo {            
            margin:auto;
            padding-bottom: 1rem;
        }

        .info-title {
            padding:2rem;
        }

        input,
        textarea {
            color: var(--cu-white-100);
            padding: 0.425rem;
            gap: 0.625rem;
            /* Neutral / 900 */

            background: var(--cu-black-700);
            /* Dark Mode / 300 */

            border: 0.0625rem solid var(--cu-black-100);
            border-radius: 0.25rem;

            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 0;
            font-size: .625rem;
        }


        .light-txt {
            input {                
                width: 100%;
            }

            input,
            textarea {
                padding: 0.625rem;
                color: var(--cu-white-200);
                font-size: 0.75rem;
            }
            textarea {
                resize: none;
            }
        }

        .label-color {
            color: var(--cu-white-100);
            font-size: 10px;
            letter-spacing: 0.02rem;
            font-weight: 400;
        }

        .update-actions {
            width:100%;         
            gap: 1rem;   

            button {
                flex: 1;
            }
        }
    .cancel-button {
        border: 0.0625rem solid var(--dark-mode-300);
        color: var(--cu-white);
        border-radius: 1.25rem;
        font-size: 0.875rem;
        padding: 0.625rem;
        width: 100%;
    }
    .pair-button {
        border-radius: 1.25rem;
        border: 1px solid var(--Dark-Mode-300, #30333A);
        background: var(--Neutral-900, #0D0F11);
        font-size: 0.875rem;
        padding: 0.625rem;
    }
    }
  }