.collection-sq-card {
  .flip-card {
    position: relative;
    aspect-ratio: 1;
    .flip-card-inner {
      width: 100%;
      height: 100%;
      transition: transform 0.5s;
      transform-style: preserve-3d;
      :is(.flip-card-front, .flip-card-back) {
        backface-visibility: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
      }
      .flip-card-back {
        transform: rotateY(180deg);
      }
    }
    &:hover {
      .flip-card-inner {
        transform: rotateY(180deg);
      }
    }
  }
  .own-collection{
      .edit-delete-wrapper{
          display: none;
          background: var(--cu-black-410);
     }
      &:hover{
          .edit-delete-wrapper{
              display: flex;
              width: 100%;
              height: 100%;
          }
      }
  }
  &.image-item {
      border-radius: 0.28571rem;
      // max-height: 25rem;
      aspect-ratio: 1;
      .image-container{
          height: 100%;
      }
      .card-drop {
          border-radius: 0.25rem;
          border: 1px solid var(--cu-black-900);
          background: var(--cu-black-100);
      }
      .drop-name, .image-text {
          color: var(--cu-white-900);
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 0.85714rem;
          letter-spacing: 0.02rem;
      }
      .image-container {
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 0.25rem;
          background-position: center;
          &.no-banner{
              background-size: contain;   
          }
          .edit-delete-wrap{
              display: flex;
              transition: 1s ease;
              justify-content: end;
              width: 100%;
              height: 100%;
              padding: 0.5rem;
              background: var(--cu-black-hover);
              .icons-overlay-edit-delete{
                  height: fit-content;
              }
          }
 
          opacity: 1;
          transition: background-image 1s ease-in-out;
          &:hover{
             transition: 1s ease-in-out;
          }
          // &:hover{
          //     opacity: 0.5;
          // }
          img.card-image {
              border-radius: 0px 0px 4px 4px;
              margin: auto;
          }
      }
      .image-text {
          font-weight: 500;
      }
      .community-pic {
          flex: 0 0 auto;
          align-self: flex-start;
          img {
              border-radius: 50%;
              border: 1px solid var(--cu-black-100);
              width: 1.875rem;
              height: 1.875rem;
              object-fit: cover;
              @media screen and (max-width: 550px){ 
                  width: 1.25rem;;
                  height: 1.25rem;;
              }
          }
          
      }
      .community-name, .creator-name {
          color: var(--neutral-500, #64748B);
          font-family: ZenKaku;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: left;
          @media screen and (max-width: 550px){ 
              font-size: 0.6rem !important;
          }
      }
      .collection-name {
          padding-top: .25rem;
          font-size: 0.875rem;
          word-break: break-word;
          @media screen and (max-width: 550px){ 
              font-size: 0.6rem !important;
          }
      }
  }
  @media screen and (max-width: 550px){ 
      gap: 0.5rem;
  }
  .card-details {
      padding: 0.625rem 0.375rem;
      @media screen and (max-width: 550px){ 
          padding-top: 0;
          padding-bottom: 0;
      } 
  }
}

.tag-name {
 span{
  text-align: left;
  word-break: break-all;
  color:var(--cu-white-100) 
 }
}

.collection-container {
  .body
  .collection-sq-card{
      &.image-item{
          .image-container{
              height: var(--discover-collection-card-height);
          }
      }
  }
}
// .hover-effect {
//     --a: 8deg; /* control the angle of rotation (the smaller, the better) */
//     width: 250px;
//     aspect-ratio: 1;
//     border-radius: 20px;
  
//     -webkit-mask: 
//       linear-gradient(135deg,#000c 40%,#000,#000c 60%)
//       100% 100%/250% 250%;
//     transition: .4s;
//     cursor: pointer;
//   }
//   .alt-class {
//     --r: 1,1;
//     -webkit-mask: 
//       linear-gradient(45deg,#000c 40%,#000,#000c 60%)
//       0 100%/250% 250%;
//   }
//   .hover-effect:hover {
//     transform: perspective(400px) rotate3d(var(--r,1,-1),0,calc(var(--i,1)*var(--a)));
//     scale: .9;
//     --i: -1;
//     -webkit-mask-position: 0 0;
//   }
//   .alt-class:hover {
//     -webkit-mask-position: 100% 0;
//   }

//   .hover-effect2 {
//     --a: 8deg; /* control the angle of rotation (the smaller, the better) */
//     width: 250px;
//     aspect-ratio: 1;
//     border-radius: 20px;
  
//     -webkit-mask: 
//       linear-gradient(135deg,#000c 40%,#000,#000c 60%)
//       100% 100%/250% 250%;
//     transition: .4s;
//     cursor: pointer;
//   }
//   .hover-effect2:hover {
//     transform: perspective(400px) rotate3d(var(--r,1,-1),0,calc(var(--i,1)*var(--a)));
//     scale: .9;
//     --i: 1;
//     -webkit-mask-position: 0 0;
//   }
