.screenshot-popup {
    height: 100%;

    .popup-template,
    .popup-template-body {
        height: 100%;
        width: 100%;
        max-height: 100%;
        background: var(--cu-black-900);
    }
    .preview-grid-show {
        .gallery-images-position {
            height: 100%;
        }
    }
}

.loading {
    height: 100%;
    width: 100%;
    background: var(--cu-black-900);
    z-index: 2;
    .curating-loader {
        width: calc(100px - 14px);
        height: 50px;
        border-radius: 50px;
        background: 
        radial-gradient(farthest-side,#0000 calc(100% - 15px),#ccc calc(100% - 14px) 99%,#0000) left,
        radial-gradient(farthest-side,#0000 calc(100% - 15px),#ccc calc(100% - 14px) 99%,#0000) right;
        background-size: calc(50% + 7px) 100%;
        background-repeat: no-repeat;
        position: relative;
        animation: l10-0 2s infinite linear;
    }
    .curating-loader::before {
        content: "";
        position: absolute;
        inset: 0;
        margin: auto; 
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgb(166,0,195);
        background: linear-gradient(90deg, rgba(166,0,195,1) 8%, rgba(254,196,0,1) 20%, rgba(236,40,2,1) 52%, rgba(117,50,231,1) 71%, rgba(61,121,243,1) 88%, rgba(32,197,255,1) 95%);
        transform-origin: -14px 50%;
        animation: l10-1 1s infinite linear;
    }
    @keyframes l10-0 { 
        0%,49.99% { transform:scaleX(1)}
        50%,100% { transform:scaleX(-1)} 
    }
    @keyframes l10-1 { 
        100% { transform:rotate(1turn)} 
    }
    .curating-text {
        width: fit-content;
        font-weight: bold;
        clip-path: inset(0 10ch 0 0);
        animation: l4 1s steps(30) infinite;
    }
    .curating-text:before {
        content:"Curating...";
    }
    
    @keyframes l4 {to{clip-path: inset(0 -4ch 0 0);}}
}