th {
    font-size: 0.875rem;
    text-align: center;
}

td {
    font-size: 0.875rem;
}

thead.gallery-table-full {
    position: sticky;
    top: -1px;
    background: var(--cu-black-700);
    z-index: 2;
}

tbody {
    tr {
        border-bottom: 0.5px solid var(--cu-black-trans-250)
    }
}

.table-image {
    width: 9rem;
    align-items: center;
    display: flex;

    .selected-new {
        height: 100%;

        &.active>div {
            border: 1px solid;

            img {
                display: block;
                margin: auto;
            }
        }
    }
}


.table-image-frame {

    .gallery-nft-frame-viewer {
        width: 5rem;

    }

}

.table-full {
    max-width: 30rem;
}

.sort-up {
    transform: rotate(180deg);
}

.sort-down {
    transform: rotate(0deg);
}

.table-image {
    .gallery-images {
        width: 2rem;

        img {
            height: 2rem;
            width: 2rem;
            object-fit: contain;
        }
    }
}


.list-name-wid {
    white-space: nowrap;
   &.eclipse-wid{
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
   }
    @media screen and (max-width: 550px) {

        font-weight: 400;
        color: var(--cu-white-900);
    }
}

.nft-gallery-full-card {
    @media screen and (max-width: 550px) {
        .table-view {
            padding-left: 0.3rem;
        }


    }
}